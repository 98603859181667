import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import "./ReportsPage.css";
import { useNotifications } from "../../helpers/getNotifications";
import ReactDataTables from "../../components/Datatables/ReactDataTables";
import { Tab } from "semantic-ui-react";
import api from "../../services/api/api";

const ReportsPage = () => {
  const handleSidebar = (sidebar) => setSidebarIsOpen(sidebar);
  const [sidebarIsOpen, setSidebarIsOpen] = useState(true);
  const { notifications, checkNotification } = useNotifications();
  const [actualPane, setActualPane] = useState(0);
  const [loadingYear, setLoadingYear] = useState(false);
  const [loadingBNZ, setLoadingBNZ] = useState(false);
  const [dataYear, setDataYear] = useState([]);
  const [dataBNZ, setDataBNZ] = useState([]);

  useEffect(() => {
    setLoadingYear(true);
    setLoadingBNZ(true);
    api
      .get("/informations/financial")
      .then((response) => {
        setDataYear(response.data);
      })
      .finally(() => setLoadingYear(false));

    api
      .get("/informations/services")
      .then((response) => {
        setDataBNZ(response.data.map(item => ({
          ...item,
          total: parseFloat(item.priceBoost || 0) + 
            parseFloat(item.priceGrain || 0) + 
            parseFloat(item.priceGrow || 0) + 
            parseFloat(item.priceSow || 0) + 
            parseFloat(item.priceSpring || 0) + 
            parseFloat(item.bicolunado || 0) + 
            parseFloat(item.extraLanguage || 0) + 
            parseFloat(item.urgentExtraBNZ || 0)
        })));
      })
      .finally(() => setLoadingBNZ(false));
  }, []);

  const columnsYear = [
    { data: "requestDate", title: "Data", search: false },
    { data: "clientName", title: "Cliente" },
    { data: "companyName", title: "Empresa" },
    { data: "code", title: "Código" },
    { data: "createdAt", title: "Data Criação" },
    { data: "fullDescription", title: "Descrição" },
    { data: "price", title: "Preço", footer: " " , className: "text-right" },
    { data: "plan", title: "Plano", footer: " " },
    { data: "subserviceName", title: "Subserviço" },
    { data: "lawyerName", title: "Advogado" },
    { data: "channel", title: "Canal" },
    { data: "month", title: "Mês" },
  ];
  const columnsBNZ = [
    { data: "serviceName", title: "Serviço" },
    { data: "serviceType", title: "Serviço Tipo" },
    { data: "subserviceName", title: "Subserviço" },
    { data: "priceBoost", title: "Boost", footer: " " , className: "text-right" },
    { data: "priceGrain", title: "Grain", footer: " " , className: "text-right" },
    { data: "priceGrow", title: "Grow", footer: " " , className: "text-right" },
    { data: "priceSow", title: "Sow", footer: " " , className: "text-right" },
    { data: "priceSpring", title: "Spring", footer: " " , className: "text-right" },
    { data: "bicolunado", title: "Bi-Colunado", footer: " " , className: "text-right" },
    { data: "extraLanguage", title: "Idioma", footer: " " , className: "text-right" },
    { data: "urgentExtraBNZ", title: "Urgente", footer: " " , className: "text-right" },
    { data: "total", title: "Total", footer: " ", className: "text-right" },
  ];

  const drawYear = function (tfoot) {
    const api = this.api();

    const pageTotal = api
      .column(6, { page: "current" })
      .data()
      .reduce((a, b) => a + parseFloat(b || 0), 0);

    if (tfoot) {
      tfoot.innerHTML = `
        <th colspan="6" style="text-align: right">Total:</th>
        <th class="text-right">${pageTotal}</th>
        <th colspan="5"></th>
      `;
    }
  };

  const drawBNZ = function (tfoot) {
    const api = this.api();
    const columns = [];
    for (let i = 3; i <= 11; i++) {
      const value = api
        .column(i, { page: "current" })
        .data()
        .reduce((a, b) => a + parseFloat(b || 0), 0);
      columns.push(value);
    }
    

    if (tfoot) {
      let html = `
        <th colspan="3" style="text-align: right">Total:</th>
      `;
      for(const item of columns) {
        html += `<th class="text-right">${item}</th>`;
      }
      tfoot.innerHTML = html;
    }
  };

  const panes = [
    {
      menuItem: "Anual",
      pane: (
        <>
          {loadingYear && actualPane === 0 && (
            <Tab.Pane loading={true}></Tab.Pane>
          )}
          <div className={`ui grid ${!loadingYear && actualPane === 0 ? '' : 'hide'}`}>
            <div className="sixteen wide column">
              <ReactDataTables
                data={dataYear}
                columns={columnsYear}
                footerCallback={drawYear}
                columnDefs={[
                  {
                      searchPanes: {
                        show: false
                      },
                      targets: [3,5,6]
                  }]}
              />
            </div>
          </div>
        </>
      ),
    },
    {
      menuItem: "Serviços BNZ",
      pane: (
        <>
        {loadingBNZ && actualPane === 1 && (
            <Tab.Pane loading={true}></Tab.Pane>
          )}
          <div className={`ui grid ${!loadingBNZ && actualPane === 1 ? '' : 'hide'}`}>
            <div className="sixteen wide column">
              <ReactDataTables
                data={dataBNZ}
                columns={columnsBNZ}
                footerCallback={drawBNZ}
              />
            </div>
          </div>
        </>
      ),
    },
  ];

  const handleChange = (e, data) => {
    setActualPane(data.activeIndex);
  };

  return (
    <div id="ReportsPage">
      <div className="row">
        <div className="col s12">
          <Sidebar
            isOpen={handleSidebar}
            notifications={notifications}
            checkNotification={checkNotification}
          />
        </div>
      </div>
      <div
        className={
          !sidebarIsOpen ? "container-width" : "container-width-sbopen"
        }
      >
        <div className="card card-reportsPage">
          <Tab
            renderActiveOnly={false}
            menu={{ pointing: true }}
            panes={panes}
            onTabChange={handleChange}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportsPage;
