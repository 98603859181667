import React, {useEffect, useState} from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Searchbar from "../../components/Searchbar/Searchbar";
import {Input, Table, TextArea, Dropdown} from 'semantic-ui-react';
import Pagination from "../../components/Pagination/Pagination";
import api from "../../services/api/api";
import Hints from '../../components/Hints/Hints';
import AssignModal from '../../components/Modals/AssignDemand/AssignModal'
import "./LawyerServicesBonuzPlans.css";
import { useNotifications } from "../../helpers/getNotifications";
import InputDate from "../../components/Date/DateInput";

const LawyerServicesBonuzPlans = (props) => {

    const [sidebarIsOpen,setSidebarIsOpen] = useState(false);

    const handleSidebar = (sidebar)=>setSidebarIsOpen(sidebar);

    const jfDemand = props.match.params.jfDemand !== undefined;
    const clientId = props.match.params.clientId || undefined;
    const [selectedClient,setSelectedClient] = useState("");
    const [openAssignModal, setOpenAssignModal] = useState(false);
    const [demandData,setDemandData] = useState("");
    const [servicesProvided,setServicesProvided] = useState("");
    const [selectedProvidedService,setSelectedProvidedService] = useState(null);
    const [serviceType,setServiceType] = useState("");
    const [selectedServiceType,setSelectedServiceType] = useState(null);
    const [subservice,setSubservice] = useState("");
    const [selectedSubservice,setSelectedSubservice] = useState(null);
    const [servicesToAssign,setServicesToAssign] = useState("");
    const [filteredServicesToAssign, setFilteredServicesToAssign] = useState("");
    const [searchServices,setSearchServices] = useState(jfDemand?"Jurídico Familiar":"");
    const [deadlineDemandInEstimating,setDeadlineDemandInEstimating] = useState("");
    const [comment,setComment] = useState("Demanda criada | Planos Bonuz");
    const [priceDemandInEstimating,setPriceDemandInEstimating] = useState(0);
    const [fieldId,setFieldId] = useState("");
    const [clients,setClients] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPage,setMaxPage] = useState(1);
    const [search,setSearch] = useState("");
    const {notifications, checkNotification} = useNotifications();
    const [requestDate, setRequestDate] = useState("");
    const [valueBNZ, setValueBNZ] = useState("");
    const [channel, setChannel] = useState("");

    useEffect(()=>{
        api.get(`/clients/index?page=${currentPage}&search=${search}`).then((response) => {
            setClients(response.data.data);
            setMaxPage(response.data.last_page)
        }).catch((error)=>{
            alert('Erro ao carregar os clientes');
        });
    },[currentPage, search])

    useEffect(()=>{
        api.get(`/lawyer/service`).then((response) => {
            setServicesProvided(response.data.data)
        }).catch((error)=>{
            alert('Erro ao carregar os serviços');
        });
    },[])


    useEffect(()=>{
        api.get(`/lawyer/searchService?search=${searchServices}`).then((response) => {
            setServicesToAssign(response.data.data)
        }).catch((error)=>{
            alert('Erro ao carregar a pesquisa');
        });
    },[searchServices])


    useEffect(()=>{
        if (selectedClient && servicesToAssign && servicesToAssign.length > 0) {
            const client = clients.find(item => item._id === selectedClient);
            let packageId = null;
            if (client) {
            packageId = client.packageId;
            }
            const servicesPrices = servicesToAssign.map((item, index) => {
                return item.prices
            })
            const filteredPrices = servicesPrices.map((item, index) => {
                const packagesArray = item
                const filteredPackagesArray = packagesArray.map((newItem, newIndex)=>{
                    return {packageId: newItem.packageId.$oid, amount: newItem.amount}
                })
                return filteredPackagesArray
            })
            const packagePriceToClient = filteredPrices.map((item, index) => {
                const packageItem = item
                const selectedPackageItem = packageItem.find(item => item.packageId === packageId);
                return selectedPackageItem
            })
            const filteredServicesToAssign = servicesToAssign.map((item, index) => {
                return {...item, priceToSelectedClient: packagePriceToClient[index]?.amount}
            })
            setFilteredServicesToAssign(filteredServicesToAssign)
        } else {
            setFilteredServicesToAssign([])
        }
    },[selectedClient, clients, servicesToAssign])


    const handleServiceType = (event, data) => {
        if(data.value) {
            setSelectedProvidedService(data.value)

            api.get(`/lawyer/service-type/${data.value}`).then((response) => {
                setServiceType(response.data.data)
            }).catch((error)=>{
                alert('Erro na seleção dos tipos de serviços');
            });
        } else {
            setSelectedProvidedService(null)
            setSelectedServiceType(null)
            setServiceType("")
            setSelectedSubservice(null)
            setSubservice("")
            setServicesToAssign("")
        }
    }

    const handleSubservice = (event, data) => {
        if(data.value) {
            setSelectedSubservice(data.value)

            api.get(`/lawyer/subservice/${data.value}`).then((response) => {
                setSubservice(response.data.data)
            }).catch((error)=>{
                alert('Erro na seleção dos subserviços');
            });
        } else {
            setSelectedServiceType("")
            setSelectedSubservice("")
            setSubservice("")
            setServicesToAssign("")
        }
    }

    const handleShowSubservice = (event, data) => {
        if(data.value) {
            setSelectedServiceType(data.value)
            const subserviceToAssign = subservice.find(item => item._id === data.value);
            setServicesToAssign([subserviceToAssign])
        } else {
            setSelectedServiceType("")
            setServicesToAssign("")
        }
    }

    const changePage = (page = 1) => {
        setCurrentPage(page);
    }

    const handleSearchClients = (event) => {
        setSearch(event.target.value)
    }

    const handleSearchServices = (search) => {
        setSearchServices(search)
    }

    const handleSelectClient = ((value) => {
        setSelectedClient(value)
    })

    const handleDemandsInEstimating = (field, fieldId, value) => {
        setFieldId(fieldId)
        console.log(field, fieldId, value);
        if(field === 'deadline'){
            setDeadlineDemandInEstimating(value)
        }
        if(field === 'price'){
            setPriceDemandInEstimating(value)
        }

        if(field === 'comment'){
            setComment(value)
        }

        if (field === 'requestDate') {
            setRequestDate(value)
        }

        if (field === 'valueBNZ') {
            setValueBNZ(value)
        }

        if (field === 'channel') {
            setChannel(value)
        }
    }
    const handleComment = (event) => {
        const commentValue = event.target.value
        const commentFinal = "Demanda criada | Planos Bonuz | Descrição de serviço: " + commentValue
        setComment(commentFinal)
    }

    const handleCloseAssignModal = (clear = true) => {
        if (clear) {
            setSelectedProvidedService(null)
            setSelectedServiceType(null)
            setServiceType("")
            setSelectedSubservice(null)
            setSubservice("")
            setServicesToAssign("")
        }
        setOpenAssignModal(false);
    }

    const handleAssignModal = (subserviceId, deadline, priceToSelectedClient, needsEstimate) => {
        if(selectedClient || jfDemand){
            if(needsEstimate){
                if(fieldId === subserviceId){
                    if(!deadline) {
                        alert("Os campos prazo não foi preenchido!");
                    } else {
                        setDemandData({
                            clientId: jfDemand ? clientId : selectedClient,
                            subserviceId: subserviceId,
                            deadline: deadline,
                            price: priceToSelectedClient,
                            comment:comment,
                            requestDate,
                            valueBNZ,
                            channel
                        })
                        setOpenAssignModal(!openAssignModal)
                    }
                } else {
                    alert("É necessário preencher os mesmos campos do serviço a ser atribuído!");
                }
            } else {
                setDemandData({
                    clientId: jfDemand ? clientId : selectedClient,
                    subserviceId: subserviceId,
                    deadline: deadline,
                    price: priceToSelectedClient,
                    comment:comment
                })
                setOpenAssignModal(!openAssignModal)
            }
        } else {
            alert("É necessário selecionar o cliente!");
        }
    }

    useEffect(()=>{
        if(clientId !== undefined){
            setSelectedClient(clientId)
        }
    },[clientId, setSelectedClient])

    return (
        <div className="services-container">
            <AssignModal
                handleClose={handleCloseAssignModal}
                openModal={openAssignModal}
                demandData={demandData}
                jfDemand={jfDemand}
            />
            <div className="row">
                <div className="col s12">
                    <Sidebar isOpen={handleSidebar} notifications={notifications} checkNotification={checkNotification}/>
                </div>
                <div className={!sidebarIsOpen ? "container-width" : "container-width-sbopen"}>
                    <div className="col s12 services-inner-container">
                        <div className="hintsbar">
                            <Hints
                                hintText={'Use os filtros ou o campo de busca para encontrar o serviço que procura'}
                                hintInfo={'Você pode encontrar serviços específicos para sua necessidade escolhendo entre as opções do filtro acima.'}
                            />
                        </div>

                        {!jfDemand &&
                            <Searchbar search={handleSearchServices}/>
                        }

                        {!jfDemand &&
                            <div className="select-services-container" style={{width: '100%'}}>
                                <div className="ui form" style={{width: '60%'}}>
                                    <div className="fields">
                                        <div className="five wide field">
                                            <label>Serviços</label>
                                            <Dropdown placeholder='Selecione um serviço' clearable selection options={
                                                [].concat(servicesProvided)?.map((item, index) => ({
                                                    key: index,
                                                    value: item._id,
                                                    text: item.name
                                                }))}
                                                onChange={handleServiceType}
                                                value={selectedProvidedService}
                                                selectOnBlur={false}
                                                selectOnNavigation={false}
                                            />
                                        </div>
                                        <div className="five wide field">
                                            <label>Tipos</label>
                                            <Dropdown placeholder='Selecione um tipo' clearable selection options={
                                                [].concat(serviceType)?.map((item, index) => ({
                                                    key: index,
                                                    value: item._id,
                                                    text: item.name
                                                }))}
                                                onChange={handleSubservice}
                                                value={selectedSubservice}
                                                selectOnBlur={false}
                                                selectOnNavigation={false}
                                            />
                                        </div>
                                        <div className="five wide field">
                                            <label>Subserviços</label>
                                            <Dropdown placeholder='Selecione um subserviço' clearable selection options={
                                                [].concat(subservice)?.map((item, index) => ({
                                                    key: index,
                                                    value: item._id,
                                                    text: item.name
                                                }))}
                                                onChange={handleShowSubservice}
                                                value={selectedServiceType}
                                                selectOnBlur={false}
                                                selectOnNavigation={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="col s12 table-container">
                        <div id="table-services" className="col s12 m7" style={{maxWidth: '60%'}}>
                            {filteredServicesToAssign && filteredServicesToAssign.length > 0 ? (filteredServicesToAssign?.map((service) => {
                                return (
                                    <div class="ui segments">
                                        <div class="ui segment">
                                            <b>{service.name}</b>
                                        </div>
                                        <div class="ui segment">
                                            {service.needsEstimate && (
                                                <div className="ui form">
                                                    <div className="fields">
                                                        <div className="three wide field">
                                                            <label>Prazo</label>
                                                            <Input icon='calendar outline' iconPosition='left' onChange={(e)=> handleDemandsInEstimating('deadline', service._id, e.target.value)} placeholder='0 dias úteis'/>
                                                        </div>
                                                        <div className="four wide field">
                                                            <label>Data</label>
                                                            <InputDate onChange={(data)=> handleDemandsInEstimating('requestDate', service._id, data)} />
                                                        </div>
                                                        <div className="three wide field">
                                                            <label>Valor</label>
                                                            <Input icon='money outline' iconPosition='left' placeholder='0' onChange={(e)=> handleDemandsInEstimating('valueBNZ', service._id, e.target.value)} />
                                                        </div>
                                                        <div className="six wide field">
                                                            <label>Canal</label>
                                                            <Dropdown placeholder='Canal' selection options={[
                                                                {
                                                                    key: 'whatsapp',
                                                                    value: 'whatsapp',
                                                                    text: 'WhatsApp'
                                                                },
                                                                {
                                                                    key: 'email',
                                                                    value: 'email',
                                                                    text: 'E-mail'
                                                                },
                                                                {
                                                                    key: 'call',
                                                                    value: 'call',
                                                                    text: 'Call'
                                                                },
                                                                {
                                                                    key: 'slack',
                                                                    value: 'slack',
                                                                    text: 'Slack'
                                                                }
                                                            ]}
                                                            selectOnBlur={false}
                                                            selectOnNavigation={false}
                                                            onChange={(e, data)=> handleDemandsInEstimating('channel', service._id, data.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="fields">
                                                        <div className="sixteen wide field">
                                                            <label>Descrição</label>
                                                            <TextArea
                                                                placeholder="Descrição do serviço"
                                                                onChange={(e)=> handleComment(e)} 
                                                            >  
                                                            </TextArea>
                                                        </div>
                                                    </div>
                                                    <div className="fields">
                                                        <div className="three wide field">
                                                            <button
                                                                className="ui button red"
                                                                onClick={() =>
                                                                handleAssignModal(
                                                                    service._id,
                                                                    deadlineDemandInEstimating,
                                                                    priceDemandInEstimating,
                                                                    true
                                                                )}
                                                            >Atribuir serviço
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {!service.needsEstimate && (
                                                <>
                                                    <div className="ui form">
                                                        {filteredServicesToAssign.deadline && (
                                                            <div className="fields">
                                                                <div className="sixteen wide field">
                                                                    <label>&nbsp;</label>
                                                                    <i className="icon calendar"></i>{filteredServicesToAssign.deadline} dias úteis
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="fields">
                                                            <div className="sixteen wide field">
                                                                <label>Descrição</label>
                                                                <TextArea
                                                                    placeholder="Descrição do serviço"
                                                                    onChange={(e)=> handleComment(e)} 
                                                                >  
                                                                </TextArea>
                                                            </div>
                                                        </div>
                                                        <div className="fields">
                                                            <div className="sixteen wide field">
                                                                <label>&nbsp;</label>
                                                                <i className="icon dollar sign"></i> <span className="ui red basic label">{service.priceToSelectedClient ? `${service.priceToSelectedClient} BNZ` : 'Selecione o cliente'} </span>
                                                            </div>
                                                        </div>
                                                        <div className="fields">
                                                            <div className="three wide field">
                                                                <button
                                                                    className="ui button red"
                                                                    onClick={() =>
                                                                    handleAssignModal(
                                                                        service._id,
                                                                        service.deadline,
                                                                        service.priceToSelectedClient,
                                                                        false
                                                                    )}
                                                                >Atribuir serviço
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )})) : (
                                <Table>
                                    <Table.Header className="tb-head">
                                        <Table.Row className="tb-head">
                                            <Table.HeaderCell>Nenhum serviço encontrado</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body className="tb-body">
                                        <Table.Row>
                                            <Table.Cell display="flex" flexDirection="row" alignItems="center" width={1}>
                                                Selecione ou busque pelo serviço que deseja atribuir e então selecione o cliente.<br/><br/>
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            )}
                        </div>
                        {!jfDemand &&
                            <div id="table-clients-services" className="col s12 m5" style={{maxWidth: '40%'}}>
                                <div className="row">
                                    <div className="col s12">
                                        <h2>Meus clientes</h2>
                                        <Input
                                            icon='search'
                                            iconPosition='left'
                                            onChange={handleSearchClients}
                                            placeholder='Buscar cliente'
                                            className="searchbar-clients"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col s12">
                                        <table className="responsive-table">
                                            <thead>
                                            <tr>
                                                <th>
                                                    Nome
                                                </th>
                                                <th>
                                                    Email
                                                </th>
                                                <th>
                                                    Telefone
                                                </th>
                                                <th>
                                                    Ação
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {clients && clients.length > 0 ? (
                                                clients?.map((person) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                {person.companyName??'Nome não informado'}
                                                            </td>
                                                            <td>
                                                                {person.businessEmail??'Email não informado'}
                                                            </td>
                                                            <td>
                                                                {person.businessTelephoneNumber??'Telefone não informado'}
                                                            </td>
                                                            <td>
                                                                <button
                                                                    style={selectedClient && selectedClient === person._id ?
                                                                        {background: '#FAFAFA', color: '#D3D2D7'}
                                                                        :
                                                                        {background: '#F14F5C', color: '#FFFFFF'}
                                                                    }
                                                                    onClick={() => handleSelectClient(person._id)}
                                                                    disabled={filteredServicesToAssign && filteredServicesToAssign.length > 0 ? false : true}
                                                                >
                                                                    {selectedClient && selectedClient === person._id ? 'Selecionado' : 'Selecionar'}
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td>
                                                        Clientes não encontrados
                                                    </td>
                                                </tr>
                                            )}
                                            </tbody>
                                            <tfoot>
                                            <Pagination numberOfPages={maxPage} changePage={changePage}/>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}

export default LawyerServicesBonuzPlans