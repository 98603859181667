import React from "react";
import "./LedIcon.css";

const LedIcon = (props) => {

    const {
        type,
        icon
    } = props;

    return (
        <div className={`led-icon circle ${type}`}>
            {icon}
        </div>
    )
}

export default LedIcon;
