
import PaginationData from "../../components/Pagination/PaginationData"
import Moment from 'react-moment'
import { usePagination } from "../../helpers/pagination"

const Notification = ( props ) => {
    const {notifications, checkNotification} = props
    const {
        getItemsPage,
        handleActualPage,
        totalPages,
        actualPage
      } = usePagination(notifications, 10);

    return (
        <>
            <div className="sixteen wide column">
                {
                    getItemsPage().map((notification,key) => {
                        return <div key={key} className='ui message floating small'>
                            <i className="close icon" onClick={() => checkNotification(notification, false)}></i>
                            <div className='header' onClick={()=>{
                                checkNotification(notification)
                            }} style={{cursor: 'pointer'}}>
                                <span className='text'><Moment format='DD/MM/YYYY H:mm:ss'>{notification.createdAt || notification.created_at}</Moment></span> - {notification.senderName} {notification.message} <span className='cliqueaqui'> Clique aqui</span>
                            </div>
                        </div>
                    })
                }
            </div>
            <div className="ui row" style={{marginTop: '10px', textAlign: 'center'}}>
                <div className="sixteen wide column">
                    <PaginationData
                        numberOfPages={totalPages}
                        changePage={handleActualPage}
                        actualPage={actualPage}
                    />
                </div>
            </div>
        </>
    )
}

export default Notification