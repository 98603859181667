import React, { useEffect, useState } from "react";
import "./Documents.css";
import { useNotifications } from "../../helpers/getNotifications";
import Sidebar from "../../components/Sidebar/Sidebar";
import api from "../../services/api/api";
import Loading from "../../components/Loading/Loading";
import { api_url } from "../../config/environment/environmentSettings";
import {getToken} from "../../services/authentication/authentication";

const FileExplorer = ({ data }) => {
  const [currentFolder, setCurrentFolder] = useState(null);
  const [previousFolder, setPreviousFolder] = useState(null);

  const handleFolderClick = (folderName) => {
    setPreviousFolder(currentFolder); // Armazenar a pasta atual antes de mudar
    setCurrentFolder(folderName); // Mudar para a nova pasta
  };

  const handleBackClick = () => {
    setCurrentFolder(previousFolder); // Voltar para a pasta anterior
    setPreviousFolder(null); // Limpar pasta anterior ao voltar
  };

  // Função para renderizar a lista de arquivos da pasta selecionada
  const renderFiles = (item) => {
    // Verificar se a pasta tem arquivos
    if (item.documents.length === 0) {
      return <div className="empty-message">Nenhum arquivo neste diretório</div>;
    }

    return (
      <div className="file-list">
        {item.documents.map((child, index) => (
          <DirectoryItem
            key={index}
            item={child}
            currentFolder={currentFolder}
            onFolderClick={handleFolderClick}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="file-explorer">
      {currentFolder ? (
         <>
            <h2>Pasta atual: {currentFolder}</h2>
            <button className="back-button" onClick={handleBackClick}>
            🏠 Voltar
            </button>
        </>
      ) : null}
      {/* Mostrar arquivos da pasta atual ou estrutura de diretórios raiz */}
      {currentFolder ? (
        data
          .filter((item) => item.type === "folder" && item.name === currentFolder)
          .map((folder) => renderFiles(folder))
      ) : (
        <div className="directory-grid">
          {data.map((item, index) => (
            <DirectoryItem
              key={index}
              item={item}
              currentFolder={currentFolder}
              onFolderClick={handleFolderClick}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const DirectoryItem = ({ item, currentFolder, onFolderClick }) => {
  const isFolderOpen = currentFolder === item.name;

  if (item.type === "folder") {
    return (
      <div className={`directory-item ${currentFolder === item.name ? 'selected' : ''}`}>
        <div
          onClick={() => onFolderClick(item.name)}
          className="folder"
        >
          <div className="icon">📁</div>
          <div className="name">{item.name}</div>
        </div>
        {isFolderOpen && (
          <div className="file-list">
            {item.documents.map((child, index) => (
              <DirectoryItem
                key={index}
                item={child}
                currentFolder={currentFolder}
                onFolderClick={onFolderClick}
              />
            ))}
          </div>
        )}
      </div>
    );
  } else if (currentFolder && item.type === "file") {
    return (
      <div className="directory-item" onClick={() => window.open(`${api_url}/${item.fileName}?token=${getToken()}`, '_blank')}>
        <div className="file">
          <div className="icon">📄</div>
          <div className="name">{item.name}</div>
        </div>
      </div>
    );
  }

  return null;
};


function Documents() {
    const [sidebarIsOpen,setSidebarIsOpen] = useState(false);
    const handleSidebar = (sidebar)=>setSidebarIsOpen(sidebar);
    const {notifications, checkNotification} = useNotifications();
    const [directoryStructure, setDirectoryStructure] = useState([]);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        api.get(`/folders`)
            .then((response) => {
               setDirectoryStructure(response.data);
               setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
            })
    }, [])

  return (
    <div id="documents">
        {loading && <Loading />}
        {!loading && (
            <div className="row">
                <div className="col s12">
                    <Sidebar isOpen={handleSidebar} notifications={notifications} checkNotification={checkNotification} />
                </div>
                <div
                    className={
                        !sidebarIsOpen
                            ? "container-width"
                            : "container-width-sbopen"
                    }
                >
                    <div className="documents">
                        {directoryStructure && directoryStructure.length > 0 ? (
                            <FileExplorer data={directoryStructure} />
                        ) : 'Nenhum diretório encontrado'}
                    </div>
                </div>
            </div>
        )}
    </div>
  );
}

export default Documents;
