import React, {useEffect, useState} from "react";
import "./ClientsPlans.css"
import api from "../../../services/api/api";
import {Link} from "react-router-dom";

const ClientsPlans = (props) => {
    const [clients, setClients] = useState({})
    const plan = props.plan;
    useEffect(() => {
        api.get(`/plans/all?plan=${plan}`)
            .then((response) => {
                setClients(response.data.data)
            })
            .catch((error) => {
                alert('Erro ao carregar os clientes')
            })
    }, [plan])

    return (
        <div id="clients-container">
            <thead id="clients-container-header">
            <th>Empresa</th>
            <th>Plano</th>
            </thead>
            <tbody id="clients-container-body">
            {clients && clients.length > 0
                ? clients.map((client, key) => {
                    return (
                        <tr key={key}>
                            <Link to={`/plano-${plan}/client/${client?.client?._id}?plano=premium`}>
                                <td>
                                    {client?.client?.companyName}
                                </td>
                                <td>
                                    {client?.planIdentifier}
                                </td>
                            </Link>
                        </tr>
                    )
                }):(<h2>Sem clientes</h2>)
            }

            </tbody>
        </div>
    )
}

export default ClientsPlans