import React from "react";
import './Breadcumb.css'
import {Link} from "react-router-dom";

const Breadcumb = (props) => {
    const breadcumbs = props.breadcumbs

    return (
        <div className="col s12">
            <div id="breadcumb">
                Você está em: &nbsp;
                {breadcumbs.map((breadcumb, counter) => {
                    return (<Link to={breadcumb.link} key={counter} className="breadcrumb">{breadcumb.name}</Link>)
                })}
            </div>
        </div>
    )
}

export default Breadcumb