import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import "./ChatServiceModal.css";
import { Table } from 'semantic-ui-react';
import StatusChips from '../../StatusChips/StatusChips';
import { formatedDate } from "../../../helpers/Date/formatedDate";
import { api_url } from "../../../config/environment/environmentSettings";
import { ReactComponent as FileAnexoIcon } from "../../../assets/fileAnexoIcon.svg";
import { Input } from 'semantic-ui-react';
import {FaLocationArrow} from "react-icons/fa";
import React, {useEffect, useState} from "react";
import api from "../../../services/api/api";
import {Link} from "react-router-dom";
import {getToken} from "../../../services/authentication/authentication";


const ChatServiceModal = (props) => {

    const {
        openModal,
        handleClose,
        chatInfo,
        demandId,
        clientId,
        type
    } = props;

    const [messages,setMessages] = useState([]);
    const [message, setMessage] = useState("");

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: 'none !important',
        boxShadow: 24,
        p: 4,
    };

    useEffect(()=>{
      if(demandId !== '' && demandId !== undefined){
        api.get('/chat/show/'+demandId).then((response) => {
            setMessages(response?.data?.chat_messages)
        })
      }

      if(clientId !== '' && clientId !== undefined){
          api.get('/chat/show/'+clientId+'/'+type).then((response) => {
              setMessages(response?.data?.chat_messages)
          })
      }
    },[demandId,clientId, type]);

    const sendMessageByKey = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleSendMessage(event,type)
        }
    };

    const handleSendMessage = (e, type = null) => {
        e.preventDefault();
        if(type === null){
            api.post('/chat/send/message',{
                "demandId":demandId,
                "message":message
            }).then((response) => {
                setMessage("");
                setMessages(response?.data)
            })
        }else{
            api.post('/chat/send/message/'+type,{
                "clientId":clientId,
                "message":message
            }).then((response) => {
                setMessage("");
                setMessages(response?.data)
            })
        }
    }

    return (
        <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus
        >
            <Box sx={style} className="box chat-modal">
                {chatInfo?.demandId !== undefined ?
                 <>
                <Table id='table-modal'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Número</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>Data de interação</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>Status</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>Baixar arquivo</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row className='tr-button'>
                            <Table.Cell width={1}><strong>{`#000${chatInfo.count}`}</strong></Table.Cell>
                            <Table.Cell width={1} textAlign='center'>{formatedDate(chatInfo.createdAt)}</Table.Cell>
                            <Table.Cell width={1} textAlign='center'>
                                    <StatusChips status={chatInfo.status} />
                            </Table.Cell>
                            <Table.Cell width={1} textAlign='center' style={!chatInfo.fileName ? {} : {display: "flex", alignItems: "center"}}>
                                {!chatInfo.fileName ?
                                "Sem anexo atribuído"
                                : <a className="anexo-file" target="_blank" href={`${api_url}/${chatInfo.fileName}?token=${getToken()}` } rel="noreferrer" download>Anexo <FileAnexoIcon/></a>}
                            </Table.Cell>
                        </Table.Row>
                    <div style={{height: "3px"}}/>
                    </Table.Body>
                </Table>
                </>
                 : 
                <div className='header-client'>
                    <div><p><strong>{chatInfo?.firstName}</strong></p></div>
                    <div><Link to="/services" target="_parent"><span>+ <i>Incluir Serviço</i></span></Link></div>
                </div>
                }
                <div className='chat'>
                    {messages?.map(({message,sender,updatedAt,updated_at, documents}) => {
                        if(sender === 'lawyer'){
                            return (    
                                <>
                                    <div className="ballon-lawyer message-hour">
                                        {new Date(updated_at).toLocaleDateString('pt-BR')} {new Date(updated_at).toLocaleTimeString('pt-BR')}
                                    </div>
                                    <div className='baloon lawyer-sender'>
                                        {message}
                                    </div>
                                </>

                            );
                        }else{
                            return (
                                <>
                                    <div className="ballon-client message-hour">
                                        {new Date(updatedAt).toLocaleDateString('pt-BR')} {new Date(updatedAt).toLocaleTimeString('pt-BR')}
                                    </div>
                                    <div className='baloon client-sender'>
                                        {message}   
                                    </div>
                                    {documents.map(item => (
                                        <div style={{textAlign: 'right', marginRight: '20px'}}>
                                            <a className="anexo-file" target="_blank" href={`${api_url}/${item.fileName}?token=${getToken()}` } rel="noreferrer" download>{item.name} <FileAnexoIcon/></a>
                                        </div>
                                    ))}
                                </>

                            );
                        }

                    })}
                </div>
                <Input
                    id="chat-message-input"
                    placeholder="Escreva uma mensagem"
                    value={message}
                    onChange={(e)=>setMessage(e.target.value)}
                    onKeyPress={(event) => sendMessageByKey(event)}
                />
                <div className='pointer-chat'>
                    <FaLocationArrow
                        onClick={(e)=>handleSendMessage(e, type)}
                    />
                </div>
            </Box>
        </Modal>
    );
}

export default ChatServiceModal;