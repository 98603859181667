
const SidebarNotification = ( props ) => {
    const {notifications, checkNotification} = props
    return (
        notifications?.length > 0 ? (
            notifications?.slice(0, 10).map((notification,key) => {
                return <div key={key}>
                    <p className="notification-link" onClick={()=>checkNotification(notification)}>
                        {notification.senderName} {notification.message} <button>Clique aqui</button>
                    </p>
                </div>
            })
        ) : (
            <p style={{textAlign: 'center'}}>Sem notificações</p>
        )
    )
}

export default SidebarNotification