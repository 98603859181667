import React, {useEffect, useState} from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Searchbar from "../../components/Searchbar/Searchbar";
import Pagination from "../../components/Pagination/Pagination";
import api from "../../services/api/api";
import CustomTable from "../../components/Table/Table";
import ClientDetailsView from "../../components/ClientDetailsView/ClientDetailsView"
import { useNotifications } from "../../helpers/getNotifications";

const Clients = () => {
    const [sidebarIsOpen,setSidebarIsOpen] = useState(false);
    const handleSidebar = (sidebar)=>setSidebarIsOpen(sidebar);
    const {notifications, checkNotification} = useNotifications();

    const [clients, setClients] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const [maxPage, setMaxPage] = useState(1)
    const [search, setSearch] = useState("")
    const [pageView, setPageView] = useState("default")
    const [info, setInfo] = useState({})
    const [modalView, setModalView] = useState("default")

    useEffect(() => {
        api.get(`/clients/index?page=${currentPage}&search=${search}`)
            .then((response) => {
                setClients(response.data.data)
                setMaxPage(response.data.last_page)
            })
            .catch((error) => {})
    }, [currentPage, search])

    const handleView = (info) => {
        setInfo(info)
        setPageView("info")
    }

    const changePage = (page = 1) => {
        setCurrentPage(page)
    }

    const searchClients = (search) => {
        let intervalToSeacrh = setInterval(() => {
            setSearch(search)
            clearInterval(intervalToSeacrh)
        }, 1500)
    }

    if (pageView === "default") {
        return (
            <div id="clients">
                <div className="row">
                    <div className="col s12">
                        <Sidebar isOpen={handleSidebar} notifications={notifications} checkNotification={checkNotification} />
                    </div>
                    <div
                        className={
                            !sidebarIsOpen
                                ? "container-width"
                                : "container-width-sbopen"
                        }
                    >
                        <div className="col s6  offset-s4">
                            <Searchbar search={searchClients} />
                        </div>
                        <CustomTable
                            dataClients={clients}
                            pagination={
                                <Pagination
                                    numberOfPages={maxPage}
                                    changePage={changePage}
                                />
                            }
                            setInfo={handleView}
                        />
                    </div>
                </div>
            </div>
        )
    } else if (pageView === "info") {
        return (
            <div id="clients">
                <div className="row">
                    <div className="col s12">
                        <Sidebar isOpen={handleSidebar} notifications={notifications} checkNotification={checkNotification}/>
                    </div>
                    <div
                        className={
                            !sidebarIsOpen
                                ? "container-width"
                                : "container-width-sbopen"
                        }
                    >
                        <ClientDetailsView
                            info={info}
                            view={modalView}
                            changeView={setModalView}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default Clients