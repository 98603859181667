import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import "../ForgotPassword/ForgotPasswordModal.css";

const PrivacyPolicy = (props) => {

    const {
        handleClose,
        openModal
    } = props;


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: 'none !important',
        boxShadow: 24,
        p: 4,
        overflow: "scroll",
        zIndex: 99
    };

    const divStyleP = {
        overflowY: "scroll",
        height: 350
    }

    return (
        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus
        >
            <Box sx={style} className="box-forgot">
                <h2>Solicitação de redefinição de senha</h2>
                <div className='div-p' style={divStyleP}>
                    <p>POL&Iacute;TICA DE PRIVACIDADE</p>

                    <p> &Uacute;ltima atualiza&ccedil;&atilde;o: 25 de novembro de 2020.</p>

                    <p></p>

                    <p> Plataforma de software desenvolvida pela BONUZ DO BRASIL ASSESSORIA EMPRESARIAL LTDA., pessoa jur&iacute;dica de direito privado inscrita no CNPJ/ME sob n&ordm; 36.454.710/0001-39, com sede na Cidade de S&atilde;o Paulo, Estado de S&atilde;o Paulo, na Rua Estados Unidos, n&ordm; 1140, Sala A, Jardim Am&eacute;rica, CEP 01427-001 (&ldquo;BONUZ&rdquo;).</p>

                    <p></p>

                    <p> Esta Pol&iacute;tica de Privacidade destina-se a ajudar voc&ecirc; a entender quais informa&ccedil;&otilde;es coletamos, porque as coletamos e como voc&ecirc; pode atualizar, gerenciar, exportar e excluir essas informa&ccedil;&otilde;es.</p>

                    <p></p>

                    <p> INFORMA&Ccedil;&Otilde;ES COLETADAS</p>

                    <p></p>

                    <p> Coletamos informa&ccedil;&otilde;es dos usu&aacute;rios para realizar o cadastro na Plataforma BONUZ e para fornecer servi&ccedil;os melhores a todos e para possibilitar o desenvolvimento da atividade da BONUZ, quais sejam:</p>

                    <p></p>

                    <p> 1) Nome completo: coletado para identifica&ccedil;&atilde;o do usu&aacute;rio e para relacionamento com a BONUZ;</p>

                    <p></p>

                    <p> 2) E-mail: utilizado para cadastro, login e canal de comunica&ccedil;&atilde;o;</p>

                    <p></p>

                    <p> 3) Senha de login: c&oacute;digo secreto criado pelo usu&aacute;rio para acesso a Plataforma;</p>

                    <p></p>

                    <p> As informa&ccedil;&otilde;es coletadas pela BONUZ s&atilde;o utilizadas para criar, desenvolver, operar, fornecer e melhorar nossos Servi&ccedil;os, conte&uacute;do e publicidade, al&eacute;m de prevenir perdas e evitar fraudes. Tamb&eacute;m podemos usar suas informa&ccedil;&otilde;es pessoais com finalidades relacionadas &agrave; seguran&ccedil;a da rede e da conta, inclusive para proteger nossos servi&ccedil;os em benef&iacute;cio de todos os nossos usu&aacute;rios.</p>

                    <p></p>

                    <p> De tempos em tempos, podemos usar suas informa&ccedil;&otilde;es pessoais para enviar avisos importantes, como comunica&ccedil;&otilde;es sobre altera&ccedil;&otilde;es em nossos termos, condi&ccedil;&otilde;es e normas. Como essas informa&ccedil;&otilde;es s&atilde;o importantes para sua intera&ccedil;&atilde;o com a BONUZ, voc&ecirc; n&atilde;o pode optar por n&atilde;o receber essas comunica&ccedil;&otilde;es.</p>

                    <p></p>

                    <p> Podemos tamb&eacute;m usar informa&ccedil;&otilde;es pessoais para prop&oacute;sitos internos, como auditoria, an&aacute;lise de dados e pesquisa para melhorar os servi&ccedil;os e as comunica&ccedil;&otilde;es de usu&aacute;rios da BONUZ.</p>

                    <p></p>

                    <p> Quando voc&ecirc; n&atilde;o est&aacute; conectado &agrave; Conta da BONUZ, armazenamos as informa&ccedil;&otilde;es que coletamos com identificadores exclusivos vinculados ao navegador, aplicativo ou dispositivo que voc&ecirc; est&aacute; usando. Isso nos ajuda a manter as prefer&ecirc;ncias que voc&ecirc; j&aacute; selecionou, por exemplo.</p>

                    <p></p>

                    <p></p>

                    <p></p>

                    <p> INFORMA&Ccedil;&Atilde;O DE LOCALIZA&Ccedil;&Atilde;O E DEMAIS PERMISS&Otilde;ES</p>

                    <p></p>

                    <p> Quando voc&ecirc; acessa a Plataforma, com a sua permiss&atilde;o, podemos coletar certas informa&ccedil;&otilde;es automaticamente, incluindo, mas n&atilde;o limitado:</p>

                    <p></p>

                    <p> i. Dados de Cadastro: coleta de informa&ccedil;&otilde;es para cria&ccedil;&atilde;o e altera&ccedil;&atilde;o de cadastro.</p>

                    <p></p>

                    <p> ii. Dados de Transa&ccedil;&atilde;o: Para possibilitar e comprovar a contrata&ccedil;&atilde;o de nossos Servi&ccedil;os e realizar o pagamento das mensalidades, coletamos informa&ccedil;&otilde;es de transa&ccedil;&otilde;es relacionadas ao que o Usu&aacute;rio faz dos servi&ccedil;os.</p>

                    <p></p>

                    <p> iii. Conte&uacute;do de Usu&aacute;rio: Para atender ao chamado do Usu&aacute;rio relativo &agrave; d&uacute;vidas t&eacute;cnicas e problemas do uso da Plataforma, bem como para identifica&ccedil;&atilde;o do Usu&aacute;rio, coletamos as informa&ccedil;&otilde;es que Voc&ecirc; envia ao entrar em contato com o suporte da BONUZ.</p>

                    <p></p>

                    <p> iv. Informa&ccedil;&otilde;es de uso: Para comprova&ccedil;&atilde;o da contrata&ccedil;&atilde;o e/ou utiliza&ccedil;&atilde;o de nossos Servi&ccedil;os, na hip&oacute;tese de questionamento, coletamos informa&ccedil;&otilde;es sobre como o Usu&aacute;rio interage com nossos servi&ccedil;os. Entre essas informa&ccedil;&otilde;es, est&atilde;o, por exemplo, data e hor&aacute;rio de acesso, recursos do aplicativo ou p&aacute;ginas visitadas, falhas do aplicativo e outras atividades do sistema, tipo de navegador e sites de terceiros que Voc&ecirc; estava usando antes de interagir com nossos servi&ccedil;os. Em alguns casos, fazemos isso pelo uso de cookies, tags de pixels e tecnologias similares que criam e mant&ecirc;m identificadores &uacute;nicos.</p>

                    <p></p>

                    <p> v. Informa&ccedil;&otilde;es do aparelho: coleta de informa&ccedil;&otilde;es sobre o aparelho que o Usu&aacute;rio usa para acessar nossos servi&ccedil;os para an&aacute;lise de comportamento do usu&aacute;rio no sistema da BONUZ, identifica&ccedil;&atilde;o de erros no sistema, etc., inclusive o modelo de hardware, sistemas operacionais e vers&otilde;es, hor&aacute;rio de login, software, nomes e vers&otilde;es de arquivos, identificadores &uacute;nicos do aparelho, identificadores de an&uacute;ncios, n&uacute;meros de s&eacute;rie e informa&ccedil;&otilde;es de movimento do aparelho.</p>

                    <p></p>

                    <p> vi. Cookies: A BONUZ pode usar os cookies com v&aacute;rios prop&oacute;sitos, tais como: evitar que voc&ecirc; necessite digitar sempre que fizer seu login informa&ccedil;&otilde;es como nome de usu&aacute;rio e senha; facilitar sua navega&ccedil;&atilde;o em nossa Plataforma; coletar informa&ccedil;&otilde;es estat&iacute;sticas; customizar a nossa Plataforma de acordo com suas prefer&ecirc;ncias pessoais; e, proporcionar maior seguran&ccedil;a das informa&ccedil;&otilde;es.</p>

                    <p></p>

                    <p> a. Alguns cookies expiram assim que a sess&atilde;o termina e voc&ecirc; fecha o seu navegador. Outros s&atilde;o salvos em seu computador. Os cookies n&atilde;o cont&ecirc;m informa&ccedil;&otilde;es confidenciais e n&oacute;s n&atilde;o trocamos cookies com quaisquer websites de terceiros ou de fornecedores externos de dados.</p>

                    <p></p>

                    <p> b. O Usu&aacute;rio poder&aacute; desabilitar os cookies por meio das op&ccedil;&otilde;es de configura&ccedil;&atilde;o do seu respectivo navegador. Contudo, ao decidir pela proibi&ccedil;&atilde;o dos cookies, o Usu&aacute;rio est&aacute; ciente e reconhece que &eacute; poss&iacute;vel que o Site e/ou Aplicativo n&atilde;o desempenhe todas as suas funcionalidades.</p>

                    <p></p>

                    <p> DIVULGA&Ccedil;&Atilde;O A TERCEIROS</p>

                    <p></p>

                    <p> &Agrave;s vezes, a BONUZ pode divulgar &agrave; terceiros determinadas informa&ccedil;&otilde;es pessoais para fornecer ou melhorar os Servi&ccedil;os, por exemplo, para que sua experi&ecirc;ncia e evolu&ccedil;&atilde;o com BONUZ seja constante. Ao fazer isso, exigimos que tais terceiros lidem com essas informa&ccedil;&otilde;es de acordo com as leis relevantes. Informa&ccedil;&otilde;es pessoais nunca ser&atilde;o compartilhadas com terceiros para prop&oacute;sitos de marketing.</p>

                    <p></p>

                    <p> Adicionalmente, compartilharemos algumas informa&ccedil;&otilde;es &agrave;s empresas contratantes dos servi&ccedil;os BONUZ a fim de realizar a cobran&ccedil;a dos servi&ccedil;os realizada pelo Usu&aacute;rio, emitir Nota Fiscal, fiscalizar o uso destes servi&ccedil;os, aplicar san&ccedil;&otilde;es e/ou multas, enviar notifica&ccedil;&otilde;es e tomar medidas cab&iacute;veis aos usu&aacute;rios de acordo com as regras internas da empresa contratante dos servi&ccedil;os.</p>

                    <p></p>

                    <p> Esses terceiros t&ecirc;m acesso &agrave;s suas Informa&ccedil;&otilde;es Pessoais apenas para realizar essas tarefas em nosso nome e s&atilde;o obrigados a n&atilde;o divulg&aacute;-las ou utiliz&aacute;-las para qualquer outro prop&oacute;sito.</p>

                    <p></p>

                    <p> CONFORMIDADE COM LEIS</p>

                    <p></p>

                    <p> A seguran&ccedil;a das suas informa&ccedil;&otilde;es pessoais &eacute; importante para n&oacute;s, mas lembre-se de que nenhum m&eacute;todo de transmiss&atilde;o pela Internet ou m&eacute;todo de armazenamento eletr&ocirc;nico &eacute; 100% seguro. Nos esfor&ccedil;amos para adotar as melhores pr&aacute;ticas de desenvolvimento bem como contratar os melhores fornecedores necess&aacute;rios para a presta&ccedil;&atilde;o do nosso Servi&ccedil;o de forma segura para proteger suas Informa&ccedil;&otilde;es Pessoais, contudo n&atilde;o podemos garantir sua seguran&ccedil;a absoluta.</p>

                    <p></p>

                    <p> Esta Pol&iacute;tica de Privacidade se aplica a todos os servi&ccedil;os oferecidos pela BONUZ. Esta Pol&iacute;tica de Privacidade n&atilde;o se aplica a servi&ccedil;os que tenham pol&iacute;ticas de privacidade separadas, de terceiros, que n&atilde;o incorporem esta Pol&iacute;tica de Privacidade.</p>

                    <p></p>

                    <p> Esta Pol&iacute;tica de Privacidade n&atilde;o se aplica a:</p>

                    <p></p>

                    <p> a) pr&aacute;ticas de coleta de informa&ccedil;&otilde;es de outras empresas e organiza&ccedil;&otilde;es que anunciam nossos Servi&ccedil;os;</p>

                    <p></p>

                    <p> b) servi&ccedil;os oferecidos por outras empresas ou indiv&iacute;duos, inclusive produtos ou sites que podem incluir servi&ccedil;os da BONUZ, que s&atilde;o exibidos para voc&ecirc; nos resultados da pesquisa ou tenham links em nossos servi&ccedil;os.</p>

                    <p></p>

                    <p> Nosso Servi&ccedil;o pode conter links para outros sites que n&atilde;o s&atilde;o operados por n&oacute;s. Se voc&ecirc; clicar em um link de terceiros, voc&ecirc; ser&aacute; direcionado para o site desse terceiro. Recomendamos que voc&ecirc; analise a Pol&iacute;tica de Privacidade de cada site que voc&ecirc; visita, pois n&atilde;o temos controle e n&atilde;o assumimos qualquer responsabilidade pelo conte&uacute;do, pol&iacute;ticas de privacidade ou pr&aacute;ticas de terceiros ou servi&ccedil;os.</p>

                    <p></p>

                    <p> A BONUZ permite que voc&ecirc; mantenha facilmente suas informa&ccedil;&otilde;es pessoais precisas, completas e atualizadas. N&oacute;s manteremos suas informa&ccedil;&otilde;es pessoais pelo per&iacute;odo necess&aacute;rio para cumprir as finalidades descritas nesta Pol&iacute;tica de Privacidade e em nossos resumos de privacidade espec&iacute;ficos. Para definir esses per&iacute;odos, examinamos com cuidado a necessidade de coletar informa&ccedil;&otilde;es pessoais e, ao identificarmos uma necessidade relevante, retemos a informa&ccedil;&atilde;o pelo per&iacute;odo mais curto poss&iacute;vel necess&aacute;rio para concluir a finalidade da coleta, a menos que um per&iacute;odo de reten&ccedil;&atilde;o mais longo seja exigido por lei.</p>

                    <p></p>

                    <p> CORRE&Ccedil;&Atilde;O E EXCLUS&Atilde;O DE INFORMA&Ccedil;&Otilde;ES</p>

                    <p></p>

                    <p> Em alguns casos, armazenamos os dados por per&iacute;odos limitados, quando eles precisam ser mantidos para fins comerciais ou legais leg&iacute;timos.</p>

                    <p></p>

                    <p> Tentamos garantir que nossos servi&ccedil;os protejam as informa&ccedil;&otilde;es de exclus&atilde;o acidental ou maliciosa. Devido a isso, pode haver um espa&ccedil;o de tempo entre o momento em que voc&ecirc; exclui algo e o momento em que as c&oacute;pias s&atilde;o exclu&iacute;das dos nossos sistemas ativos e de backup.</p>

                    <p></p>

                    <p> Se Voc&ecirc; acredita que a BONUZ det&eacute;m alguma informa&ccedil;&atilde;o inexata sua, Voc&ecirc; tem o direito de solicitar a corre&ccedil;&atilde;o dessa informa&ccedil;&atilde;o. A atualiza&ccedil;&atilde;o dos dados cadastrais, bem como a solicita&ccedil;&atilde;o de informa&ccedil;&otilde;es armazenadas pela BONUZ poder&atilde;o ser solicitadas por meio do e-mail arthur@bonuz.it.</p>

                    <p></p>

                    <p> Para excluir os dados da sua Conta na Plataforma, o usu&aacute;rio dever&aacute; encaminhar solicita&ccedil;&atilde;o para arthur@bonuz.it caso em que iniciamos imediatamente o processo de remo&ccedil;&atilde;o deles do nosso sistema. Primeiro, temos como objetivo remov&ecirc;-los rapidamente da visualiza&ccedil;&atilde;o, e eles n&atilde;o poder&atilde;o mais ser utilizados para personalizar sua experi&ecirc;ncia na BONUZ.</p>

                    <p></p>

                    <p> Em seguida, iniciamos um processo criado para excluir de forma completa e segura os dados dos nossos sistemas de armazenamento.</p>

                    <p></p>

                    <p> A exclus&atilde;o segura &eacute; importante para proteger nossos usu&aacute;rios contra a perda de dados acidental. A exclus&atilde;o completa dos dados dos nossos servidores &eacute; igualmente importante para a tranquilidade dos usu&aacute;rios e nossa prote&ccedil;&atilde;o. Esse processo geralmente leva cerca de 15 (quinze) dias a partir do momento da exclus&atilde;o.</p>

                    <p></p>

                    <p> Ap&oacute;s a exclus&atilde;o de sua conta, fica ressalvada a possibilidade de guarda pela BONUZ das informa&ccedil;&otilde;es e/ou dados por determina&ccedil;&atilde;o legal ou cuja manuten&ccedil;&atilde;o seja necess&aacute;ria para (i) cumprimento de ordem judicial, no &acirc;mbito de processos judiciais e/ou administrativos, (ii) estudo por &oacute;rg&atilde;o de pesquisa, nos termos da legisla&ccedil;&atilde;o aplic&aacute;vel, (iii) transfer&ecirc;ncia a terceiro, desde que respeitados os requisitos de tratamento da legisla&ccedil;&atilde;o aplic&aacute;vel, e (iv) uso exclusivo da BONUZ, desde que os dados sejam anonimizados.</p>

                    <p></p>

                    <p> Na hip&oacute;tese acima, a BONUZ garante que os terceiros com quem eventualmente tenha compartilhado os dados do Usu&aacute;rio adotar&aacute; a mesma medida de exclus&atilde;o e n&atilde;o tratamento dos dados do Usu&aacute;rio.</p>

                    <p></p>

                    <p> ARMAZENAMENTO E PROCESSAMENTO</p>

                    <p></p>

                    <p> Suas informa&ccedil;&otilde;es coletadas por meio do nosso Servi&ccedil;o podem ser armazenadas e processadas no Brasil ou em qualquer outro pa&iacute;s em que a BONUZ, seus prestadores de servi&ccedil;os ou parceiros mantenham instala&ccedil;&otilde;es; por&eacute;m sempre para pa&iacute;ses quem proporcionem grau de prote&ccedil;&atilde;o de dados pessoais adequados, nos termos da Lei.</p>

                    <p></p>

                    <p> Ao se registrar e usar o Servi&ccedil;o, voc&ecirc; concorda com a transfer&ecirc;ncia de informa&ccedil;&otilde;es para qualquer pa&iacute;s em que a BONUZ, prestadores de servi&ccedil;os ou parceiros mantenham instala&ccedil;&otilde;es, bem como com o uso e divulga&ccedil;&atilde;o de informa&ccedil;&otilde;es sobre voc&ecirc;, conforme descrito nesta Pol&iacute;tica de Privacidade. Usamos prote&ccedil;&otilde;es para ajudar a manter seguras as informa&ccedil;&otilde;es coletadas por meio do Plataforma, de forma a tomar medidas para verificar a sua identidade antes de lhe conceder acesso &agrave; sua conta.</p>

                    <p></p>

                    <p> Fa&ccedil;a sua parte para nos ajudar. Voc&ecirc; &eacute; respons&aacute;vel por manter o sigilo de suas informa&ccedil;&otilde;es da conta, bem como por controlar o acesso a e-mails entre voc&ecirc; e a BONUZ. Suas configura&ccedil;&otilde;es de privacidade tamb&eacute;m podem ser afetadas pelas mudan&ccedil;as nos servi&ccedil;os de redes sociais que voc&ecirc; se conecta a BONUZ. N&atilde;o somos respons&aacute;veis pela funcionalidade, privacidade ou medidas de seguran&ccedil;a de qualquer outra organiza&ccedil;&atilde;o.</p>

                    <p></p>

                    <p> ALTERA&Ccedil;&Otilde;ES A ESTA POL&Iacute;TICA DE PRIVACIDADE</p>

                    <p></p>

                    <p> Podemos atualizar nossa Pol&iacute;tica de Privacidade de tempos em tempos. N&oacute;s notificaremos voc&ecirc; de quaisquer altera&ccedil;&otilde;es publicando a nova Pol&iacute;tica de Privacidade nesta p&aacute;gina.</p>

                    <p></p>

                    <p> &Eacute; aconselh&aacute;vel rever esta Pol&iacute;tica de Privacidade periodicamente para quaisquer altera&ccedil;&otilde;es. As mudan&ccedil;as nesta Pol&iacute;tica de Privacidade s&atilde;o efetivadas quando postadas nesta p&aacute;gina, por&eacute;m manteremos os usu&aacute;rios informados de qualquer altera&ccedil;&atilde;o do conte&uacute;do desta Pol&iacute;tica de Privacidade.</p>

                    <p></p>

                    <p> COMUNICA&Ccedil;&Otilde;ES</p>

                    <p></p>

                    <p> N&atilde;o usaremos suas Informa&ccedil;&otilde;es Pessoais para contat&aacute;-lo com boletins informativos, materiais de marketing ou promocionais e outras informa&ccedil;&otilde;es sem o seu consentimento pr&eacute;vio. Podemos enviar as notifica&ccedil;&otilde;es necess&aacute;rias para a execu&ccedil;&atilde;o do Servi&ccedil;o n&atilde;o limitado a manuten&ccedil;&atilde;o, atualiza&ccedil;&otilde;es, rescis&atilde;o da conta, indica&ccedil;&atilde;o de objetivos, indica&ccedil;&atilde;o de a&ccedil;&otilde;es e materiais de marketing relativos &agrave; objetivos e a&ccedil;&otilde;es relacionadas &agrave; suas escolhas de conte&uacute;do na nossa Plataforma.</p>

                    <p></p>

                    <p> ENTRE EM CONTATO</p>

                    <p></p>

                    <p> Se voc&ecirc; tiver alguma d&uacute;vida sobre esta Pol&iacute;tica de Privacidade, entre em contato conosco por meio do e-mail arthur@bonuz.it. Este meio de comunica&ccedil;&atilde;o servir&aacute; para solicita&ccedil;&otilde;es de corre&ccedil;&atilde;o, exclus&atilde;o ou apenas conhecimento dos dados que temos dos usu&aacute;rios, que pode levar at&eacute; 7 (sete) dias.</p>

                    <p></p>

                    <p> A BONUZ &eacute; a controladora dos dados, e nosso ponto de contato/encarregado &eacute;: arthur@bonuz.it. O operador dos dados &eacute; rodrigo@bonuz.it, o qual nos auxilia a armazenar os dados de forma segura.</p>
                </div>
            </Box>
        </Modal>
    );
};

export default PrivacyPolicy;