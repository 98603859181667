import React from "react";
import {FaAngleLeft, FaAngleRight} from "react-icons/fa";

const PaginationData = (props) => {

    const numberOfPagesCounter = props.numberOfPages
    const actualPage = props.actualPage
    let nextPage = actualPage + 1
    if (nextPage > numberOfPagesCounter) {
        nextPage = numberOfPagesCounter        
    }

    let backPage = actualPage - 1
    if (backPage <= 0) {
        backPage = 1        
    }
    let numberOfPages = [];
    for (let i = 1; i <= numberOfPagesCounter; i++){
        numberOfPages.push(<div onClick={() => props.changePage(i)} className={`${actualPage === i ? 'selected' : ''}`}> {i} </div>)
    }

    return (
        (numberOfPagesCounter > 0 && (
            <div className="pagination">
                <FaAngleLeft onClick={() => props.changePage(backPage)}/>
                    {numberOfPages}
                <FaAngleRight onClick={() => props.changePage(nextPage)} />
            </div>
        ))
    )
}

export default PaginationData
