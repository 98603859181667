import { useState } from "react"

const SidebarDropdown = ({children, icon, name, initialStateOpen}) => {
    const [open, setOpen] = useState(initialStateOpen)

    return (
        <>
            <div className="icon-container" style={{padding: '20px 0 0px', cursor: 'pointer'}} onClick={() => setOpen(!open)}>
                <div>{icon}</div>
                <div><p>{name} <span id="arrow-down-dp-menu">&#9662;</span></p></div>
            </div>
            <div className={open ? "show collapse" : 'collapse'}>
                {children}
            </div>
        </>
    )
}

export default SidebarDropdown