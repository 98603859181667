import React from "react";
import DataTables from "datatables.net-se";
import { config } from "./pt_br";
import JSZip from "jszip";
import PDFMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "datatables.net-buttons-se";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-searchpanes-se";
import "datatables.net-select-se";
import "datatables.net-se/css/dataTables.semanticui.min.css";
import "datatables.net-buttons-se/css/buttons.semanticui.min.css";
import "datatables.net-select-se/css/select.semanticui.min.css";
import "datatables.net-searchpanes-se/css/searchPanes.semanticui.min.css";

window.JSZip = JSZip;
PDFMake.vfs = pdfFonts.pdfMake.vfs;

export class ReactDataTables extends React.Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.initializeDataTable();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      // Se as props mudaram, reconfigurar o DataTable
      this.destroyDataTable();
      this.initializeDataTable();
    }
  }

  componentWillUnmount() {
    this.destroyDataTable();
  }

  initializeDataTable() {
    const properties = { ...config, ...this.props };
    this.dataTable = new DataTables(this.tableRef.current, properties);
  }

  destroyDataTable() {
    if (this.dataTable) {
      this.dataTable.destroy();
    }
  }

  render() {
    return (
      <div style={{ width: "100%" }}>
        <table
          className="ui table compact small selectable celled"
          ref={this.tableRef}
        />
      </div>
    );
  }
}

export default ReactDataTables;
