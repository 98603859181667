import React, {useState} from "react";
import { Input } from "semantic-ui-react"
import ForgotPasswordModal from "../../Modals/ForgotPassword/ForgotPasswordModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import api from "../../../services/api/api";

const ForgotPassWord = () => {

    const history = useHistory();

    const [openForgetPasswordModal, setOpenForgetPasswordModal] = useState(false);
    const [email, setEmail] = useState("");

    const handleOpen = () => {
        setOpenForgetPasswordModal(true);
    }
    const handleClose = () => setOpenForgetPasswordModal(false);

    const handleSubmit = (e) =>{
        e.preventDefault()

        api.post('/account/forgot/password',{
           email:email
        }).then(()=>{
            handleOpen()
        }).catch((error)=>{
            alert('Usuário não encontrado/inexistente')
            window.location.reload();
        })
    }

    return (
        <form>
            <ForgotPasswordModal 
                handleOpen={handleOpen}
                handleClose={handleClose}
                openModal={openForgetPasswordModal}
            />
            <div className="input-content" style={{marginTop: "20px"}}>
                <label>E-mail</label>
                <Input icon='mail' iconPosition='left' onChange={e => setEmail(e.target.value)} placeholder='seunegocio@suaempresa.com' name="email" id="email" type="email" className="validate"/>
            </div>
            <div className="finalizar-cadastro">
                <button className="waves-effect waves-light btn-small" onClick={(e) => handleSubmit(e)}>
                    Enviar
                </button>
                <span onClick={() => history.goBack()}>Voltar</span>
            </div>
        </form>
    );
};

export default ForgotPassWord;