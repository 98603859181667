import "./BaloonInfo.css";
import { BsInfoCircle } from "react-icons/bs";

const BaloonInfo = (props) => {

    return(
        <div className="baloon-info">
            <div className="icon">
                <BsInfoCircle/>
            </div>
            <div className="bar">
            </div>
            <div>
                {props.children}
            </div>
        </div>
    )
}

export default BaloonInfo;