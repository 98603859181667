import React, {useEffect, useState} from 'react'
import './ScheduleActivities.css'
import api from "../../../services/api/api";
import StatusChanger from "../StatusChanger/StatusChanger";
const ScheduleActivities = (props) => {

    const [activities,setActivites] = useState({});

    useEffect(() => {
        api.get(`/data-privacy/index/${props.clientId}`)
            .then((response)=>{
                setActivites(response.data)
            })
            .catch((error)=>{})
    }, [props.clientId]);

    const changeDateActivity = (activityId, date) => {
        api.put(`/data-privacy/activities/${props.clientId}/${activityId}`,{
            'schedule_date':date
        }).then((response)=>{
            window.location.reload()
        })
    }

    const changeStatusActivity = (activityId, status) => {
        api.put(`/data-privacy/activities/${props.clientId}/${activityId}`,{
            'status':status
        }).then((response)=>{
            window.location.reload()
        })
    }


    return(
        <div className="col s10">
            <div id="clients-schedule-activities-container">
                <thead id="clients-schedule-activities-container-header">
                <th>Atividade</th>
                <th>Data</th>
                <th>Status</th>
                <th>Responsavel</th>
                </thead>
                <tbody id="clients-schedule-activities-container-body">

                {activities?.data?.length > 0 && activities?.data?.map((activity,counter)=>{

                    return (
                        <tr key={counter}>
                            <td>
                                {activity.schedule_name}
                            </td>
                            <td>
                                <input type="date"  value={activity.schedule_date} onChange={(e)=>changeDateActivity(activity.schedule_activity_id,e.target.value)}/>
                            </td>
                            <td>
                                <StatusChanger status={activity?.status} handleUpdate={changeStatusActivity} activityId={activity.schedule_activity_id}/>
                            </td>
                            <td>
                                {activity.responsible}
                            </td>
                        </tr>
                    )
                })}
                </tbody>
                {/*<tfoot id="clients-schedule-activities-container-footer">*/}
                {/*<Pagination*/}
                {/*    numberOfPages={1}*/}
                {/*    changePage={()=>{}}*/}
                {/*/>*/}
                {/*</tfoot>*/}
            </div>
        </div>
    )
}

export default ScheduleActivities