import { useState, useEffect } from "react"
import StatusChips from "../../components/StatusChips/StatusChips"
import TableModal from "../../components/TableModal/TableModal"
import "./style.css"
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa"
import { formatedDate } from "../../helpers/Date/formatedDate"
import api from "../../services/api/api"
import { Loader } from "semantic-ui-react"

const ClientDetailsView = (props) => {
    const { info, view, changeView } = props

    const [demandView, setDemandView] = useState({})
    const [showServiceDetails, setShowServiceDetails] = useState(false)
    const [demandClient, setDemandClient] = useState({})

    useEffect(() => {
        const fetchDemandaInfo = (id) => {
            api.get(`/demands/show/${id}`)
                .then((response) => {
                    setDemandClient(response.data)
                })
                .catch((error) => {
                    //console.log({ message: error.message })
                })
            //console.log(id)
        }

        fetchDemandaInfo(demandView._id)
    }, [demandView])

    const handleViewModal = (view, demanda) => {
        setDemandView(demanda)
        changeView(view)
    }

    //console.log(demandClient)
    const somaValor = info.demands
        ?.map((item) => item.price)
        .reduce((prev, curr) => prev + curr, 0)

    if (view === "default") {
        return (
            <div className="info-demand-modal client-details">
                <div className="info-container">
                    <div className="title">
                        <div>
                            <h2>{`${info.firstName} ${info.lastName}`}</h2>
                        </div>
                        <div className="acao">
                            <div className="inform">
                                <span>Tipo de pessoa</span>
                            </div>
                            <p>
                                {info.type === "legal" ? "Jurídica" : "Física"}
                            </p>
                        </div>
                    </div>
                    <div className="client-data">
                        <div className="client-info-details">
                            <div className="inform">
                                <span>Email</span>
                            </div>
                            <p>{info.email}</p>
                        </div>
                        <div className="client-info-details">
                            <div className="inform">
                                <span>CNPJ/CPF</span>
                            </div>
                            <p>
                                {info.type === "legal"
                                    ? info.cnpj
                                    : info.document}
                            </p>
                        </div>
                        <div className="client-info-details">
                            <div className="inform">
                                <span>Razão Social</span>
                            </div>
                            <p>{info.companyName ? info.companyName : "N/A"}</p>
                        </div>
                        <div className="client-info-details">
                            <div className="inform">
                                <span>Nome Fantasia</span>
                            </div>
                            <p>{info.tradeName ? info.tradeName : "N/A"}</p>
                        </div>
                        <div className="client-info-details">
                            <div className="inform">
                                <span>E-mail Financeiro</span>
                            </div>
                            <p>
                                {info.financialEmail
                                    ? info.financialEmail
                                    : "N/A"}
                            </p>
                        </div>
                    </div>
                    <div className="obs">
                        <div className="inform">
                            <span>Histórico de serviços</span>
                        </div>
                        <TableModal
                            demandas={info.demands}
                            changeView={handleViewModal}
                        />
                    </div>
                    <div className="valor">
                        <div className="inform">
                            <span>Valor</span>
                        </div>
                        <strong>{`BNZ ${somaValor},00`}</strong>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="info-demand-modal client-details">
                {demandClient._id === undefined ? (
                    <div className="loader">
                        <Loader active inline size="massive" />
                    </div>
                ) : (
                    <div className="info-container">
                        <div className="title">
                            <div>
                                <h2>{demandClient.fullDescription}</h2>
                            </div>
                            <div className="acao">
                                <div className="inform">
                                    <span>Ação</span>
                                </div>
                                <StatusChips status={demandClient.status} />
                            </div>
                        </div>
                        <div className="data">
                            <div className="inform">
                                <span>data entrega</span>
                            </div>
                            <strong>
                                {formatedDate(demandClient.finalDate)}
                            </strong>
                        </div>

                        <div className="data">
                            <div className="inform">
                                <span>Observação/Comentário</span>
                            </div>
                            <p>{demandClient.demand_interactions[0].comment}</p>
                        </div>

                        <div className="data">
                            <div className="inform">
                                <span>Detalhes do serviço</span>
                            </div>
                            <span
                                className="service-detail"
                                onClick={() =>
                                    setShowServiceDetails(!showServiceDetails)
                                }
                            >
                                Detalhes do serviço{" "}
                                {showServiceDetails ? (
                                    <FaRegEye />
                                ) : (
                                    <FaRegEyeSlash />
                                )}
                            </span>
                        </div>
                        {showServiceDetails ? (
                            <div className="data">
                                <div className="inform">
                                    <span>
                                        Descreva que tipo de trabalho gostaria
                                        de ver realizado.
                                    </span>
                                </div>
                                <p>
                                    Orçar - Reunião para esclarecimento de
                                    riscos e opções para - Maneiras de pagar
                                    Reavaliação de salários de CLT (aumento) em
                                    2020 versus dissídio.
                                </p>
                            </div>
                        ) : (
                            ""
                        )}
                        <div className="valor" onClick={() => changeView()}>
                            <div className="inform">
                                <span>Valor</span>
                            </div>
                            <strong>{`BNZ ${demandClient.price},00`}</strong>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default ClientDetailsView
