import React, {useEffect, useState} from 'react'
import './DemandsStatusGeneral.css'
import DemandCard from "../DemandCard";
import api from "../../../services/api/api";
import {Box, Modal} from "@mui/material";

const DemandsStatusGeneral = () => {

    let lenghtPending = 0
    let lenghtDone = 0
    let lenghtInProgress = 0

    const [ activities, setActivities ] = useState();
    const [activitiesList, setActivitiesList] = useState([]);
    //const [activitieStatusFilter,setActivitieStatusFilter] = useState("pending");
    const [openModal,setOpenModal] = useState(false);


    useEffect(() => {
        api.get('data-privacy/all').then((response)=>{
            setActivities(response.data.data)
            setActivitiesList(response.data.data.filter(item => {
                return (new Date(item.schedule_date).getTime()) > new Date().getTime();
            }))
        }).catch(()=>alert('erro ao carregar atividades'))
    }, []);

    activities?.forEach((activite)=>{
        if(activite.status === 'pending'){
            lenghtPending++;
        }else if(activite.status === 'done'){
            lenghtDone++
        }else if(activite.status === 'in_progress'){
            lenghtInProgress++;
        }

    })

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: 'none !important',
        boxShadow: 24,
        p: 4,
        width:'960px',
        height:'500px',
        overflowY:'scroll'
    };


    return (
        <div id="demands-status-general">
                <DemandCard
                    onClick={
                        ()=>{
                            //setActivitieStatusFilter("done")
                            setOpenModal(true)
                        }
                    }
                    status={'Demandas concluídas gerais'}
                    number={lenghtDone}
                    icon={'good'}
                />
                <br/>
                <DemandCard
                    onClick={
                        ()=>{
                            //setActivitieStatusFilter("in_progress")
                            setOpenModal(true)
                        }
                    }
                    status={'Demandas em progresso gerais'}
                    number={lenghtInProgress}
                    icon={'clock'}
                />
                <br/>
                <DemandCard
                    onClick={
                        ()=>{
                            //setActivitieStatusFilter("pending")
                            setOpenModal(true)
                        }
                    }
                    status={'Pendências gerais'}
                    number={lenghtPending}
                    icon={'atencion'}
                />
            <Modal
                open={openModal}
                className="data-privacy-modal"
                onClose={()=>setOpenModal(!openModal)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableAutoFocus
            >
                <Box sx={style}>
                    <h2>
                        Próximas atividades
                    </h2>
                    <table>
                        <thead>
                        <tr>
                            <th>Cliente</th>
                            <th>Atividade</th>
                            <th>Data</th>
                            <th>Responsavél</th>
                        </tr>
                        </thead>
                        <tbody>
                        {activitiesList?.map((activity,counter) => (
                            <tr key={counter}>
                                <td>{activity?.client?.companyName}</td>
                                <td>{activity.schedule_name}</td>
                                <td>{new Date(activity.schedule_date).toLocaleDateString('pt-BR')}</td>
                                <td>{activity.responsible}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </Box>
            </Modal>
        </div>
    )
}

export default DemandsStatusGeneral