import React, {useEffect, useState} from "react";
import './SendedDocs.css'
import sendDocButton from '../../../assets/upload-img.svg'
import {Box, Modal} from "@mui/material";
import api from "../../../services/api/api";
import {api_url} from "../../../config/environment/environmentSettings";
import {getToken} from "../../../services/authentication/authentication";

const SendedDocs = (props) => {



    const [openModal,setOpenModal] = useState(false);
    const [documents, setDocuments] = useState([])
    const {clientId} = props
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: 'none !important',
        boxShadow: 24,
        p: 4,
        width:'960px',
        height:'500px',
        overflowY:'scroll',
        borderRadius:'15px'
    };

    useEffect(() => {
        api.get(`/data-privacy/sendedDocuments/${clientId}`)
            .then((response) => {
                setDocuments(response.data)
            })
            .catch((error) => {})
    }, [clientId])


    return (
        <>
            <div id="dataprivacy-doc-send-button">
                <button onClick={()=>setOpenModal(true)}>
                    <img src={sendDocButton} alt=""/>
                    Documentos enviados
                </button>
            </div>

            <Modal
                open={openModal}
                onClose={()=>setOpenModal(!openModal)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableAutoFocus
            >
                <Box sx={style}>
                    <h2>Arquivos enviados</h2>
                    <ul id="sended-docs">
                    {
                        documents && documents?.data?.length > 0 ?
                            documents?.data.map((document,counter)=>{
                                return(
                                    <li key={counter}>
                                        <h4>
                                            <a href={`${api_url}/${document.fileName}?token=${getToken()}`} target="_blank" rel="noopener noreferrer">
                                                {document.name}
                                            </a>
                                        </h4>
                                    </li>
                                )
                            })
                            :(
                                <h2>Sem documentos enviados</h2>
                            )
                    }
                    </ul>
                </Box>
            </Modal>
        </>
    )
}

export default SendedDocs