import "../BannerLogin/BannerAdvogados.css";
import Coruja from "../../assets/advcoruja.png"
import CorujaPresentation from "../../assets/advcoruja-apresentacao.png"
import CorujaForgot from "../../assets/advcoruja-forgotpassword.png"
import { useLocation } from "react-router-dom";

const BannerAdvogados = () => {
    
    const location = useLocation();

    let pathName;
    pathName = location.pathname;

    return (
        <div className="banner">
            {pathName === "/login" ? 
                <>
                    <div className="container-banner-img"><img src={CorujaPresentation}  alt={'Coruja Presentation'}/></div>
                </>
            : pathName === "/login/forgotPassword" ? 
              <>
                  <div className="container-banner-img"><img src={CorujaForgot} alt={'Coruja forgot'} /></div>
                  <p>Por motivos de segurança, não armazenamos sua senha.<br/>Por isso, não enviamos sua senha por e-mail.</p>
              </>: 
              <>
                  <div className="container-banner-img"><img src={Coruja}  alt={'Coruja'}/></div>
              </> 
            }
        </div>
    );
};

export default BannerAdvogados;