import React, {useEffect, useState} from "react";
import {Link, NavLink, useHistory, useLocation} from "react-router-dom";
import './Sidebar.css'
import api from "../../services/api/api";
import {logout} from "../../services/authentication/authentication";
import userProfileDefaultImage from '../../assets/lawyer.png';
import userProfileBackgroundDefaultImage from '../../assets/profile-background-default.png';
import bonuzLogo from '../../assets/logo.png';
import {api_url} from "../../config/environment/environmentSettings";
import {VscBell} from "react-icons/vsc";
import SmallLogo from "../../assets/nav/SmallLogo.png"
import { ReactComponent as FourSquare } from "../../assets/nav/FourSquare.svg"
import { ReactComponent as Client } from "../../assets/nav/Client.svg"
import { ReactComponent as MyDemands } from "../../assets/nav/MyDemands.svg"
import { ReactComponent as Services } from "../../assets/nav/Services.svg"
import { ReactComponent as BonuzProductIcon } from '../../assets/bonuz-products.svg';
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io"
import { Dropdown } from 'semantic-ui-react'
import LawyerNotifications from "../LawyerNotifications/LawyerNotifications";
import SidebarDropdown from "../Dropdown";
import SidebarNotification from "../Notifications/notificationSidebar";
import { IoDocumentOutline } from "react-icons/io5";

const Sidebar = (props) => { 

    const [sidebarIsOpen, setSidebarIsOpen] = useState(true);
    const [isExternalLawyer, setIsExternalLawyer] = useState(false);
    const {isOpen, checkNotification} = props;

    useEffect(()=>{
        const sidebarOpened = isOpen;
        sidebarOpened(sidebarIsOpen)
    },[sidebarIsOpen, isOpen])

    const history = useHistory();
    const location = useLocation();

    const pathName = location?.pathname;

    const [userInfo,setUserInfo] = useState({});
    const [openNotificationPpv, setOpenNotificationPpv] = useState(false);
    const handleNotification = () => {
        setOpenNotificationPpv(!openNotificationPpv)
    }

    useEffect(()=>{
        api.get('/lawyer/me').then((response) => {
            setUserInfo({
                id: response.data._id || null,
                username: response.data.displayAs || null,
                email: response.data.personalEmail || null,
                userProfilePhoto: response.data.profileImage !== undefined ? `${api_url}/${response.data.profileImage}` : userProfileDefaultImage,
                userBackgroundProfilePhoto: response.data.profileBackgroundImage !== undefined ? `${api_url}/${response.data.profileBackgroundImage}` : userProfileBackgroundDefaultImage,
                profile: response.data.profile || ""
            });
        }).catch((error)=>{
            //console.log(error)
        });

        api.get('/lawyer/me').then((response) => {
            if (response.data.attorneyType === "external") {
              setIsExternalLawyer(true);}
            })
    },[])

    const NavTitle = () => {
        if (pathName === "/dashboard") {
            return "Início"
        } else if (pathName === "/clients") {
            return "Clientes"
         } else if (pathName === "/documents") {
            return "Documentos"
        } else if (pathName === "/demands-auction") {
            return "Demandas em leilão"
        } else if (pathName === "/demands") {
            return "Minhas Demandas"
        } else if (pathName === "/demands-picker") {
            return "Solicitações B.onuz"
        } else if (pathName === "/account") {
            return "Minha conta"
        } else if (pathName === "/services") {
            return "Consultas"            
        } else if (pathName === "/data-privacy") {
            return "Bonuz Data Privacy"
        } else if (pathName.slice(0, 16) === "/plano-essencial") {
            return "Plano Essencial"
        } else if (pathName.slice(0, 14) === "/plano-premium") {
            return "Plano Premium"
         } else if (pathName.slice(0, 15) === "/plano-platinum") {
            return "Plano Premium"
        } else if (pathName.slice(0, 7) === "/demand") {
            return "Demanda detalhes"            
        }else if (pathName.slice(0, 21) === "/services/bonuz-plans") {
            return "Adição de serviço Planos Bonuz"
        }else if (pathName.slice(0, 10) === "/services/") {
            return "Adição de serviço JF"
        }else if (pathName.slice(0, 21) === "/data-privacy/client/") {
            return "Bonuz Data Privacy > "+props.clientName
        }else if (pathName === "/data-privacy/client"){

        }else if (pathName === "/admin/reports"){
            return "Relatórios administrativos"
        }else if (pathName === "/admin/demands-monitor"){
            return "Monitor de Demandas"
        }else if (pathName === "/admin/plans"){
            return "Administração de Planos"
        } else {
            return "Add 'title' to function NavTitle Sidebar.js"
        }
    }

    // Itens comuns para todos os usuários
    const commonNavItems = [
        {
            name: "Início",
            icon: <FourSquare />,
            linkTo: "/dashboard",
            emBreve: false
        },
        {
            name: "Clientes",
            icon: <Client />,
            linkTo: "/clients",
        },
        {
            name: "Documentos",
            icon: <IoDocumentOutline size={'28'} />,
            linkTo: "/documents",
        }
    ];

    const demandNavItemsInt = [
        {
            name: 'Demandas',
            icon: <MyDemands />,
            isDropdown: true,
            initialStateOpen: true,
            dropItems: [
                {
                    name: "Demandas em leilão",
                    icon: "",
                    linkTo: "/demands-auction",
                },
                {
                    name: "Solicitações B.onuz",
                    icon: "",
                    linkTo: "/demands-picker",
                },
                {
                    name: "Minhas Demandas",
                    icon: "",
                    linkTo: "/demands",
                },
                {
                    name: "Meus Agendamentos",
                    icon: "",
                    linkTo: "",
                    emBreve: true
                }
            ]
        }
    ];

    const demandNavItemsExt = [
        {
            name: 'Demandas',
            icon: <MyDemands />,
            isDropdown: true,
            initialStateOpen: true,
            dropItems: [
                {
                    name: "Demandas em leilão",
                    icon: "",
                    linkTo: "/demands-auction",
                },
                {
                    name: "Minhas Demandas",
                    icon: "",
                    linkTo: "/demands",
                }
            ]
        }
    ];


    // Itens adicionais para usuários que não são advogados externos
    const productNavItems = [
        {
            name: 'Produtos B.onuz',
            icon: <BonuzProductIcon />,
            isDropdown: true,
            initialStateOpen: false,
            dropItems: [
                {
                    name: "Data Privacy",
                    linkTo: "/data-privacy",
                },
                {
                    name: "Juridico Familiar",
                    linkTo: "/juridico-familiar",
                },
                {
                    name: "Plano Essencial",
                    linkTo: "/plano-essencial",
                },
                {
                    name: "Plano Premium",
                    linkTo: "/plano-premium",
                    emBreve: true
                },
                {
                    name: "Plano Platinum",
                    linkTo: "/plano-platinum",
                    emBreve: true
                }
            ]
        },
        {
            name: "Consultas",
            icon: <Services />,
            linkTo: "/services",
        }
    ];

    // Item de menu para administradores
    const adminNavItems = [
        {
            name: 'Administrador',
            icon: <Client />,  // Substitua pelo ícone do administrador
            isDropdown: true,
            initialStateOpen: false,
            dropItems: [
                {
                    name: "Relatórios",
                    icon: "",
                    linkTo: "/admin/reports",
                },
                {
                    name: "Monitor de Demandas",
                    icon: "",
                    linkTo: "/admin/demands-monitor",
                }
            ]
        }
    ];
    
    let NavItens;

    // Combine os arrays conforme necessário
    if(isExternalLawyer) {
        NavItens = [...commonNavItems, ...demandNavItemsExt];
    }

    if (!isExternalLawyer) {
        NavItens = [...commonNavItems, ...demandNavItemsInt, ...productNavItems];
    }

    if (userInfo.profile === "administrator") {
        NavItens = [...commonNavItems, ...demandNavItemsInt, ...productNavItems, ...adminNavItems];
    }


    return (
        <>
            <LawyerNotifications notifications={props.notifications.filter(item => !item.checked)}/>
            <div id="sidenav">
                <div className="row nav-content">
                    <div>
                        {!sidebarIsOpen ? 
                        <div className="nav-left">
                            <div className="nav-logo">
                                <button ><img src={SmallLogo} alt="bonuz logo, an owl" /></button>
                            </div>
                            <div className="line"/>
                            <div className="link">                       
                                {NavItens?.map((item) => {
                                    if (item.emBreve === true) {
                                        return (
                                        <div className="nav-icon" style={{cursor: "pointer"}} onClick={() => alert("Em breve!")}>
                                            <div className="icon-container">{item.icon}</div>
                                        </div>
                                        )
                                    } else if (item.isDropdown) {
                                        return (
                                            <div className="nav-icon" style={{cursor: "pointer"}} onClick={() => setSidebarIsOpen(!sidebarIsOpen)}>
                                                <div className="icon-container">{item.icon}</div>
                                            </div>
                                        )
                                    } else {
                                        return(
                                            <NavLink to={item.linkTo} activeClassName="link-active" exact={true}>
                                                <div className="nav-icon">
                                                    <div className="icon-container">{item.icon}</div>
                                                </div>
                                            </NavLink>
                                        )
                                    }
                                })}
                            </div>

                            <div className="expandir" onClick={() => setSidebarIsOpen(!sidebarIsOpen)}>
                                <IoIosArrowDropright />
                                <p>Expandir</p>
                            </div>
                        </div>
                        :
                        <div className="nav-left open">
                            <div className="nav-logo">
                                <button ><img src={bonuzLogo} alt="bonuz logo, an owl" className="logo"/></button>
                            </div>
                            <div className="line"/>
                            <div className="link">
                            {NavItens?.map((item) => {
                                    if (item.emBreve === true) {
                                        return (
                                            <div className="nav-icon" style={{cursor: "pointer"}} onClick={() => alert("Em breve!")}>
                                            <div className="icon-container">
                                                <div>{item.icon}</div>
                                                <div><p>{item.name}</p></div>
                                            </div>
                                        </div>
                                        )
                                    } else if (item.isDropdown) {
                                            return (
                                                <>
                                                    <div className="nav-icon">
                                                    <SidebarDropdown
                                                        icon={item.icon}
                                                        name={item.name}
                                                        initialStateOpen={item.initialStateOpen}
                                                    >
                                                    <ul>
                                                        {item.dropItems.map(dropItem => (
                                                            <li>
                                                                <NavLink to={dropItem.linkTo} activeClassName="link-active" exact={true}>
                                                                    <div className="nav-icon">
                                                                        <div className="icon-container" style={{padding: '5px 0'}}> 
                                                                            <div><p>{dropItem.name}</p></div>
                                                                            <div>{dropItem.icon}</div>
                                                                        </div>
                                                                    </div>
                                                                </NavLink>
                                                            </li>
                                                        ))  }
                                                    </ul>
                                                    </SidebarDropdown>
                                                        </div>
                                                </>
                                            )
                                    } else {
                                            return(
                                                <NavLink to={item.linkTo} activeClassName="link-active" exact={true}>
                                                <div className="nav-icon">
                                                    <div className="icon-container">
                                                        <div>{item.icon}</div>
                                                        <div><p>{item.name}</p></div>
                                                    </div>
                                                </div>
                                                </NavLink>
                                            )
                                        }
                                    }
                                )}
                            </div>

                            <div className="expandir recuar" onClick={() => setSidebarIsOpen(!sidebarIsOpen)}>
                                <div><IoIosArrowDropleft /></div>
                                <div><p>Recuar</p></div>
                            </div>
                        </div>
                        
                        }
                        
                    </div>
                    <nav className={!sidebarIsOpen ? "nav-wrapper nav-top closed container-width" : "nav-wrapper nav-top open container-width-open" }>
                            <div>
                                <h2>{NavTitle()}</h2>
                            </div>
                            <div className="icons profile-info">
                                    <div><img className="circle" src={userInfo.userProfilePhoto} alt="user profile"/></div>
                                    <div><p>{userInfo.username}</p></div>
                                    <div className={props.notifications.filter(item => !item.checked).length > 0 ? "rotating":""}><VscBell className="ring-notifications" onClick={() => handleNotification()}/></div>
                                    {openNotificationPpv ?  
                                        <div className="popover notificacoes">
                                            <div className="header"><VscBell/><p>Notificações</p></div>
                                            <div className="popover-notificacoes-body">
                                                <SidebarNotification notifications={props.notifications.filter(item => !item.checked)} checkNotification={checkNotification} />
                                            </div>
                                        </div>
                                        : ""
                                    }
                                    <div>
                                        <Dropdown
                                            icon='ellipsis vertical'
                                        >
                                            
                                            <Dropdown.Menu>
                                            <Link to="/account">
                                                <Dropdown.Item icon='user' text='Minha conta' />
                                            </Link>
                                            <Link onClick={(e) => {
                                                e.preventDefault();
                                                logout();
                                                history.push("/login");
                                            }}>
                                                <Dropdown.Item icon='power off' text='Sair' />
                                            </Link>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                            </div>
                    </nav>
                </div>
            </div>
        </>
    )
}

export default Sidebar