import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import userProfileDefaultImage from '../../assets/lawyer.png';
import userProfileBackgroundDefaultImage from '../../assets/profile-background-default.png';
import Sidebar from "../../components/Sidebar/Sidebar";
import {api_url} from "../../config/environment/environmentSettings";
import api from "../../services/api/api";
import './Dashboard.css'
import Notification from "../../components/Notifications/notification";
import { useNotifications } from "../../helpers/getNotifications";

const Dashboard = () => {
    //Setting the breadcumb

    const [sidebarIsOpen, setSidebarIsOpen] = useState(true);
    const [userInfo,setUserInfo] = useState({});
    const handleSidebar = (sidebar)=>setSidebarIsOpen(sidebar);
    const [isLoading, setIsLoading] = useState(true);

    const [demands, setDemands] = useState([]) 
    const [currentStatus] = useState("")
    const [search] = useState("")

    const [userRating, setUserRating] = useState(8.5); // Inicialize com a nota do usuário
    const [chatNotifications, setChatNotifications] = useState([]);
    const {notifications, checkNotification} = useNotifications();
    
    useEffect(()=>{

        api.get('/lawyer/me').then((response) => {
            setUserInfo({
                id: response.data._id || null,
                username: response.data.displayAs || null,
                email: response.data.personalEmail || null,
                expertises: response.data.expertises || null,
                userProfilePhoto: response.data.profileImage !== undefined ? `${api_url}/${response.data.profileImage}` : userProfileDefaultImage,
                userBackgroundProfilePhoto: response.data.profileBackgroundImage !== undefined ? `${api_url}/${response.data.profileBackgroundImage}` : userProfileBackgroundDefaultImage
            });
        }).catch((error)=>{
            //console.log(error)
        });

        api.get('/lawyer/me-rating').then((response) => {
            const rating = typeof response.data === 'number' ? response.data : 6;
            setUserRating(rating);
        }).catch((error) => {
            //console.log(error);
        });

    },[])

    useEffect(() => {
        // Iniciar a requisição da API
        setIsLoading(true);

        // Função para buscar todas as páginas de demandas
        const fetchAllDemands = async () => {
          let allDemands = [];
          try {
            const response = await api.get(`/demands/by-status`);
            allDemands = response.data;
          } catch (error) {
          }
      
          // Defina as demandas completas
          setDemands(allDemands);
          setIsLoading(false);
        };
      
        // Chame a função para buscar todas as páginas de demandas
        fetchAllDemands();
    }, [currentStatus, search]);

    // Função para converter a nota em representação de estrelas
    const renderRatingStars = (rating) => {
        const stars = [];
        const maxRating = 5; // Máximo de 5 estrelas
    
        for (let i = 1; i <= maxRating; i++) {
            if (i <= rating) {
                // Estrela dourada se a classificação for maior ou igual a i
                stars.push(<span key={i} className="star gold">&#9733;</span>);
            } else {
                // Estrela cinza se a classificação for menor que i
                stars.push(<span key={i} className="star gray">&#9733;</span>);
            }
        }
    
        return stars;
    };
    
    useEffect(() => {
        setChatNotifications(notifications?.filter(item => !item.checked && item.message.toLowerCase()))
    }, [notifications])

    if (isLoading) {
        // Mostrar um indicador de carregamento, como uma animação de spinner
        return <div>Loading...</div>;
      } else {                
        // Contar o número de demandas em cada categoria
        const countAtrasadas = demands?.find(item => item.status === 'late')?.total;
        const countPendentes = demands?.find(item => item.status === 'pending')?.total;
        const countEmProgresso = demands?.find(item => item.status === 'in_progress')?.total;
        const countConcluidas = demands?.find(item => item.status === 'done')?.total;

        // Dados das categorias atualizados com as contagens reais
        const demandStatusData = [
            { status: `Concluídas (${countConcluidas})`, color: "green", count: countConcluidas },
            { status: `Atrasadas (${countAtrasadas})`, color: "red", count: countAtrasadas },
            { status: `Pendentes (${countPendentes})`, color: "yellow", count: countPendentes },
            { status: `Em Progresso (${countEmProgresso})`, color: "purple", count: countEmProgresso },
        ];

        return (
            <div id="dashboard">
                <div className="row">
                    <div className="col s12">
                        <Sidebar isOpen={handleSidebar} notifications={notifications} checkNotification={checkNotification} />
                    </div>
                </div>
                <div className={!sidebarIsOpen ? "container-width" : "container-width-sbopen"}>
                    <div className="card dashboard-card">
                        <div className="card userCard">
                            <div className="userImage">
                                <img
                                    className="circle dashboard-profile-image"
                                    src={userInfo.userProfilePhoto}
                                    alt="user profile"
                                />
                            </div>
                            <div className="userInfo">
                                <h2>{userInfo.username}</h2>
                                <p><strong>Especialidade:</strong> {userInfo.email}</p>
                                <Link to="/account" className="waves-effect waves-light btn">
                                    Editar Perfil
                                </Link>
                            </div>
                            <div className="userRating">
                                <div className="rating-stars">
                                    {renderRatingStars(userRating)}
                                </div>
                                <p className="userRatingText">{userRating.toFixed(1)}</p>
                            </div>
                        </div>
                        <div className="card demand-progress-card">
                            <h2>Andamento das Demandas</h2>
                            <div className="progress-bar">
                                {demandStatusData.map((statusData, index) => (
                                    <div
                                    key={index}
                                    className="status-bar"
                                    style={{ backgroundColor: statusData.color, width: `${statusData.count * 10}%` }}
                                    ></div>
                                ))}
                            </div>
                            <div className="legend">
                                {demandStatusData.map((statusData, index) => (
                                    <div key={index} className="status-item">
                                    <span className="status-dot" style={{ backgroundColor: statusData.color }}></span>
                                    <span className="status-name">{statusData.status}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="status-container">
                            <div className="status">
                                <div>
                                    <span className='status-icon status-icon-warning'>warning</span>
                                </div>
                                <div>
                                    <h3>Atrasadas</h3>
                                    <span className='status-count'>{countAtrasadas}</span>
                                    <Link to="/demands?status=atrasadas" className="waves-effect waves-light waves-link">
                                        Ver demandas
                                    </Link>
                                </div> 
                            </div>
                            <div className="status">
                                <div>
                                    <span className='status-icon status-icon-pending'>pending_actions</span>
                                </div>
                                <div>
                                    <h3>Pendentes</h3>                                
                                    <span className='status-count'>{countPendentes}</span>
                                    <Link to="/demands?status=pendentes" className="waves-effect waves-light waves-link">
                                        Ver demandas
                                    </Link> 
                                </div>                                
                            </div>
                            <div className="status">
                                <div>
                                    <span className='status-icon status-icon-progress'>rotate_right</span>
                                </div>
                                <div>
                                    <h3>Em Progresso</h3>
                                    <span className='status-count'>{countEmProgresso}</span>
                                    <Link to="/demands?status=em_progresso" className="waves-effect waves-light waves-link">
                                        Ver demandas
                                    </Link> 
                                </div>
                            </div>
                            <div className="status">
                                <div>
                                    <span className='status-icon status-icon-done'>check</span>
                                </div>
                                <div>
                                    <h3>Concluídas</h3>
                                    <span className='status-count'>{countConcluidas}</span>
                                    <Link to="/demands?status=concluidas" className="waves-effect waves-light waves-link">
                                        Ver demandas
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="card chatNotificationCard" id="chat-notifications">
                            <h3>Notificações de Chat</h3>
                            <div className="ui grid">
                                <div className="ui row">
                                    {chatNotifications?.length > 0 && (
                                        <Notification notifications={chatNotifications} checkNotification={checkNotification} />
                                    )}
                                </div>
                            </div>
                            {((chatNotifications?.length || 0) === 0) && (
                                <p>Nenhuma notificação de chat.</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Dashboard 