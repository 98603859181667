import React, {useState} from "react";
import { Table } from 'semantic-ui-react';
import InfoDemandModal from '../Modals/TableDemands/InfoDemand/InfoDemand';
import StatusChips from '../StatusChips/StatusChips';
import "./TableModal.css";
import { ReactComponent as MessageIcon } from "../../assets/messageIcon.svg";
import { ReactComponent as FileAnexoIcon } from "../../assets/fileAnexoIcon.svg";
import { formatedDate } from "../../helpers/Date/formatedDate";
import { api_url } from "../../config/environment/environmentSettings";
import {getToken} from "../../services/authentication/authentication";

const TableModal = (props) => {

    const {
        demandas,
        changeView,
        isHistoryDemand,
        isClientTable,
        setComantarioDemand,
        client,
        isMyDemand,
        isAuctionDemand,
        demandSubService,
        handleChat
    } = props;

    //console.log(new Date(demandas?.finalDate).toLocaleDateString('pt-BR'))

    const [infoModal, setInfoModal] = useState({});
    const [openTableModal, setOpenTableModal] = useState(false);

    const handleInfoDemandModal = (demanda) => {
        setInfoModal(demanda);
        setOpenTableModal(!openTableModal);
    }
    if (isHistoryDemand ){
        return (
            <Table id='table-modal'>
                <InfoDemandModal
                    info={infoModal}
                    open={openTableModal}
                    handleClose={handleInfoDemandModal}
                />
                <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Número</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>Data de interação</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>Status</Table.HeaderCell>
                    {isAuctionDemand ? <Table.HeaderCell textAlign='center'>Subserviço</Table.HeaderCell> : ""}
                    <Table.HeaderCell textAlign='center'>Baixar arquivo</Table.HeaderCell>
                </Table.Row>
                </Table.Header>
                <Table.Body>
                    {demandas.demand_interactions?.map((demanda, count) => {
                            return (
                                    <>
                                        <Table.Row className='tr-button'>
                                            <Table.Cell width={1} onClick={() => setComantarioDemand(demanda.comment)}>{`#000${++count}`}</Table.Cell>
                                            <Table.Cell width={1} textAlign='center' onClick={() => setComantarioDemand(demanda.comment)}>{formatedDate(demanda.createdAt, true)}</Table.Cell>
                                            <Table.Cell width={1} textAlign='center' onClick={() => setComantarioDemand(demanda.comment)}>
                                                    <StatusChips status={demanda.status} />
                                            </Table.Cell>
                                            {isAuctionDemand ? <Table.Cell width={1} onClick={() => setComantarioDemand(demanda.comment)}>{demandSubService}</Table.Cell> : ""}
                                            <Table.Cell width={1} textAlign='center' style={{display: "flex", alignItems: "center"}}>
                                                {!demanda.documents?
                                                 <p>Sem anexo atribuído</p>
                                                : demanda.documents.length > 0 && demanda.documents.map((d, counter)=> {
                                                    return (
                                                    <a className="anexo-file" target="_blank" href={`${api_url}/demands/${demandas._id}/file/${d.$oid}?token=${getToken()}` } rel="noreferrer" download>Anexo {counter+1}&nbsp;<FileAnexoIcon/>&nbsp;</a>
                                                        )
                                                    })
                                                }
                                                <MessageIcon onClick={isAuctionDemand ? () => handleChat(demandas.client_info) : () => handleChat({...demanda, count: count }) } className="mensagem-icon"/>
                                            </Table.Cell>
                                        </Table.Row>
                                        <div style={{height: "3px"}}/>
                                    </>
                                    )       
                                    }
                                )
                            }
                </Table.Body>
            </Table>
    );}
    else if (isClientTable) {
        return (
            <Table id='table-modal' className="demand-auction">
            <InfoDemandModal
                info={infoModal}
                open={openTableModal}
                handleClose={handleInfoDemandModal}
            />
            <Table.Header>
            <Table.Row>
            <Table.HeaderCell>Cliente</Table.HeaderCell>
                <Table.HeaderCell>Data de entrega</Table.HeaderCell>
                <Table.HeaderCell>E-mail</Table.HeaderCell>
                <Table.HeaderCell>CNPJ/CPF</Table.HeaderCell>
                <Table.HeaderCell>Razão Social</Table.HeaderCell>
                <Table.HeaderCell>Nome Fantasia</Table.HeaderCell>
                <Table.HeaderCell>E-mail financeiro</Table.HeaderCell>
            </Table.Row>
            </Table.Header>
            <Table.Body>
                
                                    <Table.Row >
                                        <Table.Cell width={1}>{client?.firstName}</Table.Cell>
                                        <Table.Cell width={1}>{demandas.finalDate ? formatedDate(demandas?.finalDate) :  '-'}</Table.Cell>
                                        <Table.Cell width={1}>{!isMyDemand ? "-" : client?.email}</Table.Cell>
                                        <Table.Cell width={1}>{client?.document === null ? client?.cnpj : client?.document}</Table.Cell>
                                        <Table.Cell width={1}>{!isMyDemand ? "-" : client?.companyName}</Table.Cell>
                                        <Table.Cell width={1}>{!isMyDemand ? "-" : "-"}</Table.Cell>
                                        <Table.Cell width={1}>{isMyDemand ? "-" : client?.financialEmail}</Table.Cell>
                                    </Table.Row>
            </Table.Body>
        </Table>
        );
    }
    else {
        return (
            <Table id='table-modal'>
                <InfoDemandModal
                    info={infoModal}
                    open={openTableModal}
                    handleClose={handleInfoDemandModal}
                />
                <Table.Header>
                <Table.Row>
                <Table.HeaderCell>Número</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>Status</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>Vencimento</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>Prazo</Table.HeaderCell>
                    <Table.HeaderCell>Demanda</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>Valor</Table.HeaderCell>
                </Table.Row>
                </Table.Header>
                <Table.Body>
                    {demandas?.map((demanda) => {
                            return (
                                    <>
                                        <Table.Row className='tr-button' onClick={() => changeView("",demanda)}>
                                            <Table.Cell width={1} style={{borderLeft: "1px solid #D3D2D7", borderRadius: "8px 0 0 8px"}}>{`#000${demanda.code}`}</Table.Cell>
                                            <Table.Cell width={2} textAlign='center'>
                                                    <StatusChips status={demanda.status} />
                                            </Table.Cell>
                                            <Table.Cell width={2} textAlign='center'>{formatedDate(demanda.finalDate)}</Table.Cell>
                                            <Table.Cell width={2} textAlign='center'>{`${demanda.deadline} dias úteis`}</Table.Cell>
                                            <Table.Cell width={7}>{demanda.fullDescription}</Table.Cell>
                                            <Table.Cell width={2} textAlign='center' style={{borderRight: "1px solid #D3D2D7", borderRadius: "0 8px 8px 0"}}>{`R$ ${demanda.price},00`}</Table.Cell>
                                        </Table.Row>
                                        <div style={{height: "3px"}}/>
                                    </>
                                    )       
                                    }
                                )
                            }
                </Table.Body>
            </Table>
        )
    }
};

export default TableModal;