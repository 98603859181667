import axios from 'axios';
import {getToken} from "../authentication/authentication";
import {api_url} from "../../config/environment/environmentSettings";

const api = axios.create({
    baseURL:api_url
});


api.interceptors.request.use(function (config) {
    const token = getToken()

    if(token){
        config.headers.Authorization = 'Bearer '+token;
    }

    return config;
});

api.interceptors.response.use(function (response) {

    if(response.status === 401) {
        //console.log(response)
    }

    return response
});

api.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    const errorText = error.toString();
    let find401 = errorText.search("401");
    if(find401 !== -1 && window.location.pathname !== "/login"){
        alert("Sessão expirada");
        window.location.href="/login";
    }

    return Promise.reject(error);
});


export default api;