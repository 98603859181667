import React, { useEffect, useMemo, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Searchbar from "../../components/Searchbar/Searchbar";
import Table from "../../components/Table/Table";
import Pagination from "../../components/Pagination/Pagination";
import api from "../../services/api/api";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import StatusChips from "../../components/StatusChips/StatusChips";
import DemandInfoView from "../../components/DemandInfoView/DemandInfoView";
import { useLocation } from "react-router-dom";
import "./Demands.css";
import { useNotifications } from "../../helpers/getNotifications";

const Demands = () => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  const handleSidebar = (sidebar) => setSidebarIsOpen(sidebar);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const statusFromURL = queryParams.get("status");
  const getStatus = useMemo(() => ({
    "atrasadas": 'late',
    "pendentes": 'pending',
    "em_progresso": 'in_progress',
    "concluidas": 'done'
  }), []);

  const [demands, setDemands] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [currentStatus, setCurrentStatus] = useState(getStatus[statusFromURL] || '');
  const [search, setSearch] = useState("");
  const [pageView, setPageView] = useState("default");
  const [info, setInfo] = useState({});
  const {notifications, checkNotification} = useNotifications();
  const [isLoading, setIsLoading] = useState(false)

  const handleChangeSelect = (event) => {
    setCurrentStatus(event.target.value);
  };

  const handleView = (info) => {
    setInfo(info);
    setPageView("info");
  };

  useEffect(() => {
    setIsLoading(true)
    setDemands([])
    api
      .get(
        `/demands/index?page=${currentPage}&status=${currentStatus}&search=${search}`
      )
      .then((response) => {
        const fetchedDemands = response.data.data;
        setDemands(fetchedDemands);
        setMaxPage(response.data.last_page);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setIsLoading(false));
  }, [currentPage, currentStatus, search]);

  useEffect(() => {
    if (!currentStatus && statusFromURL) {
      setCurrentStatus(getStatus[statusFromURL]);
    }
  }, [statusFromURL, currentStatus, getStatus]);


  const changePage = (page = 1) => {
    setCurrentPage(page);
  };

  const reloadDemands = () => {
    setIsLoading(true)
    setDemands([])
    api.get(
        `/demands/index?page=${currentPage}&status=${currentStatus}&search=${search}`,
    )
        .then((response) => {
          const fetchedDemands = response.data.data;
          setDemands(fetchedDemands);
          setMaxPage(response.data.last_page);
        })
        .catch((error) => {})
        .finally(() => setIsLoading(false));
  }

  const searchDemands = (search) => {
    let intervalToSearch = setInterval(() => {
      setSearch(search);
      clearInterval(intervalToSearch);
    }, 1500);
  };

  const displayDemands = demands;

  if (pageView === "default") {
    return (
      <div id="demands">
        <div className="row">
          <div className="col s12">
            <Sidebar isOpen={handleSidebar} notifications={notifications} checkNotification={checkNotification}/>
          </div>
          <div
            className={
              !sidebarIsOpen
                ? "container-width"
                : "container-width-sbopen"
            }
          >
            <div className="col s12 search-select-demands">
              <div className="row">
                <div className="col s6">
                  <Select
                    onChange={handleChangeSelect}
                    className="select-table searchbar"
                    displayEmpty
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    value={currentStatus}
                  >
                    <MenuItem value="" selected>
                      <em>Selecionar status</em>
                    </MenuItem>
                    <MenuItem value={"pending"}>
                      <StatusChips status={"pending"} />
                    </MenuItem>
                    <MenuItem value={"in_progress"}>
                      <StatusChips
                        status={"in_progress"}
                      />
                    </MenuItem>
                    <MenuItem value={"stand_by"}>
                      <StatusChips status={"stand_by"} />
                    </MenuItem>
                    <MenuItem value={"waiting_approval"}>
                      <StatusChips
                        status={"waiting_approval"}
                      />
                    </MenuItem>
                    <MenuItem value={"in_estimating"}>
                      <StatusChips
                        status={"in_estimating"}
                      />
                    </MenuItem>
                    <MenuItem value={"draft"}>
                      <StatusChips status={"draft"} />
                    </MenuItem>
                    <MenuItem value={"demand_sent"}>
                      <StatusChips
                        status={"demand_sent"}
                      />
                    </MenuItem>
                    <MenuItem value={"in_review"}>
                      <StatusChips status={"in_review"} />
                    </MenuItem>
                    <MenuItem value={"canceled"}>
                      <StatusChips status={"canceled"} />
                    </MenuItem>
                    <MenuItem value={"done"}>
                      <StatusChips status={"done"} />
                    </MenuItem>
                    <MenuItem
                      value={"waiting_estimate_approval"}
                    >
                      <StatusChips
                        status={
                          "waiting_estimate_approval"
                        }
                      />
                    </MenuItem>
                    <MenuItem
                      value={"waiting_lawyer_acceptance"}
                    >
                      <StatusChips
                        status={
                          "waiting_lawyer_acceptance"
                        }
                      />
                    </MenuItem>
                  </Select>
                </div>
                <div className="col s6">
                  <Searchbar search={searchDemands} />
                </div>
              </div>
            </div>
            <div className="col s12">
              <Table
                tableTitle={"Demandas"}
                dataMyDemands={displayDemands}
                reloadDemands={reloadDemands}
                setInfo={handleView}
                loading={isLoading}
                pagination={
                  <Pagination
                    numberOfPages={maxPage}
                    changePage={changePage}
                    actualPage={currentPage}
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else if (pageView === "info") {
    return (
      <div id="demands-picker">
        <div className="row">
          <div className="col s12">
            <Sidebar isOpen={handleSidebar} notifications={notifications} checkNotification={checkNotification}/>
          </div>
          <div
            className={
              !sidebarIsOpen
                ? "container-width"
                : "container-width-sbopen"
            }
          >
            <DemandInfoView
              isMyDemand={true}
              reloadDemands={reloadDemands}
              info={info}
            />
          </div>
        </div>
      </div>
    );
  }
};

export default Demands;

