import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import "../ForgotPassword/ForgotPasswordModal.css";

const UserTerms = (props) => {

    const {
        handleClose,
        openModal
    } = props;


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: 'none !important',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus
        >
            <Box sx={style} className="box-forgot">
                <h2>Solicitação de redefinição de senha</h2>
                <div className='div-p'>
                    <p>TERMOS DE USO</p>

                    <p> Plataforma de software desenvolvida pela BONUZ DO BRASIL ASSESSORIA EMPRESARIAL LTDA., pessoa jur&iacute;dica de direito privado inscrita no CNPJ/ME sob n&ordm; 36.454.710/0001-39, com sede na Cidade de S&atilde;o Paulo, Estado de S&atilde;o Paulo, na Rua Estados Unidos, n&ordm; 1140, Sala A, Jardim Am&eacute;rica, CEP 01427-001 (&ldquo;BONUZ&rdquo;).</p>

                    <p> Agradecemos por usar nossos servi&ccedil;os (&ldquo;Servi&ccedil;os&rdquo;). Ao usar nossos Servi&ccedil;os, voc&ecirc; (&ldquo;Usu&aacute;rio&rdquo;) est&aacute; concordando com estes termos. Leia-os com aten&ccedil;&atilde;o.</p>

                    <p> Pedimos que leia atentamente, tire todas as suas d&uacute;vidas, pois celebrar&aacute; um contrato vinculativo com a BONUZ. Se Voc&ecirc; n&atilde;o concordar ou n&atilde;o puder cumprir com as disposi&ccedil;&otilde;es do presente documento, n&atilde;o dever&aacute; utilizar a Plataforma. Caso concorde com os termos, agradecemos por escolher a BONUZ. Alertamos que para algumas situa&ccedil;&otilde;es precisaremos de seu consentimento.</p>

                    <p> Para usar os Servi&ccedil;os da BONUZ, Voc&ecirc; precisa (1) ter o poder de celebrar um contrato vinculativo conosco e n&atilde;o ser impedido de faz&ecirc;-lo por qualquer lei; e (2) ser residente de um pa&iacute;s onde o Servi&ccedil;o est&aacute; dispon&iacute;vel.</p>

                    <p> COMO USAR NOSSOS SERVI&Ccedil;OS</p>

                    <p> A BONUZ &eacute; uma plataforma tecnol&oacute;gica de intermediacao de servi&ccedil;os jur&iacute;dicos consultivos, que oferece qualidade a um custo acess&iacute;vel, previamente acordado entre a BONUZ e o profissional.</p>

                    <p> Os servi&ccedil;os discriminados na plataforma ser&atilde;o cobrados por meio de uma moeda denominada &ldquo;BNZ&rdquo;, e cada servi&ccedil;o j&aacute; possui um valor determinado de forma que o usu&aacute;rio poder&aacute;, desde o in&iacute;cio, ter ci&ecirc;ncia de quanto ser&aacute; cobrado por cada servi&ccedil;o.</p>

                    <p> No caso do usu&aacute;rio necessitar de aux&iacute;lio em a&ccedil;&otilde;es judiciais, os valores ser&atilde;o informados &agrave; ele ap&oacute;s an&aacute;lise do caso pelo advogado associado e cobrado a parte diretamente por este, sem d&eacute;bitos de BNZ.</p>

                    <p> A BONUZ intermedia todos os servi&ccedil;os jur&iacute;dicos que voc&ecirc; necessitar, conforme destacado na plataforma. Nossos prazos de devolu&ccedil;&atilde;o do trabalho s&atilde;o de at&eacute; 10 (dez) dias corridos, dependendo tamb&eacute;m da entrega, pelo usu&aacute;rio, de todas as informa&ccedil;&otilde;es necess&aacute;rias para a execu&ccedil;&atilde;o do trabalho. Alguns servi&ccedil;os fora da prateleira (ou seja, que n&atilde;o possuem valor pr&eacute;fixado na plataforma) ser&atilde;o alinhados diretamente entre o usu&aacute;rio e o advogado.</p>

                    <p> Caso o usu&aacute;rio necessite de servi&ccedil;os de urg&ecirc;ncia ou que envolvam l&iacute;ngua estrangeira, este dever&aacute; verificar diretamente na plataforma os custos adicionais que ser&atilde;o debitados, bem como o prazo para tais servi&ccedil;os. Caso o usu&aacute;rio solicite a urg&ecirc;ncia em alguma demanda que n&atilde;o pela plataforma (por e-mail ou por whatsapp), sendo feito o envio do d&eacute;bito e aprovado por qualquer destes meios, e o trabalho sendo entregue, o usu&aacute;rio concorda em pagar os valores informados.</p>

                    <p> O prazo para conclus&atilde;o dos servi&ccedil;os &eacute; estabelecido de acordo com a complexidade destes, bem como pela agenda do respons&aacute;vel por sua execu&ccedil;&atilde;o e ser&aacute; informado uma previs&atilde;o no momento do pedido. Referido prazo &eacute; uma estimativa, e apenas inicia-se a contagem no dia de aceita&ccedil;&atilde;o pelo respons&aacute;vel, a qual voc&ecirc; ser&aacute; informado por e-mail e que deve ocorrer em at&eacute; 24 (vinte e quatro) horas do pedido na plataforma. Caso haja informa&ccedil;&otilde;es pendentes, o prazo ser&aacute; reajustado de acordo com a data de recebimento e, tamb&eacute;m, disponibilidade do respons&aacute;vel.</p>

                    <p> O uso de nossos Servi&ccedil;os n&atilde;o lhe confere a propriedade sobre direitos de propriedade intelectual sobre os nossos Servi&ccedil;os ou sobre o conte&uacute;do que voc&ecirc; acessar. Voc&ecirc; n&atilde;o pode usar conte&uacute;dos de nossos Servi&ccedil;os a menos que obtenha permiss&atilde;o do propriet&aacute;rio de tais conte&uacute;dos ou que o fa&ccedil;a por algum meio permitido por lei. Estes termos n&atilde;o conferem a voc&ecirc; o direito de usar quaisquer marcas ou logotipos utilizados em nossos Servi&ccedil;os. N&atilde;o remova, oculte ou altere quaisquer avisos legais exibidos em ou junto a nossos Servi&ccedil;os.</p>

                    <p> Em rela&ccedil;&atilde;o ao seu uso dos Servi&ccedil;os, podemos enviar-lhe an&uacute;ncios de servi&ccedil;os, mensagens administrativas e outras informa&ccedil;&otilde;es. Voc&ecirc; pode desativar algumas dessas comunica&ccedil;&otilde;es.</p>

                    <p> SUA CONTA</p>

                    <p> Voc&ecirc; precisar&aacute; criar uma Conta para utilizar nossos Servi&ccedil;os, conforme discriminado abaixo.</p>

                    <p> Ap&oacute;s o envio do login e senha feita pelos representantes da BONUZ, o usu&aacute;rio deve trocar a senha automaticamente no primeiro acesso. Para proteger sua Conta, o Usu&aacute;rio deve manter a senha em sigilo. A atividade realizada na Conta ou por seu interm&eacute;dio &eacute; de responsabilidade do Usu&aacute;rio. N&atilde;o recomendamos que a senha da Conta seja reutilizada em aplicativos de terceiros. Caso tome conhecimento de uso n&atilde;o autorizado da sua senha ou Conta, o Usu&aacute;rio deve alterar sua senha.</p>

                    <p> O Usu&aacute;rio concorda em usar a Plataforma apenas conforme previsto nestes Termos de Uso. Concorda tamb&eacute;m em n&atilde;o usar a Plataforma de qualquer forma que esteja em conflito com a legisla&ccedil;&atilde;o. A t&iacute;tulo de exemplo, e n&atilde;o limita&ccedil;&atilde;o, o Usu&aacute;rio concorda em N&Atilde;O:</p>

                    <p> (i) publicar conte&uacute;do ou itens nas categorias inadequadas ou &aacute;reas em nossos site e Servi&ccedil;os; (ii) infringir as leis, os direitos de terceiros ou as nossas pol&iacute;ticas internas; (iii) postar conte&uacute;do falso, impreciso, enganoso, difamat&oacute;rio ou ofensivo contra a Plataforma ou qualquer usu&aacute;rio; (iv) distribuir ou postar spam n&atilde;o solicitado, ou comunica&ccedil;&otilde;es eletr&ocirc;nicas em massa, correntes ou esquemas de pir&acirc;mide; (v) distribuir v&iacute;rus ou quaisquer outras tecnologias que podem prejudicar a Plataforma; (vi) tentar modificar, traduzir, adaptar, modificar, descompilar, desmontar ou fazer engenharia reversa de qualquer programa de software utilizado em conex&atilde;o com a Plataforma; (vii) copiar, modificar ou distribuir direitos ou conte&uacute;do da Plataforma e/ou direitos autorais e marcas comerciais da empresa; e (viii) colher e/ou compartilhar quaisquer informa&ccedil;&otilde;es sobre outros Usu&aacute;rios sem o consentimento do mesmo.</p>

                    <p> Caso necessite o usu&aacute;rio poder&aacute; autorizar o acesso &agrave; sua p&aacute;gina por algum representante da BONUZ para qualquer fim necess&aacute;rio ao cumprimento destes Termos, solicitando expressamente por email ou whatsapp.</p>

                    <p> CADASTRO</p>

                    <p> Para usufruir dos servi&ccedil;os da BONUZ o usu&aacute;rio precisar&aacute; realizar um cadastro na plataforma.</p>

                    <p> Todos os servi&ccedil;os da BONUZ dependem de pagamento, na forma e pacotes de BNZs expressos na plataforma (ser&atilde;o 5 pacotes dispon&iacute;veis) e de acordo com o porte do usu&aacute;rio, a ser avaliado pela BONUZ. Os valores destacados na plataforma para voc&ecirc; no momento da contrata&ccedil;&atilde;o n&atilde;o ser&atilde;o alterados, a n&atilde;o ser que haja uma mudan&ccedil;a no porte do usu&aacute;rio, o que ser&aacute; verificado pela BONUZ a cada per&iacute;odo de 6 (seis) meses da data da contrata&ccedil;&atilde;o para ajuste no semestre posterior.</p>

                    <p></p>

                    <p> O pacote funciona da seguinte maneira: o usu&aacute;rio adquire o pacote m&iacute;nimo mencionado na plataforma, podendo este ser parcelado em at&eacute; 10 (dez) vezes, possuindo assim os BNZs para uso nos servi&ccedil;os que desejar. Referidos BNZs n&atilde;o possuem prazo de validade.</p>

                    <p></p>

                    <p> Caso o usu&aacute;rio deseje cancelar seu cadastro, N&Atilde;O HAVER&Aacute; DEVOLU&Ccedil;&Atilde;O DE VALORES J&Aacute; PAGOS. Ap&oacute;s o aceite destes termos de uso e pol&iacute;tica de privacidade, e ao adquirir um dos pacotes dispon&iacute;veis, o usu&aacute;rio n&atilde;o pode utilizar os BNZs para um &uacute;nico servi&ccedil;o e desejar pagar somente por tal servi&ccedil;o. O PACOTE ENSEJA O PAGAMENTO DE TODAS AS PARCELAS E, CASO N&Atilde;O QUEIRA UTILIZAR OS BNZs PENDENTES, O USU&Aacute;RIO AINDA ASSIM DEVER&Aacute; ARCAR COM OS PAGAMENTOS DAS MENSALIDADES, SOB PENA DE COBRAN&Ccedil;A JUDICIAL.</p>

                    <p></p>

                    <p> Caso haja o inadimplemento de qualquer mensalidade, o usu&aacute;rio ter&aacute; seu acesso &agrave; Plataforma suspenso at&eacute; que haja a regulariza&ccedil;&atilde;o da situa&ccedil;&atilde;o, sem que isso implique qualquer indeniza&ccedil;&atilde;o por parte da BONUZ por preju&iacute;zos gerados &agrave; esta.</p>

                    <p></p>

                    <p> Qualquer nova demanda ou compra de novo pacote deve ser feita na plataforma, mas caso ocorrida por whatsapp ou e-mail diretamente a algum representante da BONUZ, por qualquer motivo, este servir&aacute; como comprova&ccedil;&atilde;o para d&eacute;bito e cobran&ccedil;a posterior, a qual o usu&aacute;rio n&atilde;o poder&aacute; se recusar.</p>

                    <p></p>

                    <p> Os valores das demandas descritos na plataforma n&atilde;o incluem eventuais taxas ou custos com diligencias necess&aacute;rias para registro dos documentos frente a &oacute;rg&atilde;os p&uacute;blicos, por exemplo, mas n&atilde;o se limitando a: custos de registro nas Juntas Comerciais, Receita Federal e Prefeituras, taxas de registro de marca e software no INPI, custas processuais, portador para entrega de documentos, etc. Considerando que podem variar caso a caso, para precifica&ccedil;&atilde;o de tais custos, por favor nos contatar.</p>

                    <p></p>

                    <p> Em caso de per&iacute;odo de teste, algumas funcionalidades da Plataforma podem n&atilde;o ser disponibilizadas para a Empresa, caso em que agradecemos a compreens&atilde;o e estaremos focados em solucionar a indisponibilidade o mais r&aacute;pido poss&iacute;vel.</p>

                    <p></p>

                    <p> Os usu&aacute;rios ter&atilde;o acesso aos servi&ccedil;os da BONUZ por meio das interfaces apropriadas. Todos os usu&aacute;rios cadastrados est&atilde;o tamb&eacute;m obrigatoriamente vinculados aos presentes Termos de Uso e &agrave; Pol&iacute;tica de Privacidade e dever&atilde;o se cadastrar individualmente no aplicativo da BONUZ para usufru&iacute;rem dos servi&ccedil;os.</p>

                    <p></p>

                    <p> Ao se registrar, o usu&aacute;rio concorda e declara que:</p>

                    <p></p>

                    <p> N&atilde;o registrar&aacute; e/ou usar&aacute; dos servi&ccedil;os da BONUZ para qualquer finalidade que n&atilde;o seja a descrita nestes Termos;  &Eacute; respons&aacute;vel por garantir que o processamento de quaisquer dados inseridos na plataforma da BONUZ seja feito conforme a regulamenta&ccedil;&atilde;o e a lei necess&aacute;rias, inclusive para a exclus&atilde;o e exporta&ccedil;&atilde;o de dados;  Assume total e exclusiva responsabilidade no caso de inserir informa&ccedil;&otilde;es de pessoas f&iacute;sicas (como s&oacute;cios, prestadores de servi&ccedil;os, funcion&aacute;rios, etc.), sendo unicamente respons&aacute;vel por quaisquer preju&iacute;zos causados &agrave; tais pessoas por, mas n&atilde;o se limitando a, vazamento de dados, utiliza&ccedil;&atilde;o indevida dos dados, compartilhamento sem pr&eacute;via autoriza&ccedil;&atilde;o, etc.;  N&atilde;o compartilhar&aacute; quaisquer informa&ccedil;&otilde;es sigilosas obtidas por meio da Plataforma, sob pena de aplica&ccedil;&atilde;o de multa no montante a ser definido de acordo com os danos diretos sofridos pela BONUZ, sem preju&iacute;zo das demais indeniza&ccedil;&otilde;es cobradas judicialmente;  As informa&ccedil;&otilde;es fornecidas durante o processo de registro, discriminadas na Pol&iacute;tica de Privacidade, s&atilde;o verdadeiras, precisas, atuais e completas;  &Eacute; obrigado a revisar e atualizar periodicamente seus dados cadastrais para garantir que estejam sempre atualizados e corretos.  Como titular dos dados, as pessoas f&iacute;sicas informadas pelo usu&aacute;rio podem fornecer instru&ccedil;&otilde;es sobre o que fazer com esses dados, aos quais o usu&aacute;rio &eacute; obrigado &agrave; faz&ecirc;-lo e notificar a BONUZ para que tome as medidas cab&iacute;veis. Todos os detalhes de como coletamos, armazenamos e tratamos os dados s&atilde;o cobertos pela Pol&iacute;tica de Privacidade da plataforma BONUZ.</p>

                    <p> NO CASO DE ATRASO DE QUALQUER PARCELA POR AT&Eacute; 10 (DEZ) DIAS ENSEJAR&Aacute; A INCID&Ecirc;NCIA DE MULTA DE 10% (DEZ POR CENTO) MAIS JUROS DE 1% (UM POR CENTO) AO M&Ecirc;S. PASSADO O PRAZO ACIMA HAVER&Aacute; O VENCIMENTO ANTECIPADO DAS DEMAIS PARCELAS, QUE DEVER&Atilde;O SER PAGAS EM AT&Eacute; 15 (QUINZE) DIAS, COM INCID&Ecirc;NCIA DE MULTA DE 10% (DEZ POR CENTO) MAIS JUROS DE 1% (UM POR CENTO) AO M&Ecirc;S SOBRE O VALOR TOTAL.</p>

                    <p> Os valores das mensalidades e multas, porventura, aplicadas, ser&atilde;o considerados d&iacute;vidas l&iacute;quidas, certas e exig&iacute;veis, com base nestes Termos, ou ainda a BONUZ poder&aacute; cobr&aacute;-las judicialmente servindo, para tanto, o presente Instrumento, como t&iacute;tulo executivo extrajudicial.</p>

                    <p> PROTE&Ccedil;&Atilde;O &Agrave; PRIVACIDADE E DIREITOS AUTORAIS</p>

                    <p> As Pol&iacute;ticas de Privacidade da BONUZ explicam o modo como tratamos seus dados pessoais e protegemos sua privacidade quando voc&ecirc; usa nossos Servi&ccedil;os. Ao utilizar nossos Servi&ccedil;os, voc&ecirc; concorda que a BONUZ poder&aacute; usar esses dados de acordo com nossas Pol&iacute;ticas de Privacidade.</p>

                    <p> Os programas de computador, arquivos, textos, &iacute;cones, desenhos, v&iacute;deos, sons, marcas, logotipos, layouts, templates, inseridos na Plataforma s&atilde;o protegidos por direitos de propriedade intelectual da BONUZ ou de terceiros que autorizaram sua utiliza&ccedil;&atilde;o. Sendo assim, est&atilde;o protegidos pelas leis e pelos tratados internacionais, sendo vedada sua c&oacute;pia, altera&ccedil;&atilde;o, reprodu&ccedil;&atilde;o, ou qualquer outro tipo de utiliza&ccedil;&atilde;o, ficando os infratores sujeitos &agrave;s san&ccedil;&otilde;es civis e criminais correspondentes, nos termos das Leis n&ordm; 9.279/96, n&ordm; 9.610/98 e n&ordm; 9.609/98. Qualquer viola&ccedil;&atilde;o aos direitos de Propriedade Intelectual poder&aacute; culminar, al&eacute;m da incid&ecirc;ncia das responsabilidades legais, no bloqueio sum&aacute;rio e sem aviso pr&eacute;vio da Empresa e de seus Colaboradores, ocasi&atilde;o em que nada ser&aacute; devido &agrave; estes.</p>

                    <p> Ademais, as marcas, os nomes comerciais ou logotipos de qualquer esp&eacute;cie apresentados por meio da Plataforma s&atilde;o de propriedade da BONUZ ou de terceiro que permitiu o seu uso, de modo que a utiliza&ccedil;&atilde;o da Plataforma n&atilde;o consiste em autoriza&ccedil;&atilde;o para que o Usu&aacute;rio possa citar as tais marcas, nomes comerciais e logotipos.</p>

                    <p> A Plataforma pode incluir links para sites e aplicativos de terceiros. Isso n&atilde;o implica, de maneira alguma, que a BONUZ endossa, verifica, garante ou possui qualquer liga&ccedil;&atilde;o com os propriet&aacute;rios desses sites ou aplicativos, n&atilde;o sendo respons&aacute;vel pelo seu conte&uacute;do.</p>

                    <p> SEU CONTE&Uacute;DO EM NOSSOS SERVI&Ccedil;OS</p>

                    <p> Os nossos Servi&ccedil;os permitem que voc&ecirc; submeta, armazene, envie ou receba conte&uacute;do. Voc&ecirc; mant&eacute;m a propriedade de quaisquer direitos de propriedade intelectual que voc&ecirc; detenha sobre aquele conte&uacute;do. Em resumo, aquilo que pertence a voc&ecirc;, permanece com voc&ecirc;.</p>

                    <p> Nossos sistemas automatizados analisam o seu conte&uacute;do selecionado de interesses para fornecer recursos pessoalmente relevantes para voc&ecirc;, como resultados de pesquisas customizadas, sugest&atilde;o de conte&uacute;do, etc.. Essa an&aacute;lise ocorre &agrave; medida que o conte&uacute;do &eacute; enviado e recebido.</p>

                    <p> Voc&ecirc; pode encontrar mais informa&ccedil;&otilde;es sobre como usamos e armazenamos conte&uacute;do na Pol&iacute;tica de Privacidade. Se voc&ecirc; enviar coment&aacute;rios ou sugest&otilde;es sobre nossos Servi&ccedil;os, podemos usar seus coment&aacute;rios ou sugest&otilde;es sem obriga&ccedil;&atilde;o para voc&ecirc;.</p>

                    <p> COMO MODIFICAR E CANCELAR NOSSOS SERVI&Ccedil;OS</p>

                    <p> Estamos constantemente alterando e melhorando nossos Servi&ccedil;os. Podemos incluir ou remover funcionalidades ou recursos e podemos tamb&eacute;m suspender ou encerrar um Servi&ccedil;o por completo.</p>

                    <p> Voc&ecirc; pode deixar de usar nossos Servi&ccedil;os a qualquer momento, embora fiquemos chateados ao ver voc&ecirc; ir embora. A BONUZ tamb&eacute;m poder&aacute; deixar de prestar os Servi&ccedil;os a voc&ecirc; ou, incluir ou criar novos limites a nossos Servi&ccedil;os a qualquer momento.</p>

                    <p> Acreditamos que voc&ecirc; seja o propriet&aacute;rio dos seus dados e que &eacute; importante preservar seu acesso a esses dados. Se descontinuarmos um Servi&ccedil;o, quando razoavelmente poss&iacute;vel, voc&ecirc; ser&aacute; informado com anteced&ecirc;ncia razo&aacute;vel e ter&aacute; a chance de retirar as suas informa&ccedil;&otilde;es daquele Servi&ccedil;o.</p>

                    <p></p>

                    <p>  NOSSAS GARANTIAS E ISEN&Ccedil;&Otilde;ES DE RESPONSABILIDADE</p>

                    <p> Fornecemos nossos Servi&ccedil;os usando um n&iacute;vel comercialmente razo&aacute;vel de capacidade e cuidado e esperamos que voc&ecirc; aproveite seu uso deles. Mas existem algumas coisas que n&atilde;o prometemos sobre nossos Servi&ccedil;os.</p>

                    <p> Exceto quando expressamente previsto nestes termos ou em termos adicionais, nem a BONUZ, nem seus fornecedores ou distribuidores oferecem quaisquer garantias sobre os Servi&ccedil;os prestados. Por exemplo, n&atilde;o nos responsabilizamos pelos conte&uacute;dos e qualidade dos Servi&ccedil;os, por funcionalidades espec&iacute;ficas dos Servi&ccedil;os, ou pela confiabilidade, disponibilidade ou capacidade de atender suas necessidades. Fornecemos os servi&ccedil;os &ldquo;na forma em que est&atilde;o&rdquo;.</p>

                    <p> Devido &agrave; manuten&ccedil;&atilde;o e melhoria do sistema, suas fun&ccedil;&otilde;es podem ser desativadas temporariamente e, neste caso, a BONUZ n&atilde;o poder&aacute; ser responsabilizada pela restri&ccedil;&atilde;o tempor&aacute;ria.</p>

                    <p> RESPONSABILIDADE PELOS NOSSOS SERVI&Ccedil;OS</p>

                    <p> Ap&oacute;s aceite eletr&ocirc;nico dos presentes Termos de Uso, a BONUZ disponibilizar&aacute; os dados de acesso aos usu&aacute;rios, de acordo com os termos ora pactuados, permitindo o acesso a todas as ferramentas disponibilizadas aos Usu&aacute;rios ap&oacute;s a libera&ccedil;&atilde;o do login.</p>

                    <p> A BONUZ garante que, durante o prazo de utiliza&ccedil;&atilde;o da Plataforma pelos Usu&aacute;rios, esta funcionar&aacute; regularmente em bases razo&aacute;veis, exclu&iacute;dos os seguintes casos:</p>

                    <p></p>

                    <p> defeito proveniente de mau uso da Plataforma, devido ao n&atilde;o atendimento aos preceitos estabelecidos pela BONUZ, tais como a falta de conex&atilde;o com a internet, a bateria descarregada ou qualquer outro defeito eletr&ocirc;nico do dispositivo, incompatibilidade de hardware, sistema operacional, mem&oacute;ria insuficiente, etc.;  caso seja constatado que o sistema tenha sido utilizado ou violado por terceiros n&atilde;o autorizados, bem como problemas decorrentes de procedimentos n&atilde;o indicados nos manuais t&eacute;cnicos, como hacking, cracking, crawler, mashups;  problemas oriundos das companhias de servi&ccedil;os telef&ocirc;nicos que disponibilizam sinais para acesso &agrave; internet, sendo considerado caso fortuito e de for&ccedil;a maior;  pelo mau funcionamento do equipamento ocasionado por queda ou indisponibilidade do servidor onde se encontra hospedado o Gateway de SMS e o servi&ccedil;o de envio de e-mails.  SOBRE ESTES TERMOS</p>

                    <p> Podemos modificar estes termos ou quaisquer termos adicionais que sejam aplic&aacute;veis a um Servi&ccedil;o para, por exemplo, refletir altera&ccedil;&otilde;es da lei ou mudan&ccedil;as em nossos Servi&ccedil;os. Voc&ecirc; deve consultar os termos regularmente. Postaremos avisos sobre modifica&ccedil;&otilde;es nesses termos nesta p&aacute;gina. Publicaremos um aviso de altera&ccedil;&atilde;o sobre os termos adicionais dentro do Servi&ccedil;o aplic&aacute;vel. As altera&ccedil;&otilde;es n&atilde;o ser&atilde;o aplicadas retroativamente e entrar&atilde;o em vigor pelo menos cinco dias ap&oacute;s sua publica&ccedil;&atilde;o. Entretanto, altera&ccedil;&otilde;es a respeito de novas funcionalidades de um Servi&ccedil;o ou altera&ccedil;&otilde;es feitas por raz&otilde;es legais entrar&atilde;o em vigor imediatamente. Se voc&ecirc; n&atilde;o concordar com os termos alterados de um Servi&ccedil;o, deve descontinuar o uso desse Servi&ccedil;o.</p>

                    <p> Estes termos regem a rela&ccedil;&atilde;o entre a BONUZ e voc&ecirc;. Eles n&atilde;o criam quaisquer direitos para terceiros.</p>

                    <p> Caso voc&ecirc; n&atilde;o cumpra estes termos e n&oacute;s n&atilde;o tomemos provid&ecirc;ncias imediatas, isso n&atilde;o significa que estamos renunciando a quaisquer direitos que possamos ter (como tomar provid&ecirc;ncias futuras).</p>

                    <p> Caso uma condi&ccedil;&atilde;o espec&iacute;fica destes termos n&atilde;o seja execut&aacute;vel, isso n&atilde;o prejudicar&aacute; quaisquer outros termos.</p>

                    <p> Voc&ecirc; concorda com a aplica&ccedil;&atilde;o das leis do Brasil a quaisquer disputas decorrentes de ou relacionadas com estes termos ou Servi&ccedil;os. Nos outros casos todas as reclama&ccedil;&otilde;es decorrentes de ou relacionadas com esses termos ou Servi&ccedil;os ser&atilde;o litigadas exclusivamente em tribunais da Comarca de S&atilde;o Paulo, Estado de S&atilde;o Paulo e voc&ecirc; e a BONUZ autorizam a jurisdi&ccedil;&atilde;o pessoal nesses tribunais.</p>

                    <p> Para entrar em contato com a BONUZ, por favor envie um e-mail para agueda@bonuz.it e/ou arthur@bonuz.it</p>
                </div>
            </Box>
        </Modal>
    );
};

export default UserTerms;