import React from 'react'
import './UpcomingEvents.css'
import {Card} from "@mui/material";

const UpcomingEvents = (props) => {

    const {activities} = props
    const list = activities?.filter(item => (new Date(item.schedule_date).getTime()) > new Date().getTime())

    return (
        <Card id="upcoming-events">

            <h2>
                Próximas atividades
            </h2>
            <table>
                <thead>
                    <tr>
                        <th>Cliente</th>
                        <th>Atividade</th>
                        <th>Data</th>
                        <th>Responsavél</th>
                    </tr>
                </thead>
                <tbody>
                {list?.map((activity,counter) => (
                    <tr key={counter}>
                        <td>{activity?.client?.companyName}</td>
                        <td>{activity.schedule_name}</td>
                        <td>{new Date(activity.schedule_date).toLocaleDateString('pt-BR')}</td>
                        <td>{activity.responsible}</td>
                    </tr>

                ))}
                </tbody>
            </table>
        </Card>
    )
}

export default UpcomingEvents