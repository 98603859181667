import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import "./MyDemandV2.css";
import api from "../../services/api/api";
import StatusChips from "../../components/StatusChips/StatusChips";
import { api_url } from "../../config/environment/environmentSettings";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { formatedDate } from "../../helpers/Date/formatedDate";
import { useNotifications } from "../../helpers/getNotifications";
import { ReactComponent as Anexar } from "../../assets/icons/anexar.svg";
import { getToken } from "../../services/authentication/authentication";
import SimpleButtonDropdown from "../../components/SemanticUI/SimpleButtonDropdown";
import DemandChat from "../../components/DemandChat/DemandChat";
import Loading from "../../components/Loading/Loading";
import ModalCompletionDemand from "../../components/SemanticUI/Modal/ModalCompletionDemand";
import ModalDocumentRequest from "../../components/DocumentRequest/ModalDocumentRequest";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import "alertifyjs/build/css/themes/default.css";
import ModalTextArea from "../../components/SemanticUI/Modal/ModalTextArea";
import Moment from 'react-moment';

alertify.set("notifier", "position", "top-right");

const MyDemand = (props) => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  const handleSidebar = (sidebar) => setSidebarIsOpen(sidebar);
  const [demandDetails, setDemandDetails] = useState({});
  const [openConclusionDemandModal, setOpenConclusionDemandModal] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [acaoSelectValue, setAcaoSelectValue] = useState("");
  const [openJustificativa, setOpenJustificativa] = useState(false);
  const [standByJustificativa, setStandByJustificativa] = useState("");
  const { notifications, checkNotification } = useNotifications();
  const [previousStatus, setPreviousStatus] = useState("");
  const demandId = props.match.params.demandId;
  const [currentStage, setCurrentStage] = useState("chat");
  const [openDocumentRequest, setOpenDocumentRequest] = useState(false);
  const [documentsPending, setDocumentsPending] = useState([]);
  const [documentsWaiting, setDocumentsWaiting] = useState([]);
  const [documentsDone, setDocumentsDone] = useState([]);
  const [documentsDelete, setDocumentsDelete] = useState([]);
  const [documentsApprove, setDocumentsApprove] = useState([]);
  const [documentsRefuse, setDocumentsRefuse] = useState([]);
  const [refuseDocumentData, setRefuseDocumentData] = useState({});
  const [openRefuseDocument, setOpenRefuseDocument] = useState(false);
;
  useEffect(() => {
    const fetchData = async () => {
      await fetchDemand(demandId);
    };

    fetchData();
  }, [demandId]);

  const fetchDemand = async (id) => {
    let uri = `/demands/show/${id}`;
    setLoading(true);
    api
      .get(uri)
      .then((response) => {
        setDemandDetails(response.data);
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setDocumentsPending(demandDetails.request_documents?.flatMap((item) => item.documents.map(reg => ({ ...reg, requestStatus: item.status, requestValue: item.valueBNZ}))).filter(item => ['pending', 'refused'].includes(item.status)));
    setDocumentsWaiting(demandDetails.request_documents?.flatMap((item) => item.documents.map(reg => ({ ...reg, requestStatus: item.status, requestValue: item.valueBNZ}))).filter(item => item.status === 'waiting_lawyer_acceptance'));
    setDocumentsDone(demandDetails.request_documents?.flatMap((item) => item.documents.map(reg => ({ ...reg, requestStatus: item.status, requestValue: item.valueBNZ}))).filter(item => item.status === 'done'));
  }, [demandDetails])

  const handleConclusionDemand = () => {
    setOpenConclusionDemandModal(!openConclusionDemandModal);
  };

  const handleChangeAcaoSelect = (value) => {
    setPreviousStatus(acaoSelectValue);
    setAcaoSelectValue(value);

    if (value === "stand_by") {
      setOpenJustificativa(true);
    } else {
      handleJustifcativaBaloon(false, true);
    }
  };

  const handleJustifcativaBaloon = (comment, in_progress = false) => {
    if (comment) {
      api
        .post(`/demands/change-status/${demandDetails._id}`, {
          status: acaoSelectValue,
          comment: standByJustificativa,
        })
        .then(() => {
          setOpenJustificativa(false);
          window.location.reload();
        });
    } else if (!comment && in_progress) {
      api
        .post(`/demands/change-status/${demandDetails._id}`, {
          status: "in_progress",
        })
        .then(() => {
          setOpenJustificativa(false);
          window.location.reload();
        });
    }
  };

  const handleStandByJustificativa = (value) => {
    setStandByJustificativa(value);
  };

  const handleJustificatvaCancel = () => {
    setOpenJustificativa(false);
    setAcaoSelectValue(previousStatus);
  };

  const handleCloseDocumentRequest = (updateStatusStandBy = false) => {
    setOpenDocumentRequest(false);
    if (updateStatusStandBy) {
      fetchDemand(demandDetails._id);
    }
  };

  const handleModalDocumentRequest = () => {
    setOpenDocumentRequest(true);
  };

  const getStatusText = (status) => {
    const list = {
      pending: "Pendente",
      in_progress: "Em Progresso",
      stand_by: "Stand-by",
      waiting_approval: "Aguardando Aprovação",
      in_estimating: "Aguardando Orçamento",
      draft: "Pendente de Informação",
      demand_sent: "Demanda Enviada",
      in_review: "Aguardando Revisão",
      canceled: "Cancelado",
      done: "Demanda Concluída",
      waiting_estimate_approval: "Aguardando Aprovação do Orçamento",
      waiting_lawyer_acceptance: "Aguardando Aceite do Advogado",
    };
    return list[status] || "Demanda solicitada";
  };

  const handleRemoveDocument = async (document) => {
    setDocumentsDelete(documentsDelete => [...documentsDelete, document._id]);
    try {
      await api.delete(`/demands/${demandDetails._id}/request/${document.requestId}/document/${document._id}`);
      const updatedDemandDetails = { ...demandDetails };
      const request = updatedDemandDetails.request_documents.find(item => item._id === document.requestId);
      if (request) {
        request.documents = request.documents.filter(item => item._id !== document._id);
        setDemandDetails(updatedDemandDetails);
      }
    } catch (error) {

    
    } finally {
        setDocumentsDelete(documentsDelete => documentsDelete.filter(item => item !== document._id));
    }
  }

  const handleApproveDocument = async (document) => {
    alertify.confirm('Confirmação', 'Deseja realmente aprovar esse documento?', async () => {
      setDocumentsApprove([...documentsApprove, document._id]);
      try {
        await api.put(`/demands/${demandDetails._id}/request/${document.requestId}/document/${document._id}`, {status: 'done'});
        const updatedDemandDetails = { ...demandDetails };
        const request = updatedDemandDetails.request_documents.find(item => item._id === document.requestId);
        if (request) {
          request.documents = request.documents.map(item => {
            const novo = item;
            if (novo._id === document._id) {
              novo.status = 'done';
            }
            return novo;
          });
          setDemandDetails(updatedDemandDetails);
        }
      } catch (error) {

      } finally {
        const index = documentsApprove.findIndex(item => item === document._id);
        setDocumentsApprove(documentsApprove.splice(index, 1));
      }
    }, null)
      .set({labels:{ok:'Sim', cancel: 'Não'}});
  }

  const handleRefuseDocument = async (comment) => {
    const document = refuseDocumentData;
    setDocumentsRefuse([...documentsRefuse, document._id]);
    try {
      await api.put(`/demands/${demandDetails._id}/request/${document.requestId}/document/${document._id}`, {status: 'refused', comment});
      const updatedDemandDetails = { ...demandDetails };
      const request = updatedDemandDetails.request_documents.find(item => item._id === document.requestId);
      if (request) {
        request.documents = request.documents.map(item => {
          const novo = item;
          if (novo._id === document._id) {
            novo.status = 'refused';
          }
          return novo;
        });
        setDemandDetails(updatedDemandDetails);
      }
    } catch (error) {

    } finally {
      const index = documentsRefuse.findIndex(item => item === document._id);
      setDocumentsRefuse(documentsRefuse.splice(index, 1));
    }
  }

  const handleCloseModalRefuseDocument = () => {
    setOpenRefuseDocument(false);
    setRefuseDocumentData({});
  }

  const handleOpenModalRefuseDocument = (document) => {
    setOpenRefuseDocument(true);
    setRefuseDocumentData({...document});
  }

  return (
    <div id="demand">
      <ModalDocumentRequest
        demandDetails={demandDetails}
        openModal={openDocumentRequest}
        handleClose={handleCloseDocumentRequest}
      />
      <ModalTextArea
        data={refuseDocumentData}
        title="Recusar Documento"
        openModal={openRefuseDocument}
        handleClose={handleCloseModalRefuseDocument}
        handleOk={handleRefuseDocument}
      />
      {loading && <Loading />}
      <div className="row">
        <div className="col s12" style={{ height: "65px" }}>
          <Sidebar
            isOpen={handleSidebar}
            notifications={notifications}
            checkNotification={checkNotification}
          />
        </div>
        <div
          className={
            !sidebarIsOpen ? "container-width" : "container-width-sbopen"
          }
        >
          <ModalCompletionDemand
            openModal={openConclusionDemandModal}
            handleClose={handleConclusionDemand}
            demandId={demandDetails._id}
          />
          <div
            className="ui grid"
            style={{ marginLeft: "0px", marginTop: "0px" }}
          >
            <div className="four wide column" style={{ padding: "0px" }}>
              <div className="demand-sidebar">
                <div className="title">
                  <h3>
                    <strong>{`#000${demandDetails.code}`} - {demandDetails.fullDescription}</strong>
                  </h3>
                </div>
                <div className="options-container">
                  <div className="row">
                    <div className="col">
                      <button
                        type="button"
                        onClick={() => setCurrentStage("chat")}
                      >
                        Conversas
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <button
                        type="button"
                        onClick={() => setCurrentStage("info")}
                      >
                        Informações da demanda
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <button
                        type="button"
                        onClick={() => setCurrentStage("request_documents")}
                      >
                        Documentos Solicitados
                        {documentsWaiting &&
                          documentsWaiting.length > 0 && (
                            <i className="icon exclamation triangle orange"></i>
                          )}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="ui divider"></div>
                <div className="subtitle">
                  <h5>Fluxo da demanda</h5>
                </div>
                <div id="fluxos">
                  {demandDetails?.demand_interactions?.filter(item => item.status).map((interaction) => {
                    return (
                      <div className="timeline-item ui grid row">
                        <div
                          className="four wide column"
                          style={{ textAlign: "center" }}
                        >
                          <span>{formatedDate(interaction.createdAt)}</span>
                        </div>
                        <div className="nine wide column">
                          <span className="bullet">•</span>
                          <span>{getStatusText(interaction.status)}</span>
                          <span>
                            {interaction.documents &&
                            interaction.documents.length > 0
                              ? interaction.documents.map((d) => (
                                  <Anexar
                                    className="attach-button"
                                    onClick={() =>
                                      window.open(
                                        `${api_url}/demands/${
                                          demandDetails._id
                                        }/file/${d.$oid}?token=${getToken()}`,
                                        "_blank"
                                      )
                                    }
                                    style={{ marginRight: "1px" }}
                                  />
                                ))
                              : ""}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="twelve wide column" style={{ padding: "0px" }}>
              <div className="ui grid" id="demand-v2-topbar">
                <div className="three wide column">
                  <h6>Prazo final</h6>
                  <p>
                    {demandDetails.status !== 'stand_by' && (
                      <strong><Moment format='DD/MM/YYYY'>{demandDetails?.finalDate}</Moment></strong>
                    )}
                    {demandDetails.status === 'stand_by' && (
                      'Em stand-by'
                    )}
                  </p>
                </div>
                <div className="two wide column">
                  <h6>Código</h6>
                  <p>
                    <strong>
                      #{demandDetails?.code?.toString()?.padStart(6, "0")}
                    </strong>
                  </p>
                </div>
                <div className="three wide column">
                  <h6>Status</h6>
                  <p>
                    {demandDetails.status === "in_progress" ||
                    demandDetails.status === "stand_by" ||
                    demandDetails.status === "waiting_lawyer_acceptance" ||
                    demandDetails.status === "pending" ||
                    demandDetails.status === "in_review" ? (
                      <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <Select
                          value={acaoSelectValue}
                          onChange={(e) =>
                            handleChangeAcaoSelect(e.target.value)
                          }
                          className="select-table"
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return (
                                <StatusChips status={demandDetails.status} />
                              );
                            } else {
                              return <StatusChips status={acaoSelectValue} />;
                            }
                          }}
                        >
                          {["in_progress", "stand_by"].map((menuItem) => {
                            if (menuItem !== demandDetails.status)
                              return (
                                <MenuItem value={menuItem}>
                                  <StatusChips status={menuItem} />
                                </MenuItem>
                              );
                            return null;
                          })}
                        </Select>
                      </FormControl>
                    ) : (
                      <StatusChips status={demandDetails.status} />
                    )}
                  </p>
                  <div
                    className={
                      openJustificativa
                        ? "justificativa"
                        : "justificativa d-none"
                    }
                  >
                    <p>
                      Esta demanda está em stand-by, por favor informe uma
                      justificativa.
                    </p>
                    <textarea
                      placeholder="Mensagem"
                      onChange={(e) =>
                        handleStandByJustificativa(e.target.value)
                      }
                    ></textarea>
                    <div
                      className="two ui buttons"
                      style={{ maxWidth: "80%", marginTop: "10px" }}
                    >
                      <button
                        type="button"
                        className={`ui button green icon ${
                          !standByJustificativa ? "disabled" : ""
                        }`}
                        onClick={() => handleJustifcativaBaloon(true)}
                      >
                        <i className="icon check"></i> Confirmar
                      </button>
                      <button
                        type="button"
                        className="ui red button icon"
                        onClick={() => handleJustificatvaCancel()}
                      >
                        <i className="icon remove"></i> Cancelar
                      </button>
                    </div>
                  </div>
                </div>
                <div className="eight wide column">
                    <SimpleButtonDropdown
                      buttonText="Novo serviço"
                      buttonIcon="plus"
                      class="icon blue"
                      options={[
                        {
                          value: "bonuz-plan",
                          text: "Planos Bonuz",
                          onClick: () =>
                            window.open(
                              `/services/bonuz-plans/${demandDetails?.client_info?._id}`,
                              "_blank"
                            ),
                        },
                        {
                          value: "jf",
                          text: "Jurídico Familiar",
                          onClick: () =>
                            window.open(
                              `/services/1/${demandDetails?.client_info?._id}`,
                              "_blank"
                            ),
                        },
                      ]}
                      comment="Escolha um Servço"
                    />
                    {demandDetails?.status === "in_progress" ? (
                      <>
                        <button
                          className="ui button red icon labeled"
                          onClick={handleModalDocumentRequest}
                        >
                          <i className="icon file"></i>
                          Solicitar documentos
                        </button>
                        <button
                          className="ui button green icon labeled"
                          onClick={() => handleConclusionDemand()}
                        >
                          <i className="icon check"></i>
                          Concluir
                        </button>
                      </>
                    ) : null}
                </div>
              </div>
              {currentStage === "chat" && (
                <DemandChat
                  demandDetails={demandDetails}
                  loading={setLoading}
                ></DemandChat>
              )}
              {currentStage === "info" && (
                <div className="ui grid" id="demand-info">
                  <div
                    className="sixteen wide column"
                    style={{ paddingRight: "20px" }}
                  >
                    <div className="demand-obs">
                      <h3>Observações/Comentário</h3>
                      <p>
                        {demandDetails?.demand_interactions &&
                        demandDetails?.demand_interactions[0]?.comment
                          ? demandDetails?.demand_interactions[0]?.comment
                          : ""}
                      </p>
                    </div>
                    <div className="demand-obs">
                      <h3>Dados do Cliente</h3>
                      <p>
                        Nome: {demandDetails?.client_info?.firstName} {demandDetails?.client_info?.lastName} | 
                        CNPJ/CPF: {demandDetails?.client_info?.document === null ? demandDetails?.client_info?.cnpj : demandDetails?.client_info?.document}
                      </p>
                    </div>
                    <div className="demand-awnsers">
                      <h3>Detalhes da demanda</h3>
                      {demandDetails?.demand_anwsers &&
                      demandDetails?.demand_anwsers.length > 0 ? (
                        demandDetails?.demand_anwsers.map((answer, counter) => {
                          return (
                            <div>
                              <p>
                                <b>{answer.question}</b>
                              </p>
                              <p>
                                Resposta: <b>{answer.anwser}</b>
                              </p>
                              {answer.documents && answer.documents.length > 0 && (
                                <p>Arquivos:
                                  <div className="file-list">
                                  {answer?.documents?.map((file, index) => (
                                    <div className="file-item" style={{cursor: "pointer"}}>
                                      <i className="icon paperclip"></i>
                                      <span onClick={() => {window.open(`${api_url}/demands/${demandDetails._id}/file/${file._id}?token=${getToken()}`,'_blank')}}>{file.name}</span>
                                    </div>
                                  ))}
                                  </div>
                                </p>
                              )}
                              <br />
                            </div>
                          );
                        })
                      ) : (
                        <p>Não há questionário preenchido</p>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {currentStage === "request_documents" && (
                <div id="demand-request-documents">
                  {documentsPending && documentsPending.length > 0 && (
                    <div className="ui grid">
                        <div className="in_progress">
                            <div className="ui grid">
                                <div className="row">
                                    <div className="eleven wide column">
                                        <h3>Documentos pendentes <i className="icon exclamation triangle orange"></i></h3>
                                    </div>
                                </div>
                                {documentsPending.map((document, index) => {
                                  return <div className="row document" key={index}>
                                          <div className="twelve wide column">
                                              <div className="document">
                                                <span>{document.description}</span>
                                                {document.status === 'refused' && <span className="comment"> (Motivo Recusa: {document.comment})</span>}
                                                {document.requestStatus === 'refused' && (<span className="comment"> {` Cliente Recusou (${document.requestValue} BNZs)`}</span>)}
                                            </div>
                                              {document.requestFiles && (
                                                <>
                                                  <span className="model">Modelo</span>
                                                  <div>
                                                    <button
                                                      type="button"
                                                      className="ui button"
                                                      onClick={() => window.open(
                                                        `${api_url}/demands/${
                                                          demandDetails._id
                                                        }/file/${document.requestedFiles[0]._id}?token=${getToken()}`,
                                                        "_blank"
                                                      )}>
                                                      <i className="icon download"></i> {document.requestedFiles[0].name}
                                                    </button>
                                                  </div>
                                                </>
                                              )}
                                          </div>
                                          <div className="four wide column send">
                                            <button 
                                              type="button" 
                                              className={`ui button red icon circular large ${documentsDelete.includes(document._id) ? 'disabled loading' : ''}`} 
                                              onClick={() => handleRemoveDocument(document)}
                                            >
                                              <i className="icon remove"></i>
                                            </button>
                                          </div>
                                      </div>
                                })}
                            </div>
                        </div>
                    </div>
                  )}
                  {documentsWaiting && documentsWaiting.length > 0 && (
                    <div className="ui grid">
                        <div className="in_progress">
                            <div className="ui grid">
                                <div className="row">
                                    <div className="sixteen wide column">
                                        <h3>Documentos aguardando aprovação <i className="icon hourglass half orange"></i></h3>
                                    </div>
                                </div>
                                {documentsWaiting.map((document, index) => {
                                  return <div className="row document" key={index}>
                                      <div className="ten wide column">
                                          <div className="document">{document.description}</div>
                                          {document.requestFiles && (
                                            <>
                                              <span className="model">Modelo</span>
                                              <div>
                                                <button
                                                  type="button"
                                                  className="ui button"
                                                  onClick={() => window.open(
                                                    `${api_url}/demands/${
                                                      demandDetails._id
                                                    }/file/${document.requestedFiles[0]._id}?token=${getToken()}`,
                                                    "_blank"
                                                  )}>
                                                  <i className="icon download"></i> {document.requestedFiles[0].name}
                                                </button>
                                              </div>
                                            </>
                                          )}
                                      </div>
                                      <div className="six wide column send">
                                        {document.deliveryFiles && (
                                          <button
                                            type="button"
                                            className="ui button black large"
                                            onClick={() => window.open(
                                              `${api_url}/demands/${
                                                demandDetails._id
                                              }/file/${document.deliveredFiles[0]._id}?token=${getToken()}`,
                                              "_blank"
                                            )}>
                                            <i className="icon download"></i> Baixar
                                          </button>
                                        )}
                                        <div className="ui buttons">
                                            <button
                                              type="button"
                                              className={`ui button icon large ${documentsRefuse.includes(document._id) ? 'disabled loading' : ''}`}
                                              onClick={() => handleOpenModalRefuseDocument(document)}
                                            >
                                              <i className="icon remove"></i> Recusar
                                            </button>
                                            <div class="or" data-text="ou"></div>
                                            <button
                                              type="button"
                                              className={`ui button green icon large ${documentsApprove.includes(document._id) ? 'disabled loading' : ''}`}
                                              onClick={() => handleApproveDocument(document)}
                                            >
                                              <i className="icon check"></i> Aprovar
                                            </button>
                                        </div>
                                      </div>
                                  </div>
                                })}
                            </div>
                        </div>
                    </div>
                  )}
                  {documentsDone && documentsDone.length > 0 && (
                    <div className="ui grid">
                        <div className="in_progress">
                            <div className="ui grid">
                                <div className="row">
                                    <div className="sixteen wide column">
                                        <h3>Documentos concluídos <i className="icon check green"></i></h3>
                                    </div>
                                </div>
                                {documentsDone.map((document, index) => {
                                  return <div className="row document" key={index}>
                                    <div className="ten wide column">
                                      <div className="document">{document.description}</div>
                                      {document.requestFiles && (
                                        <>
                                          <span className="model">Modelo</span>
                                          <div>
                                            <button
                                              type="button"
                                              className="ui button"
                                              onClick={() => window.open(
                                                `${api_url}/demands/${
                                                  demandDetails._id
                                                }/file/${document.requestedFiles[0]._id}?token=${getToken()}`,
                                                "_blank"
                                              )}>
                                              <i className="icon download"></i> {document.requestedFiles[0].name}
                                            </button>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                    <div className="six wide column send">
                                      {document.deliveredFiles && (
                                        <button
                                          type="button"
                                          className="ui button black large"
                                          onClick={() => window.open(
                                            `${api_url}/demands/${
                                              demandDetails._id
                                            }/file/${document.deliveredFiles[0]._id}?token=${getToken()}`,
                                            "_blank"
                                          )}>
                                          <i className="icon download"></i> Baixar
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                })}
                            </div>
                        </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyDemand;
