
import api from "../../services/api/api";
import axios from 'axios';


const uploadFiles = async (files, path) => {
  const uploadedFiles = [];
  console.log('filessss', files);
  for (const file of files) {
    console.log(`Iniciando upload do arquivo: ${file.name}`);
    const upload = await uploadFile(file, path);
    console.log(`Upload concluído do arquivo: ${file.name}`);
    uploadedFiles.push(upload);
  }
  return uploadedFiles;
};

const getPresignedUrl = async (file) => {
    const response = await api.post("/upload/generate-presigned-url", {
      filename: file.name,
      filetype: file.type,
    });

    return response.data.url;
  };

  const moveFile = async (filename, path) => {
    const response = await api.post("/upload/move-file", {
      filename: filename,
      newpath: path,
    });
    return response.data;
  };

  const uploadFile = async (file, path) => {
    const presignedUrl = await getPresignedUrl(file);

    await axios.put(presignedUrl, file, {
      headers: {
        "Content-Type": file.type,
        Accept: "*/*",
      },
    });

    const uploadFile = await moveFile(file.name, path);
    return {
      name: file.name,
      fileName: uploadFile.name,
      extension: file.name.split(".").pop(),
      type: file.type,
      url: uploadFile.url,
    };
  };

  export default uploadFiles;