import React, {useEffect, useState} from 'react';
import Sidebar from "../../components/Sidebar/Sidebar";
import Breadcumb from "../../components/Breadcumb/Breadcumb";
import { ReactComponent as FileAnexoIcon } from "../../assets/fileAnexoIcon.svg"
import { ReactComponent as MessageIcon } from "../../assets/messageIcon.svg"
import { Button } from "semantic-ui-react"
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa"
import Demands from "../Demands/Demands";
import './Demand.css'
import api from "../../services/api/api";
import StatusChips from "../../components/StatusChips/StatusChips";
import TableModal from '../../components/TableModal/TableModal';
import {api_url} from "../../config/environment/environmentSettings";
import ChatServiceModal from '../../components/Modals/ChatService/ChatServiceModal';
import { formatedDate } from '../../helpers/Date/formatedDate';
import { useLocation } from 'react-router-dom';
import ConclusionDemand from '../../components/Modals/ConclusionDemand/ConclusionDemand';
import LanceModal from '../../components/Modals/TableDemands/Lance/LanceModal'
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useNotifications } from "../../helpers/getNotifications";
import {getToken} from "../../services/authentication/authentication";

const Demand = (props) => {

    const [sidebarIsOpen,setSidebarIsOpen] = useState(false);
    const handleSidebar = (sidebar)=>setSidebarIsOpen(sidebar);
    const location = useLocation()
    const {notifications, checkNotification} = useNotifications();
    
    const [comentarioDemand, setComantarioDemand] = useState("")
    
    let breadcumb = [
        {link:<Demands/>,name:'Demandas'},
        {link:<Demands/>,name:'Demanda'},
        {link:<Demand/>,name:'Detalhes'}
    ]
    
    const [demandDetails, setDemandDetails] = useState({});
    const [showServiceDetails, setShowServiceDetails] = useState(true)
    const [typeDemand ] = useState(location.search.slice(1))
    const [currentInteraction, setCurrentInteraction] = useState({});
    const [openChatServiceModal, setOpenChatServiceModal] = useState(false)
    const [openConclusionDemandModal, setOpenConclusionDemandModal] = useState(false);
    const [openLanceModal, setOpenLanceModal] = useState(false)
    const [viewLanceModal, setViewLanceModal] = useState("default")
    const [loading, setLoading] = useState(true)
    const [acaoSelectValue, setAcaoSelectValue] = useState("");
    const [openJustificativa, setOpenJustificativa] = useState(false);
    const [standByJustificativa, setStandByJustificativa] = useState("");
    const demandId = props.match.params.demandId;
    

    useEffect(() => {
        const fetchInictialComment = (array) => {
            if (array && array.length > 0) {
                const myArray = array
                setComantarioDemand(myArray[myArray.length - 1].comment)
            }
        }

        fetchInictialComment(demandDetails?.demand_interactions)
    }, [demandId, demandDetails])
    
    useEffect(() => {
        const fetchData = async () => {
            await fetchDemand(demandId, typeDemand);
        };
    
        fetchData();
    }, [demandId, typeDemand]);

    const handleChatServiceModal = (data) => {
        if (data) {
            setOpenChatServiceModal(!openChatServiceModal)
        }
    }

    const fetchDemand = async (id, type) => {
        let uri;
         switch(type){
            case "picker": 
                uri = `/demands/pending-acceptance/show/${id}`
            break;
            case "auction":
                uri = `/demands/show-in-auction/${id}`
            break;
            default: uri = `/demands/show/${id}`;
        }

        //console.log(uri, type)
        api.get(uri).then((response) => {
            setDemandDetails(response.data)
            setLoading(false)
                setCurrentInteraction(
                    response.data.demand_interactions[response.data.demand_interactions.length -1]
                    );
                }).catch((error)=>{
                            
        });
    }
            
    const handleConclusionDemand = () => {
        setOpenConclusionDemandModal(!openConclusionDemandModal)
    }

    const handleLanceModal = (id) => {
        setOpenLanceModal(!openLanceModal)
    }


    const handleChangeAcaoSelect = (value) => {
        setAcaoSelectValue(value);

        if(value === 'stand_by'){
            setOpenJustificativa(true)
        }else{
            handleJustifcativaBaloon(false,true)
        }
    };

    const handleJustifcativaBaloon = (comment, in_progress = false) => {
        if (comment) {
            api.post(`/demands/change-status/${demandDetails._id}`,
                {
                    'status':acaoSelectValue,
                    'comment':standByJustificativa
                }
            ).then(()=>
            {
                setOpenJustificativa(false)
                window.location.reload()
            })
        } else if(!comment && in_progress){
            api.post(`/demands/change-status/${demandDetails._id}`,
                {
                    'status':'in_progress',
                }
            ).then(()=>
            {
                setOpenJustificativa(false)
                window.location.reload()
            })
        }
    }

    const handleStandByJustificativa = (value) => {
        setStandByJustificativa(value)
    }
    
    return (
        <div id="demand">
                <div className="row">
                    <div className="col s12">
                        <Sidebar isOpen={handleSidebar} notifications={notifications} checkNotification={checkNotification}/>
                    </div>
                    <div className={!sidebarIsOpen ? "container-width" : "container-width-sbopen"}>
                        <div className="col s12">
                        <Breadcumb breadcumbs={breadcumb}/>
                        </div>
                    {loading ? 'carregando...' : 
                    <>
                        <ChatServiceModal
                            openModal={openChatServiceModal}
                            handleClose={handleChatServiceModal}
                            chatInfo={demandDetails?.client_info}
                            demandId={demandDetails._id}
                        />
                        <ConclusionDemand
                            openModal={openConclusionDemandModal}
                            handleClose={handleConclusionDemand}
                            demandId={demandDetails?._id}
                        />
                        <LanceModal
                            openModal={openLanceModal}
                            handleModal={handleLanceModal}
                            setView={setViewLanceModal}
                            view={viewLanceModal}
                            demandId={demandDetails?._id}
                        />
                        <div className="info-demand-view">
                            <div className="info-container">
                                <div className="title">
                                    <div><h2>{`#000${demandDetails.code}`} - {demandDetails.fullDescription}</h2></div>
                                    <div className="acao">
                                        <div className="inform">
                                            <span>Ações:4</span> {typeDemand} - {demandDetails.status}
                                        </div>
                                        {
                                            (typeDemand === 'myDemand' && demandDetails.status === "in_progress")
                                            ||
                                            demandDetails.status === "stand_by"
                                            ||
                                            (typeDemand === 'myDemand' && demandDetails.status === "pending")
                                            ||
                                            (typeDemand === 'myDemand' && demandDetails.status === "waiting_lawyer_acceptance")
                                            ||
                                            (typeDemand === 'myDemand' && demandDetails.status === "in_review") ?
                                                <FormControl sx={{ m: 1, minWidth: 120 } }>
                                                    <Select
                                                        value={acaoSelectValue}
                                                        onChange={ (e) => handleChangeAcaoSelect(e.target.value)}
                                                        className="select-table"
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        renderValue={(selected) => {
                                                            if (selected.length === 0) {
                                                                return <StatusChips status={demandDetails.status}/>

                                                            } else {
                                                                return <StatusChips status={acaoSelectValue}/>
                                                            }
                                                        }}
                                                    >
                                                        {
                                                            ["in_progress", "stand_by"].map((menuItem) => {
                                                                if(menuItem !== demandDetails.status) return <MenuItem value={menuItem}><StatusChips status={menuItem}/></MenuItem>
                                                                return null;
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                                : <StatusChips status={demandDetails.status}/>
                                        }
                                        <div className={openJustificativa ? "justificativa" : "justificativa d-none"} >
                                            <p>Esta demanda está em stand-by, por favor informe uma justificativa.</p>
                                            <textarea placeholder="Mensagem" onChange={(e) => handleStandByJustificativa(e.target.value)}></textarea>
                                            <button onClick={() => handleJustifcativaBaloon(true)} disabled={!standByJustificativa}>OK</button>
                                        </div>
                                    </div>
                                </div>
                                    {typeDemand === 'auction' || typeDemand === 'myDemand' ? (
                                    <div
                                        className="table-container"
                                        style={{ display: "block" }}
                                    >
                                        <div className="scroll">
                                            <TableModal
                                                handleChat={handleChatServiceModal}
                                                client={demandDetails?.client_info}
                                                demandas={currentInteraction}
                                                isMyDemand={typeDemand === 'myDemand'}
                                                isClientTable
                                            />
                                        </div>
                                    </div>
                            ) : (
                                <div className="data">
                                    <div className="inform">
                                        <span>Data de entrega</span>
                                    </div>
                                    <strong>
                                        {formatedDate(demandDetails?.finalDate)}
                                    </strong>
                                </div>
                            )}
                            <div className="obs">
                                <div className="inform">
                                    <span>Obervação/Comentário</span>
                                </div>
                                <p>{comentarioDemand === "" ? "Não existe observações/comentários" : comentarioDemand}</p>
                            </div>
                                <div className="data">
                                    <div className="inform">
                                        <span>Detalhes do serviço</span>
                                    </div>
                                    <span
                                        className="service-detail"
                                        onClick={() =>
                                            setShowServiceDetails(!showServiceDetails)
                                        }
                                    >
                                        Detalhes do serviço{" "}
                                        {showServiceDetails ? (
                                            <FaRegEye />
                                        ) : (
                                            <FaRegEyeSlash />
                                        )}
                                    </span>
                                </div>
                                {showServiceDetails ? (
                                    <div className="obs">
                                        <div className="inform">
                                            <span>
                                                Descreva que tipo de trabalho gostaria
                                                de ver realizado.
                                            </span>
                                        </div>
                                        <div className="anwser">
                                            {demandDetails?.demand_anwsers
                                                ? demandDetails?.demand_anwsers.map(
                                                    (anwser) => {
                                                        if (
                                                            anwser.question &&
                                                            anwser.anwser
                                                        ) {
                                                            return (
                                                                <div>
                                                                    <p>
                                                                        <strong>
                                                                            {
                                                                                anwser.question
                                                                            }
                                                                        </strong>
                                                                    </p>
                                                                    <p>
                                                                        <small>
                                                                            Resposta:{" "}
                                                                        </small>
                                                                        {
                                                                            anwser.anwser
                                                                        }
                                                                    </p>
                                                                    {anwser.documents.map(
                                                                        (
                                                                            document,
                                                                            counter,
                                                                        ) => {
                                                                            return (
                                                                                <a
                                                                                    className="anexo-file"
                                                                                    target="_blank"
                                                                                    href={`${api_url}/${document}?token=${getToken()}`}
                                                                                    rel="noreferrer"
                                                                                    download
                                                                                >
                                                                                    Anexo{" "}
                                                                                    {
                                                                                        ++counter
                                                                                    }{" "}
                                                                                    <FileAnexoIcon />{" "}
                                                                                    &nbsp;
                                                                                </a>
                                                                            )
                                                                        },
                                                                    )}
                                                                    <br />
                                                                </div>
                                                            )
                                                        }
                                                        return null;
                                                    },
                                                )
                                    : "Sem detalhes"}

                                        </div>
                                    </div>
                                ) : (
                                    ""
                                    )}

                                <div className="table-history">
                                    {
                                        typeDemand === 'myDemand' ?
                                            <div className="table-container">
                                                <div style={{width: "50px",paddingTop: "10px"}}>
                                                    <span>Histórico</span>
                                                </div>
                                                <div className="scroll">
                                                    <TableModal isHistoryDemand isAuctionDemand={demandDetails.isAuction} handleChat={handleChatServiceModal} demandSubService={demandDetails.subserivceName} demandas={demandDetails} setComantarioDemand={setComantarioDemand}/>
                                                </div>
                                            </div>
                                            :
                                            ""
                                    }


                                </div>
                                    
                                    {typeDemand === 'auction' ? (
                                    <div className="service-chat">
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                            onClick={() =>
                                                handleChatServiceModal(demandDetails)
                                            }
                                        >
                                            <MessageIcon />
                                            <div>
                                                <span>Chat do serviço</span>
                                            </div>
                                        </div>
                                        <div>
                                            <button
                                                className="btn-red"
                                                style={{
                                                    width: "94px",
                                                    height: "32px",
                                                }}
                                                onClick={() => handleLanceModal()}
                                            >
                                                Dar lance
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="valor">
                                        {typeDemand === 'auction' ? (
                                            <button
                                                className="btn-green"
                                                style={{
                                                    width: "94px",
                                                    height: "32px",
                                                }}
                                                onClick={() => handleLanceModal()}
                                            >
                                                Auction
                                            </button>
                                        ) : (
                                            <div>
                                                <div className="inform">
                                                    {/*<span>Valor</span>*/}
                                                </div>
                                                {/*{*/}
                                                {/*    demand?.status !== 'in_estimating'*/}
                                                {/*    ?*/}
                                                {/*        <strong>{`BNZ ${demand?.price},00`}</strong>*/}
                                                {/*    :*/}
                                                {/*        <h6>Valor em orçamento</h6>*/}
                                                {/*}*/}
                                            </div>
                                        )}
                                    </div>
                                )}


                                    {demandDetails?.status === "in_progress" ? (
                                        <Button
                                            style={{ marginTop: "20px" }}
                                            onClick={() => handleConclusionDemand()}
                                            color="green"
                                        >
                                            Concluir
                                        </Button>
                                    ) : (
                                        ""
                                    )}

                                <Button
                                    style={{ marginTop: "20px" }}
                                    onClick={() => window.open(`/services/1/${demandDetails?.client_info?._id}`,'_blank')}
                                    color="blue"
                                >
                                    Adicionar serviço (JF)
                                </Button>
                            </div>
                        </div>
                
                </>  
        }
        </div>
        </div>
        </div>
    )
}

export default Demand