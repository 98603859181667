import React from "react";
import "./Searchbar.css"
import { Input } from 'semantic-ui-react'
import debounce from "../../helpers/debunce";

const Searchbar = (props) => {
    const onChangeHandle = debounce(props.search, 500);
    return (
        <Input icon='search'  iconPosition='left' onChange={(event) => onChangeHandle(event.target.value)} placeholder='Buscar por' className="searchbar"/>
    )
}

export default Searchbar;