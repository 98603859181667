import { useState, useCallback, useEffect } from "react";
import api from "../services/api/api";
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import 'alertifyjs/build/css/themes/default.css';

alertify.set('notifier', 'position', 'top-right');

export const useNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const fetchNotifications = useCallback(() => {
    api
      .get("/notifications/index")
      .then((response) => {
        setNotifications(response.data);
      })
      .catch((error) => {
        //console.log(error)
      });
  }, []);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  const checkNotification = (notification, open = true) => {
    api
      .put("/notifications/check/" + notification._id)
      .then((response) => {
        setNotifications((prevNotifications) => {
          return prevNotifications.map((prevNotification) => {
            if (prevNotification._id === notification._id) {
              return { ...prevNotification, checked: true };
            }
            return prevNotification;
          });
        });
        if (open) {
          const newWindow = window.open(notification.linkTo.replace("/demands/", "/demand/"), "_blank");
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            alertify.notify('A nova guia foi bloqueada. Por favor, habilite pop-ups no seu navegador.', 'error', 15);
          }
        }
      })
      .catch((error) => {
        //console.log(error)
      });
  };

  return {
    notifications,
    checkNotification,
  };
};
