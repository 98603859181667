import React from "react";
import './Login.css';
import LoginForm from "../../components/LoginForm/LoginForm";
import BannerAdvogados from "../../components/BannerLogin/BannerAdvogados";
import 'semantic-ui-css/semantic.min.css'
import { useLocation } from "react-router-dom";

const Login = () => {

    const location = useLocation();

    let pathName;
    pathName = location.pathname.substring(7);

    return (
        <div id="login-main">
            <div className="container" style={{margin: 'auto 16px'}}>
                <div id="login-content" className={pathName === "row forgotPassword" ? " row forgot" : "row"}>
                  <LoginForm />
                  <BannerAdvogados />
                </div>
            </div>
        </div>
    );
};

export default Login