import React, {useState} from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Coruja from "../../../../assets/CorujaOptionModal.png";
import "./AcceptModal.css";
import api from "../../../../services/api/api";

const AcceptModal = (props) => {

    const [view, setView] = useState("default");

    const {
        handleClose,
        openModal,
        setOptionDemand,
        refreshDemands,
    } = props;

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: 'none !important',
        boxShadow: 24,
        p: 4,
      };

      const selectOptionModal = (value) => {
        if (value === true) {
            api.put(`/demands/accept/${props.demandId}`)
                .then(()=>{
                    setOptionDemand(true)
                    setView("finish-modal");
                    setInterval(()=>window.location.reload(),1500)
                }).catch((error)=>{

            })

            refreshDemands()

        } else {
            setOptionDemand(false)
            handleClose()
        }
      }

    return (
        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus
        >
            <Box sx={style} className={view === "finish-modal" ? "box accept-modal finish" : "box accept-modal"}>
                {view === "default" ?
                <>
                    <h2>Você solicitou uma demanda!</h2>
                    <div><img src={Coruja} alt="Bonuz owl"/></div>
                    <p>Deseja mesmo aceitar esta demanda?</p>
                    <div>
                        <button className='cancel' onClick={() => selectOptionModal(false)}>Cancelar</button>
                        <button onClick={() => selectOptionModal(true)}>Aceitar</button>
                    </div>
                </>
                : ""
                }
                {view === "finish-modal" ?
                <>
                    <h2>Esta demanda foi aceita!</h2>
                    <h2>veja em <span>‘Minhas demandas’</span></h2>
                </>
                : ""
                }
                
            </Box>
        </Modal>
    );
};

export default AcceptModal;