import React from 'react'
import './StatusChanger.css'

const StatusChanger = (props) => {

    if(props.status === 'dont_apply'){
        return (
            <div>
                <select id="status-changer-select" onChange={(e)=>props.handleUpdate(props.activityId,e.target.value)}>
                     <option value="dont_apply" selected>Não se aplica</option>
                     <option value="pending">Pendente</option>
                     <option value="in_progress">Em progresso</option>
                     <option value="done">Concluído</option>
                </select>
            </div>
        )
    }else if(props.status === 'pending'){
        return (
            <div>
                <select id="status-changer-select" onChange={(e)=>props.handleUpdate(props.activityId,e.target.value)}>
                    <option value="dont_apply">Não se aplica</option>
                    <option value="pending" selected>Pendente</option>
                    <option value="in_progress">Em progresso</option>
                    <option value="done">Concluído</option>
                </select>
            </div>
        )
    }else if(props.status === 'in_progress'){
        return (
            <div>
                <select id="status-changer-select" onChange={(e)=>props.handleUpdate(props.activityId,e.target.value)}>
                    <option value="dont_apply">Não se aplica</option>
                    <option value="pending">Pendente</option>
                    <option value="in_progress" selected>Em progresso</option>
                    <option value="done">Concluído</option>
                </select>
            </div>
        )
    }else if(props.status === 'done'){
        return (
            <div>
                <select id="status-changer-select" onChange={(e)=>props.handleUpdate(props.activityId,e.target.value)}>
                    <option value="dont_apply">Não se aplica</option>
                    <option value="pending">Pendente</option>
                    <option value="in_progress">Em progresso</option>
                    <option value="done" selected>Concluído</option>
                </select>
            </div>
        )
    }
}

export default StatusChanger