import React, { useState, useRef } from "react";
import {
  ModalContent,
  ModalActions,
  Button,
  Header,
  Icon,
  Modal,
  Form,
} from "semantic-ui-react";
import "./ModalCompletionDemand.css";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import "alertifyjs/build/css/themes/default.css";
import api from "../../../services/api/api";
import uploadFiles from "../../../services/api/uploadS3";

alertify.set("notifier", "position", "top-right");

const ModalCompletionDemand = (props) => {
  const { openModal, handleClose } = props;

  const [comment, setComment] = useState("");
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFinish = async (e) => {
    e.preventDefault();
    if (comment === "") {
      alertify.notify("As observações são obrigatórias.", "error", 5);
      return false;
    }
    if (files.length <= 0) {
      alertify.notify("O envio de arquivos é obrigatório", "error", 5);
      return false;
    }
    setIsLoading(true);
    const uploadedFiles = await uploadFiles(files, `documents/${props.demandId}/{userId}/`);

    api
      .post(`/demands/finish/${props.demandId}`, {
        comment,
        files: uploadedFiles,
      })
      .then(() => {
        fileInputRef.current.value = '';
        alert("Demanda enviada para revisão com sucesso");
        close();
        window.location.reload();
      })
      .catch((error) => {
        window.alert("Erro ao finalizar a demanda");
      })
      .finally(() => setIsLoading(false));
  };

  const close = () => {
    setFiles([]);
    setComment("");
    handleClose();
  };

  const handlerFileClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Modal
      closeIcon={!isLoading}
      centered={true}
      open={openModal}
      onClose={close}
      closable={false}
      size="small"
      closeOnDimmerClick={false}
    >
      <Header icon="check circle outline" content="Conclusão" />
      <ModalContent>
        <Form action="#">
          <div className="fields">
            <div className="fourteen wide field">
              <label for="comment">Observações</label>
              <textarea
                name="comment"
                id="comment"
                onChange={(e) => setComment(e.target.value)}
                rows="5"
                required
                style={{ maxHeight: "50px" }}
              ></textarea>
            </div>
            <div className="two wide field" style={{ verticalAlign: "middle" }}>
              <label>&nbsp;</label>
              <input
                type="file"
                multiple
                ref={fileInputRef}
                onChange={(e) => setFiles(Array.from(e.target.files))}
              />
              <Button icon={true} type="button" onClick={handlerFileClick}>
                <Icon name="icon paperclip"></Icon>
              </Button>
            </div>
          </div>
          {files.length > 0 && (
            <div className="fields">
              <div className="file-list">
                {files.map((file, index) => (
                  <div key={index} className="file-item">
                    <i className="icon paperclip"></i>
                    <span>{file.name}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Form>
      </ModalContent>
      <ModalActions>
        <Button color="green" className={isLoading ? 'loading disabled' : ''} onClick={(e) => handleFinish(e)}>
          <Icon name="checkmark" /> FINALIZAR DEMANADA
        </Button>
        {!isLoading && (
          <Button color="red" onClick={close}>
            <Icon name="remove" /> FECHAR
          </Button>
        )}
      </ModalActions>
    </Modal>
  );
};

export default ModalCompletionDemand;
