import React, {useEffect, useState} from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Table from "../../components/Table/Table";
import api from "../../services/api/api";
import Searchbar from "../../components/Searchbar/Searchbar";
import "./DemandsPicker.css";
import Pagination from "../../components/Pagination/Pagination";
import { useNotifications } from "../../helpers/getNotifications";

const DemandsPicker = () => {
    const [sidebarIsOpen,setSidebarIsOpen] = useState(false);
    const handleSidebar = (sidebar)=>setSidebarIsOpen(sidebar);
    const [demands, setDemands] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const [maxPage, setMaxPage] = useState(1)
    const [search, setSearch] = useState("")
    const {notifications, checkNotification} = useNotifications();

    useEffect(() => {

        api.get(
            `/demands/pending-acceptance?page=${currentPage}&search=${search}`,
        )
            .then((response) => {
                setDemands(response.data.data)
                setMaxPage(response.data.last_page)
            })
            .catch((error) => {})
    }, [currentPage, search])

    const reloadDemands = () => {
        api.get(
            `/demands/pending-acceptance?page=${currentPage}&search=${search}`,
        ).then((response) => {
                setDemands(response.data.data)
                setMaxPage(response.data.last_page)
        }).catch((error) => {
            alert('Erro ao carregar demandas')
        })
    }

    const changePage = (page = 1) => {
        setCurrentPage(page)
    }

    const searchDemands = (search) => {
        let intervalToSeacrh = setInterval(() => {
            setSearch(search)
            clearInterval(intervalToSeacrh)
        }, 1500)
    }

    return (
        <div id="demands-picker">
            <Sidebar isOpen={handleSidebar} notifications={notifications} checkNotification={checkNotification}/>
            <div
                className={
                    !sidebarIsOpen
                        ? "container-width demanda-geral"
                        : "container-width-sbopen demanda-geral"
                }
            >
                <div className="searchbar">
                    <Searchbar search={searchDemands} />
                </div>
                <Table
                    isDemandPicker={true}
                    dataDemandPicker={demands}
                    pagination={
                        <Pagination
                            numberOfPages={maxPage}
                            changePage={changePage}
                        />
                    }
                    refreshDemands={reloadDemands}
                />
            </div>
        </div>
    )
}

export default DemandsPicker