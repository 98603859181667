import { DateInput } from "semantic-ui-calendar-react";
import React from "react";
import moment from 'moment';
import 'moment/locale/pt-br';

export default class InputDate extends React.Component {
  constructor(props) {
    super(props);

    this.state = { date: "" };
  }

  handleChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ date: value });
      this.props.onChange(value);
    }
  };

  render() {
    moment.locale('pt-br');
    return (
      <DateInput
        name={this.props.name || "date"}
        placeholder={this.props.placeholder || "Data"}
        value={this.state.date}
        iconPosition={this.props.iconPosition || "left"}
        onChange={this.handleChange}
        dateFormat={"DD/MM/YYYY"}
        localization={'pt'}
      />
    );
  }
}
