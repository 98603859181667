import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import "../ForgotPassword/ForgotPasswordModal.css";

const ForgotPassWordModal = (props) => {

    const {
        handleClose,
        openModal
    } = props;


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: 'none !important',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus
        >
            <Box sx={style} className="box-forgot">
                <h2>Solicitação de redefinição de senha</h2>
                <div className='div-p'>
                   <p>Olá, corujinha</p>
                <br/>
                <p>Você solicitou a redefinição de senha da sua conta na plataforma.</p>
                <br/>
                <p>Um email foi enviado para você contendo os próximos passos para redefinir sua senha</p>
                </div>
            </Box>
        </Modal>
    );
};

export default ForgotPassWordModal;