import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useNotifications } from "../../helpers/getNotifications";
import api from "../../services/api/api";
import ReactDataTables from "../../components/Datatables/ReactDataTables";
import "./DemandsMonitor.css";

const DemandsMonitor = () => {
  const handleSidebar = (sidebar) => setSidebarIsOpen(sidebar);
  const [sidebarIsOpen, setSidebarIsOpen] = useState(true);
  const { notifications, checkNotification } = useNotifications();
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedType, setSelectedType] = useState('client');
  const [years, setYears] = useState([]);
  const [tableDataClient, setTableDataClient] = useState([]);
  const [tableDataPlan, setTableDataPlan] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchDateRanges = async () => {
      try {
        const response = await api.get('/demands/date-range');
        setYearsActual(response.data.min_date, response.data.max_date);
      } catch (error) {
        console.error('Erro ao buscar ranges de data:', error);
      }
    };
    fetchDateRanges();
  }, []);

  const setYearsActual = (min, max) => {
    if (!min || !max) return [];
    const startYear = new Date(min).getFullYear();
    const endYear = new Date(max).getFullYear();
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    setYears(years);
  };
  const getCurrentMonthOptions = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    
    if (parseInt(selectedYear) === currentYear) {
      return getMonthOptions().filter(month => month.value <= currentMonth);
    }
    
    return getMonthOptions();
  };

  const getMonthOptions = () => {
    const months = [
      { value: 1, label: 'Janeiro' },
      { value: 2, label: 'Fevereiro' },
      { value: 3, label: 'Março' },
      { value: 4, label: 'Abril' },
      { value: 5, label: 'Maio' },
      { value: 6, label: 'Junho' },
      { value: 7, label: 'Julho' },
      { value: 8, label: 'Agosto' },
      { value: 9, label: 'Setembro' },
      { value: 10, label: 'Outubro' },
      { value: 11, label: 'Novembro' },
      { value: 12, label: 'Dezembro' }
    ];
    return months;
  };

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      let route = 'demands-by-client';
      if (selectedType === 'plan') {
        route = 'demands-by-plan';
      }
      const response = await api.get(`/informations/${route}?year=${selectedYear}&month=${selectedMonth}&type=${selectedType}`);
      if (selectedType === 'client') {
        setTableDataClient(response.data);
      } else {
        setTableDataPlan(response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar ranges de data:', error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setTableDataClient([]);
    setTableDataPlan([]);
  }, [selectedType, selectedYear, selectedMonth]);

  return (
    <div id="demandsMonitor">
      <div className="row">
        <div className="col s12">
          <Sidebar
            isOpen={handleSidebar}
            notifications={notifications}
            checkNotification={checkNotification}
          />
        </div>
      </div>
      <div
        className={
          !sidebarIsOpen ? "container-width" : "container-width-sbopen"
        }
      >
       <div className="ui grid" style={{ marginLeft: "30px", marginTop: "0px", marginRight: "30px" }}>
            <div className="sixteen wide column" style={{padding: "0"}}>
                <div className="ui segments">
                    <div className="ui segment">
                        <div className="ui form">
                            <div className="fields">
                                <div className="two wide field">
                                    <label>Ano</label>
                                    <select 
                                        className="ui dropdown"
                                        value={selectedYear}
                                        onChange={(e) => setSelectedYear(e.target.value)}
                                    >
                                        <option value="">Selecione o ano</option>
                                        {years.map(year => (
                                            <option key={year} value={year}>{year}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="two wide field">
                                    <label>Mês</label>
                                    <select 
                                        className="ui dropdown"
                                        value={selectedMonth}
                                        onChange={(e) => setSelectedMonth(e.target.value)}
                                    >
                                        <option value="">Selecione o mês</option>
                                        {getCurrentMonthOptions().map(month => (
                                            <option key={month.value} value={month.value}>
                                                {month.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="two wide field">
                                    <label>Por</label>
                                    <select 
                                        className="ui dropdown"
                                        value={selectedType}
                                        onChange={(e) => setSelectedType(e.target.value)}
                                    >
                                        <option value="client" selected>Cliente</option>
                                        <option value="plan">Plano</option>
                                    </select>
                                </div>
                                <div className="two wide field">
                                  <label>&nbsp;</label>
                                  <button className={`ui button red icon labeled ${isLoading ? 'loading disabled' : ''}`} onClick={handleSearch}><i className="search icon"></i> Consultar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ui segment secondary">
                      {selectedType === 'client' && (
                        <ReactDataTables
                            data={tableDataClient}
                            columnDefs={[
                            {
                                emptyPanes: '',
                                searchPanes: {
                                    show: false
                                },
                                targets: [0,1,2,3,4,5]
                            }]}
                            columns={[
                                { data: "clientName", title: "Cliente", class: "center aligned", className: "dt-center four wide" },
                                { data: "plan", title: "Plano", class: "center aligned", className: "dt-center four wide" },
                                { data: "total", title: "Qtd. Demandas", className: "two wide" },
                                { data: "totalPrice", title: "Total", className: "two wide" },
                                { data: "franchise", title: "Franquia", className: "two wide" },
                                { data: "difference", title: "Saldo", className: "two wide" }
                            ]}
                            footerCallback={function(tfoot) {
                                const api = this.api();
                                const columns = [];
                                
                                // Soma das colunas numéricas (demands, totalBnz, difference)
                                for (let i = 2; i <= 4; i++) {
                                    const value = api
                                        .column(i, { page: "current" })
                                        .data()
                                        .reduce((a, b) => a + parseFloat(b || 0), 0);
                                    columns.push(value);
                                }

                                if (tfoot) {
                                    let html = `
                                        <th colspan="2" style="text-align: right">Total:</th>
                                    `;
                                    for(const item of columns) {
                                        html += `<th class="text-right">${item}</th>`;
                                    }
                                    tfoot.innerHTML = html;
                                }
                            }}
                        />
                      )}
                      {selectedType === 'plan' && (
                        <ReactDataTables
                            data={tableDataPlan}
                            columnDefs={[
                            {
                                emptyPanes: '',
                                searchPanes: {
                                    show: false
                                },
                                targets: [0,1,2 ]
                            }]}
                            columns={[
                                { data: "planName", title: "Plano", class: "center aligned", className: "dt-center four wide" },
                                { data: "total", title: "Qtd. Demandas", className: "two wide" },
                                { data: "totalPrice", title: "Total", className: "two wide" }
                            ]}
                            footerCallback={function(tfoot) {
                                const api = this.api();
                                const columns = [];
                                
                                // Soma das colunas numéricas (demands, totalBnz, difference)
                                for (let i = 1; i <= 2; i++) {
                                    const value = api
                                        .column(i, { page: "current" })
                                        .data()
                                        .reduce((a, b) => a + parseFloat(b || 0), 0);
                                    columns.push(value);
                                }

                                if (tfoot) {
                                    let html = `
                                        <th style="text-align: right">Total:</th>
                                    `;
                                    for(const item of columns) {
                                        html += `<th class="text-right">${item}</th>`;
                                    }
                                    tfoot.innerHTML = html;
                                }
                            }}
                        />
                      )}
                    </div>
                </div>
            </div>
          </div>
      </div>
    </div>
  );
};

export default DemandsMonitor;
