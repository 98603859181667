import React from "react";
import './style.css'

const DemandCard = (props) => {

    return (
        <div className="demand-card" onClick={props.onClick} style={{cursor: "pointer"}}>
            <div className="d-flex justify-content-between demand-card-wrapper">
                <h6>{props.status}</h6>
                <div className={`icon ${props.icon}`}></div>
            </div>
            <div className="d-flex justify-content-between align-items-end demand-card-wrapper">
                <span className="number">{props.number}</span>
                <span className="link" onClick={props.openInfoDemand}>Ver detalhes</span>
            </div>
        </div>
    )
}

export default DemandCard;
