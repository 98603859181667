import React, {useState} from "react";
import { Input } from "semantic-ui-react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import api from "../../../../services/api/api";
import PrivacyPolicy from "../../../Modals/PrivacyPolicy/PrivacyPolicy";
import UserTerms from "../../../Modals/UserTerms/UserTerms";

const DadosPessoais = () => {

    const [eyePassword, setEyePassword] = useState(false);
    const [eyePasswordConfirm, setEyePasswordConfirm] = useState(false);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordCorfirm] = useState("");
    const [checkboxTS, setCheckboxTS] = useState(false);
    const [checkboxPP, setCheckboxPP] = useState(false);
    const [privacyPoliceModal,setPrivacyPoliceModal] = useState(false);
    const [userTermsModal,setUserTermsModal] = useState(false);

    const handleCheckboxChange = (e, type) => {
        if ( type === "ts") {
         setCheckboxTS( e.target.checked);
        } else if ( type === "pp") {
            setCheckboxPP(e.target.checked);
        }
        
    }

    const handleOpenPrivacyPolice = () => {
        setPrivacyPoliceModal(true)
    }

    const handleClosePrivacyPolice = () => {
        setPrivacyPoliceModal(false)
    }

    const handleOpenUserTerms = () => {
        setUserTermsModal(true)
    }

    const handleCloseUserTerms = () => {
        setUserTermsModal(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(window.confirm("Você confirma que os dados estão corretos?") === true){
            api.post('/account/register',{
                displayAs:name,
                personalEmail:email,
                professionalEmail:email,
                personalMobileNumber:phone,
                password:password,
                agreedTerms:checkboxTS,
                agreedPrivatePolicy:checkboxPP
            }).then(({data})=>{

                alert('Cadastro realizado com sucesso!');

                let linkTo = `/login/register/2/${data._id}`;

                window.location.pathname = linkTo;

            }).catch(()=>{
                alert('Erro ao cadastrar');
                //window.location.reload();
            })
        }
    };
    
    const buttonAlert = () => {
        if (!name || !email || !phone || !password || !passwordConfirm || !checkboxPP || !checkboxTS) {
            return (
                <button className="waves-effect waves-light btn-small"
                        onClick={() => alert("Preencha todos os campos corretamente!")}>
                    Avançar
                </button>
            )
        } else if (passwordConfirm !== password) {
            return (
                <button className="waves-effect waves-light btn-small"
                        onClick={() => alert("Senha e confirma senha estão diferentes!")}>
                    Avançar
                </button>
            );

        } else if (password.length < 8) {
            return (
                <button className="waves-effect waves-light btn-small"
                        onClick={() => alert("Senha deve ter no mínimo 8 caracteres.")}>
                    Avançar
                </button>
            );

        } else {
            return (
                <button className="waves-effect waves-light btn-small" onClick={(e) => handleSubmit(e)}>
                    Iniciar cadastro
                </button>
            );
        }
    };

    return (
        <form>
            <PrivacyPolicy
                handleOpen={handleOpenPrivacyPolice}
                handleClose={handleClosePrivacyPolice}
                openModal={privacyPoliceModal}
            />
            <UserTerms
                handleOpen={handleOpenUserTerms}
                handleClose={handleCloseUserTerms}
                openModal={userTermsModal}
            />
            <h3>Dados pessoais</h3>

            <div className="input-content">
                <label>Nome</label>
                <Input icon='user'  iconPosition='left' onChange={e => setName(e.target.value)} placeholder='Nome completo' name="name" id="name" type="text" className="validate"/>
            </div>
            <div className="input-content">
                <label>E-mail</label>
                <Input icon='mail' iconPosition='left' onChange={e => setEmail(e.target.value)} placeholder='email@advocacia.com' name="email" id="email" type="email" className="validate"/>
            </div>
            <div className="input-content">
                <label>Telefone</label>
                <Input icon='phone' iconPosition='left' onChange={e => setPhone(e.target.value)} placeholder='(00) 00000-0000' name="telefone" id="telefone" type="text" className="validate"/>
            </div>
            <div className="input-content">
                <label>Senha</label>
                <Input labelPosition='left' onChange={e => setPassword(e.target.value)} icon='lock' iconPosition='left'  placeholder='no minimo 8 dígitos' name="password" id="password" type={!eyePassword ? "password" : "text" } className="validate input-senha"/>
                {!eyePassword ? <FaRegEyeSlash onClick={() => setEyePassword(true)} className="icon-password"/> : <FaRegEye className="icon-password" onClick={() => setEyePassword(false)}/> }
            </div>
            <div className="input-content confirm-pw">
                <label>Confirmar senha</label>
                <Input labelPosition='left' onChange={e => setPasswordCorfirm(e.target.value)}  icon='lock' iconPosition='left' placeholder='no minimo 8 dígitos'  name="passwordConfirm" id="passwordConfirm" type={!eyePasswordConfirm ? "password" : "text" } className="validate input-senha" />
                {!eyePasswordConfirm ? <FaRegEyeSlash onClick={() => setEyePasswordConfirm(true)} className="icon-password"/> : <FaRegEye className="icon-password" onClick={() => setEyePasswordConfirm(false)}/> }
            </div>
            <div>
            <FormGroup>
                <FormControlLabel onChange={(e) => handleCheckboxChange(e, "ts")} control={<Checkbox />} label={<p>Li e aceito os <span onClick={()=>handleOpenUserTerms()} style={{textDecoration: "underline", cursor:"pointer"}}>Termos de serviço</span></p>} />
                <FormControlLabel onChange={(e) => handleCheckboxChange(e, "pp")} control={<Checkbox />} label={<p>Li e aceito a <span onClick={() => handleOpenPrivacyPolice()} style={{textDecoration: "underline", cursor:"pointer"}}>Política de Privacidade</span></p>} />
            </FormGroup>
            </div>
            <div style={{marginTop: "20px"}}>
                {buttonAlert()}
            </div>
        </form>
    );
};

export default DadosPessoais;