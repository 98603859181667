import React, {useState} from 'react';
import './LawyerNotifications.css'
import { usePagination } from "../../helpers/pagination"
import PaginationData from "../../components/Pagination/PaginationData"
import Moment from 'react-moment'

const LawyerNotifications = ( props ) => {

    const {notifications, checkNotification} = props
    const [openModal, setOpenModal] = useState(sessionStorage.getItem('checkedNotifications') === 'yes'?false:true);
    const {
        getItemsPage,
        handleActualPage,
        totalPages,
        actualPage
      } = usePagination(notifications, 10);

    const setOpenModalSession = ()=>{
        sessionStorage.setItem('checkedNotifications','yes');
        setOpenModal(false);
    }

    return (
        <div id="lawyer-notifications" className={!openModal?"closed":""}>

            <div id="lawyer-notifications-box">
                <div className="close-button">
                    <button onClick={()=>setOpenModalSession()}>&#x2715;</button>
                </div>
                <h1>Notificações</h1>

                <div className="sixteen wide column">
                    {
                        getItemsPage().length >= 1 ?
                        getItemsPage().map((notification,key) => {
                            return <div key={key} className='ui message floating small'>
                                <i cclassName="close icon" onClick={() => checkNotification(notification, false)}></i>
                                <div className='header' onClick={()=>{
                                    setOpenModalSession()
                                    checkNotification(notification)
                                }} style={{cursor: 'pointer'}}>
                                    <span className='text'><Moment format='DD/MM/YYYY H:mm:ss'>{notification.createdAt || notification.created_at}</Moment></span> - {notification.senderName} {notification.message} <span className='cliqueaqui'> Clique aqui</span>
                                </div>
                            </div>
                        }):
                            <h2 className="no-notifications">Você não possui novas notificações =(</h2>
                    }
                </div>
                <PaginationData
                    numberOfPages={totalPages}
                    changePage={handleActualPage}
                    actualPage={actualPage}
                />
            </div>
        </div>
    )
}

export default LawyerNotifications;