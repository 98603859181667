import React from "react";
import "./StatusChips.css"
import {ReactComponent as IconCanceled} from '../../assets/StatusChips/canceled.svg';
import {ReactComponent as IconConcluido} from '../../assets/StatusChips/concluido.svg';
import {ReactComponent as IconDraft} from '../../assets/StatusChips/draft.svg';
import {ReactComponent as IconPending} from '../../assets/StatusChips/pending.svg';
import {ReactComponent as IconWaitingApproval} from '../../assets/StatusChips/waiting_approval.svg';
import {ReactComponent as IconProgress} from '../../assets/StatusChips/in_progress.svg';
import {ReactComponent as IconDemandSent} from '../../assets/StatusChips/demand_sent.svg';
import {ReactComponent as IconInReview} from '../../assets/StatusChips/in_review.svg';
import {ReactComponent as IconStandby} from '../../assets/StatusChips/standby.svg';
import {ReactComponent as IconInEstimate} from '../../assets/StatusChips/in_estimate.svg';
import {ReactComponent as WaitingEstimateApproval} from '../../assets/StatusChips/waiting_estimate_approval.svg';


const StatusChips = (props) => {

    const {
        status,
    } = props;

    switch(status) {
        case 'pending':
            return <div className={`status-chip ${status}`}><IconPending style={{marginRight: "5px"}}/> Pendente</div>
        case 'in_progress':
            return <div className={`status-chip ${status}`}><IconProgress style={{marginRight: "5px"}}/> Em Progresso </div>
        case 'stand_by':
            return <div className={`status-chip ${status}`}><IconStandby style={{marginRight: "5px"}}/> Stand-by</div>
        case 'waiting_approval':
            return <div className={`status-chip ${status}`}><IconWaitingApproval style={{marginRight: "5px"}}/> Aguardando Aprovação</div>            
        case 'in_estimating':
            return <div className={`status-chip ${status}`}><IconInEstimate style={{marginRight: "5px"}}/> Aguardando Orçamento</div>                  
        case 'draft':
            return <div className={`status-chip ${status}`}><IconDraft style={{marginRight: "5px"}}/> Pendente de Informação</div> 
        case 'demand_sent':
            return <div className={`status-chip ${status}`}><IconDemandSent style={{marginRight: "5px"}}/> Demanda Enviada</div>
        case 'in_review':
            return <div className={`status-chip ${status}`}><IconInReview style={{marginRight: "5px"}}/> Aguardando Revisão</div>
        case 'canceled':
            return <div className={`status-chip ${status}`}><IconCanceled style={{marginRight: "5px"}}/> Cancelado</div>
        case 'done':
            return <div className={`status-chip ${status}`}><IconConcluido style={{marginRight: "5px"}}/> Demanda Concluída</div>            
        case 'waiting_estimate_approval':
            return <div className={`status-chip ${status}`}><WaitingEstimateApproval style={{marginRight: "5px"}}/> Aguardando Aprovação do Orçamento</div>
        case 'waiting_lawyer_acceptance':
            return <div className={`status-chip ${status}`}><WaitingEstimateApproval style={{marginRight: "5px"}}/> Aguardando Aceite do Advogado</div>
        // case "in_auction":
        //     return <div className="status-chip">Em Leilão</div>
        // case "transfered_lawyer":
        //     return <div className="status-chip">Transferência de Advogado</div>            
        // case "without_history":
        //     return <div className="status-chip">Sem Status / Status não Registrado</div>            
        default:
            return <div className={`status-chip concluido`}><IconConcluido style={{marginRight: "5px"}}/> Demanda Concluída</div>
    }
}

export default StatusChips;