import React, { useState } from "react";
import { ModalContent, ModalActions, Header, Modal, Form, Button, Popup, Icon } from "semantic-ui-react";
import "./ModalDocumentRequest.css";
import api from "../../services/api/api";
import uploadFiles from "../../services/api/uploadS3";
import cuate from "../../assets/cuate.png"

const ModalDocumentRequest = (props) => {
  const documentDefault = {
    _id: null,
    description: "",
    requestFiles: null,
    uploaded: false,
    errors: null,
    loading: false,
  };
  const { openModal, demandDetails, handleClose } = props;
  const [documents, setDocuments] = useState([documentDefault]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);
  const [debitBNZ, setDebitBNZ] = useState(false);
  const [valueBNZ, setValueBNZ] = useState(0);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const nameOnly = name.replace(/[^a-zA-Z]/g, '');
    const updatedDocuments = documents.map((doc, docIndex) => {
      if (index === docIndex) {
        return {
          ...doc,
          [nameOnly]: value,
        };
      }
      return doc;
    });
    setDocuments(updatedDocuments);
  };

  const handleFileChange = (index, event) => {
    const file = event.target.files[0];
    const updatedDocuments = documents.map((doc, docIndex) => {
      if (index === docIndex) {
        return {
          ...doc,
          requestFiles: file,
        };
      }
      return doc;
    });
    setDocuments(updatedDocuments);
  };

  const addDocument = () => {
    setDocuments([...documents, documentDefault]);
  };

  const removeDocument = (index) => {
    setDocuments(documents.filter((_, docIndex) => docIndex !== index));
    const erros = errors;
    erros[`documents.${index}.description`] = null;
    erros[`documents.${index}.valueBNZ`] = null;
    erros[`documents.${index}.requestFiles`] = null;
    setErrors(erros);
  };

  const close = () => {
    const updateStatus = success;
    setDocuments([documentDefault]);
    setSuccess(false);
    setErrors([]);
    setDebitBNZ(false);
    setValueBNZ(0);
    handleClose(updateStatus);
  };

  const handleFileClick = (index) => {
    const input = document.getElementById(`file[${index}]`);
    input.click();
  }

  const finish = async () => {
    setErrors([]);
    setIsLoading(true);
    const documents_uploaded = await sendFiles(documents);
    setDocuments(documents_uploaded);
    if (!errors.length) {
      const request = await createDocuments(documents_uploaded, debitBNZ, valueBNZ);
      if (request) {
        setDocuments(request);
        setSuccess(true);
      }
    }
    setIsLoading(false);
  }

  const sendFiles = async (documents) => {
    let index = 0;
    for (const document of documents) {
      if (document.requestFiles && !document.uploaded) {
        try {
          const upload = await uploadFiles([document.requestFiles], `documents/${demandDetails._id}/{userId}/`);
          document.file = upload[0];
          document.uploaded = true;
        } catch (error) {
          const erros = errors;
          erros.push({ 
            [`documents.${index}.requestFiles`]: [error]
          });
          setErrors(erros);
        }
      }
      index++;
    }
    return documents;
  }

  const createDocuments = async (documents) => {
    try {
      const request = await api.post(`/demands/${demandDetails._id}/request`, {
        documents,
        debitBNZ,
        valueBNZ
      });
      return request.data.documents;
    } catch (error) {
      setErrors(error.response.data.errors);
      return false;
    }
  }

  const handleDebitBNZ = (e) => {
    setDebitBNZ(e.target.checked);
  }

  const handleValueBNZ = (e) => {
    if ((/^\d*$/.test(e.target.value))) {
      setValueBNZ(e.target.value);
    }
  }

  return (
    <Modal
      closeIcon={!isLoading}
      centered={true}
      open={openModal}
      onClose={close}
      closable={false}
      size={!success ? "large" : 'mini'}
      closeOnDimmerClick={false}
    >
      {!success && (
        <Header icon="file" content="Solicitar Documento" />
      )}
      {!success ? (
        <>
          <ModalContent style={{maxHeight: '600px', overflow: 'auto', minHeight: '170px'}} className="modalDocumentRequest">
            <Form size="small" action="#" loading={isLoading}>
              <div className="ui grid columns">
                <div className={`thirteen wide column divider-vertical-modal-document`}>
                  {documents.map((document, index) => (
                    <>
                      <div className="fields" key={index}>
                        <div className={`ten wide field ${errors[`documents.${index}.description`] ? 'error' : ''}`}>
                          <label htmlFor="description">Documento</label>
                          <input
                            type="text"
                            name={`description[${index}]`}
                            id={`description[${index}]`}
                            value={document.description}
                            onChange={(e) => handleInputChange(index, e)}
                          />
                          {errors[`documents.${index}.description`] && (
                            <div class="ui pointing red basic label small">
                              {errors[`documents.${index}.description`].join(' | ')}
                            </div>
                          )}
                        </div>
                        <div className="four wide field">
                          <label>&nbsp;</label>
                          <Button type="button" size="small" fluid className="full-height-button" onClick={() => handleFileClick(index)}>
                            <Icon name="cloud upload" /> {document.requestFiles ? `Arquivo: ${document.requestFiles.name}` : 'Anexar Modelo'}
                          </Button>
                          <input type="file" name={`file[${index}]`} id={`file[${index}]`} onChange={(e) => handleFileChange(index, e)} />
                        </div>
                        <div className="one wide field">
                          {index + 1 === documents.length && (
                            <>
                              <label>&nbsp;</label>
                              <Popup content='Adicionar Documento' inverted size="small"  trigger={<Button type="button" fluid color="blue" circular compact  icon="plus" onClick={addDocument} />} />
                            </>
                          )}
                        </div>
                        <div className="one wide field">
                          {index + 1 === documents.length && index > 0 && (
                            <>
                              <label>&nbsp;</label>
                              <Popup content='Remover Documento' inverted size="small"  trigger={<Button type="button" fluid color="red" circular compact  icon="close" onClick={() => removeDocument(index)}/>} />
                            </>
                          )}
                        </div>
                      </div>
                      {documents.length > 1 && (
                        <div className="divider-modal-document"></div>
                      )}
                    </>
                  ))}
                </div>
                <div className="three wide column" style={{position: 'absolute', right: 0}}>
                  <div className="fields">
                    <div className="sixteen wide field">
                      <div className="ui checkbox toggle">
                        <input
                          type="checkbox"
                          name="debitBNZ"
                          id="debitBNZ"
                          checked={debitBNZ}
                          onChange={handleDebitBNZ}
                          value="S"
                        />
                        <label htmlFor="debitBNZ">Utilizar BNZs</label>
                      </div>
                    </div>
                  </div>
                  {debitBNZ && (
                    <div className="fields">
                      <div className={`sixteen wide field ${errors[`valueBNZ`] ? 'error' : ''}`}>
                        <label htmlFor="valueBNZ">BNZs</label>
                        <input
                          type="text"
                          name="valueBNZ"
                          id="valueBNZ"
                          value={valueBNZ}
                          onChange={handleValueBNZ}
                        />
                        {errors[`valueBNZ`] && (
                          <div class="ui pointing red basic label small">
                            {errors[`valueBNZ`].join(' | ')}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Form>
          </ModalContent>
          <ModalActions>
            <Button type="button" onClick={close} loading={isLoading} disabled={isLoading}>
              <Icon name="close" /> Fechar
            </Button>
            <Button type="button" color="violet" loading={isLoading} disabled={isLoading} onClick={() => finish()}>
              <Icon name="check" /> Confirmar
            </Button>
          </ModalActions>
        </>
      ) : 
        (
          <>
            <ModalContent style={{minHeight: '200px', overflow: 'auto', textAlign: 'center', padding: '50px'}}>
              <span style={{fontSize: '24px', fontWeight: 'bold'}}>Documento Solicitado</span>
              <div className="ui centered medium image"><img src={cuate} alt="sucesso" style={{margin: 'auto'}} /></div>
            </ModalContent>
            <ModalActions>
            <Button type="button" onClick={close} >
              <Icon name="close" /> Fechar
            </Button>
          </ModalActions>
          </>
        )
      }
    </Modal>
  );
};

export default ModalDocumentRequest;
