import React, {useEffect, useState} from 'react'
import Sidebar from "../../components/Sidebar/Sidebar";
import ClientsContainer from "../../components/DataPrivacy/ClientsContainer/ClientsContainer";
import './style.css'
import ActivitiesStatusBar from "../../components/DataPrivacy/ActivitiesStatusBar/ActivitiesStatusBar";
import DemandsStatusGeneral from "../../components/DataPrivacy/DemandsStatusGeneral/DemandsStatusGeneral";
import api from "../../services/api/api";
import UpcomingEvents from "../../components/DataPrivacy/UpcomingEvents/UpcomingEvents";
import { useNotifications } from "../../helpers/getNotifications";

const DataPrivacy = () => {
    const [sidebarIsOpen,setSidebarIsOpen] = useState(false);
    const {notifications, checkNotification} = useNotifications();
    const handleSidebar = (sidebar)=>setSidebarIsOpen(sidebar);

    const [ activities, setActivities ] = useState();

    useEffect(() => {
        api.get('data-privacy/all').then((response)=>{
           setActivities(response.data.data)
        }).catch(()=>alert('erro ao carregar atividades'))
    }, []);


    return (
        <div id="data-privacy">
            <Sidebar isOpen={handleSidebar} notifications={notifications} checkNotification={checkNotification}/>
                <div className={
                    !sidebarIsOpen
                        ? "container-width "
                        : "container-width-sbopen"
                }>
                    <div id="data-privacy-container">
                        {/*Start of the page content*/}
                            <div className='row'>
                                <div className="col s12 m12 l6 xl6">
                                    <ActivitiesStatusBar activities={activities}/>
                                </div>
                                <div className="col s12 m12 l6 xl6">
                                    <DemandsStatusGeneral activities={activities}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 m12 l6 xl6">
                                    <ClientsContainer/>
                                </div>
                                <div className="col s12 m12 l6 xl6">
                                    <UpcomingEvents activities={activities}/>
                                </div>
                            </div>
                    </div>
                </div>
        </div>

    )
}

export default DataPrivacy
