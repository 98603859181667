import React from "react";
import {FaAngleLeft, FaAngleRight} from "react-icons/fa";
import { Table } from 'semantic-ui-react';

const Pagination = (props) => {

    const numberOfPagesCounter = props.numberOfPages
    const actualPage = props.actualPage
    let numberOfPages = [];
    for (let i = 1; i <= numberOfPagesCounter; i++){
        numberOfPages.push(<div onClick={() => props.changePage(i)} className={`${actualPage === i ? 'selected' : ''}`}> {i} </div>)
    }

    return (
        <Table.Row>
                <Table.HeaderCell colSpan='6'>
                    <div className="pagination">
                        <FaAngleLeft/>
                            {numberOfPages}
                        <FaAngleRight />
                    </div>
                </Table.HeaderCell>
            </Table.Row>
    )
}

export default Pagination
