import React, {useState} from "react";
import './ChatButton.css'
import sendMessage from '../../../assets/send-message.svg'
import ChatServiceModal from "../../Modals/ChatService/ChatServiceModal";

const ChatButton = (props) => {
    const [chatServiceInfo, setChatServiceInfo] = useState({})
    const [openChatServiceModal, setOpenChatServiceModal] = useState(false)

    const handleChatServiceModal = (data) => {
        if (data !== undefined) {
            setChatServiceInfo(data)
            setOpenChatServiceModal(!openChatServiceModal)
        }
    }
    return (
        <>
            <div id="dataprivacy-chat-button">
                <button onClick={()=>setOpenChatServiceModal(true)}>
                    <img src={sendMessage} alt=""/>
                    Mandar Mensagem
                </button>
            </div>
            <ChatServiceModal
                openModal={openChatServiceModal}
                handleClose={handleChatServiceModal}
                chatInfo={chatServiceInfo}
                demandId={""}
                clientId={props.clientId}
                type={'data-privacy'}
            />
        </>

    )
}

export default ChatButton