import React, {useEffect, useState} from "react";
import "./ClientsContainer.css"
import api from "../../../services/api/api";
import {Link} from "react-router-dom";

const ClientsContainer = (props) => {
    const [clients, setClients] = useState({})


    useEffect(() => {
        api.get(`/clients/clients-data-privacy`)
            .then((response) => {
                setClients(response.data)
                // setMaxPage(response.data.last_page)
            })
            .catch((error) => {
                alert('Erro ao carregar os clientes')
            })
    }, [])

    function formatPlan(plan){
        if(plan !== undefined){
            return plan.replaceAll('-',' ').toUpperCase()
        }else{
            return 'PLANO NÃO IDENTIFICADO';
        }
    }

    return (
        <div id="clients-container">
            <thead id="clients-container-header">
                <th>Empresa</th>
                <th>Plano</th>
            </thead>
            <tbody id="clients-container-body">
            {clients && clients.length > 0
                ? clients.map((client, key) => {
                    return (
                        <tr key={key}>
                            <Link to={`/data-privacy/client/${client[0].clientId}`}>
                                <td>
                                    {client[0].client_name}
                                </td>
                                <td>
                                    {formatPlan(client[0].planIdentifier)}
                                </td>
                            </Link>
                        </tr>
                    )
                }):(<h2>Sem clientes</h2>)
            }

            </tbody>
            {/*<tfoot id="data-privacy-clients-container-footer">*/}
            {/*    <Pagination*/}
            {/*        numberOfPages={maxPage}*/}
            {/*        changePage={changePage}*/}
            {/*    />*/}
            {/*</tfoot>*/}
        </div>
    )
}

export default ClientsContainer