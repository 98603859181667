import {Col, Row} from "react-bootstrap";

const InfoCard = (props) => {
    const client = props.client
    const plan = props.plan

    return (
        <div className="card">
            <header>
                <div>
                    <h4>Dados da empresa</h4>
                </div>
            </header>
            <div className="content">
                <Row>
                    <Col sx={4}>
                        <div>
                            <p className="little">CNPJ</p>
                            <p>{client.cnpj || '-'}</p>
                        </div>
                        <div>
                            <p className="little">Email corporativo</p>
                            <p>{client.email || '-'}</p>
                        </div>
                    </Col>
                    <Col sx={4}>
                        <div>
                            <p className="little">Quantidade de funcionários</p>
                            <p>17</p>
                        </div>
                        <div>
                            <p className="little">Telefone</p>
                            <p>{client.businessTelephoneNumber || '-'}</p>
                        </div>
                    </Col>
                    <Col sx={4}>
                        <div style={{marginTop: '58px'}}>
                            <p className="little">Plano</p>
                            <p>{plan || '-'}</p>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default InfoCard