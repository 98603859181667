import React, {useEffect, useState} from 'react';
import Sidebar from "../../components/Sidebar/Sidebar";
import ClientsDetailsContainer from "../../components/DataPrivacy/ClientsDetailsContainer/ClientsDetailsContainer";
import api from "../../services/api/api";
import SendDocButton from "../../components/DataPrivacy/SendDocButton/SendDocButton";
import ChatButton from "../../components/DataPrivacy/ChatButton/ChatButton";
import './DataPrivacyClient.css'
import ScheduleActivities from "../../components/DataPrivacy/ScheduleActivities/ScheduleActivities";
import SendedDocs from "../../components/DataPrivacy/SendedDocs/SendedDocs";
import { useNotifications } from "../../helpers/getNotifications";

const DataPrivacyClient = (props) => {

    const [sidebarIsOpen,setSidebarIsOpen] = useState(false);
    const handleSidebar = (sidebar)=>setSidebarIsOpen(sidebar);
    const [client,setClient] = useState({});
    const {notifications, checkNotification} = useNotifications();


    const clientId = props.match.params.clientId;

    useEffect(() => {
        api.get(`/clients/data-privacy/${clientId}`)
            .then((response) => {
                setClient(response.data)
            })
            .catch((error) => {})
    }, [clientId])


    return (
        <div id="data-privacy">
            <Sidebar isOpen={handleSidebar} notifications={notifications} checkNotification={checkNotification}/>
            <div className="row">
                <div className={
                    !sidebarIsOpen
                        ? "container-width"
                        : "container-width-sbopen"
                }>
                    <div className="col s4">
                        <ClientsDetailsContainer clientData={client}/>
                    </div>
                    <div className="data-privacy-client-details-buttons-wrapper">                    
                        <SendDocButton clientId={clientId} />
                        <ChatButton clientId={clientId}/>
                        <SendedDocs clientId={clientId}/>
                    </div>  
                </div>
            </div>
            <div className="row">
                <div  className={
                    !sidebarIsOpen
                        ? "container-width"
                        : "container-width-sbopen"
                }>
                    <ScheduleActivities clientId={clientId}/>
                </div>
            </div>
        </div>
    )
}

export default DataPrivacyClient