import React  from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import "../FinishRegister/FinishRegisterModal.css";
import Coruja from "../../../assets/advcoruja-modal.png"
import { FaTimes } from "react-icons/fa"

const FinishRegisterModal = (props) => {

    const {
        handleClose,
        openModal
    } = props;

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: 'none !important',
        boxShadow: 24,
        p: 4,
      };


    return (
        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus
        >
            <Box sx={style} className="box">
                <FaTimes className="x-icon" onClick={handleClose}/>
                <h2>Obrigado por cadastrar-se como <strong>Coruja na B.onuz.</strong></h2>
                <div><img src={Coruja} alt="Bonuz owl"/></div>
                <p>Seu cadastro será analisado e voce receberá os próximos passos por e-mail...</p>
            </Box>
        </Modal>

    );
};

export default FinishRegisterModal;