import React, { useState, useEffect, useCallback } from "react"
import "./style.css"
import StatusChips from "../../components/StatusChips/StatusChips"
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa"
import TableModal from "../../components/TableModal/TableModal"
import api from "../../services/api/api"
import { Loader, Button } from "semantic-ui-react"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import { formatedDate } from "../../helpers/Date/formatedDate"
import ChatServiceModal from "../../components/Modals/ChatService/ChatServiceModal"
import ConclusionDemand from "../../components/Modals/ConclusionDemand/ConclusionDemand"
import { ReactComponent as MessageIcon } from "../../assets/messageIcon.svg"
import { api_url } from "../../config/environment/environmentSettings"
import { ReactComponent as FileAnexoIcon } from "../../assets/fileAnexoIcon.svg"
import {getToken} from "../../services/authentication/authentication";

const DemandInfoView = (props) => {
    const {
        info,
        isMyDemand = false,
        isDemandPicker = false,
        isDemandAuction = false,
        openLanceModal,
        reloadDemands,
    } = props

    const [demand, setDemand] = useState({ message: "empty" })
    const [showServiceDetails, setShowServiceDetails] = useState(false)
    const [acaoSelectValue, setAcaoSelectValue] = useState("")
    const [openJustificativa, setOpenJustificativa] = useState(false)
    const [standByJustificativa, setStandByJustificativa] = useState("")
    const [comentarioDemand, setComantarioDemand] = useState("")
    const [chatServiceInfo, setChatServiceInfo] = useState({})
    const [openChatServiceModal, setOpenChatServiceModal] = useState(false)
    const [openConclusionDemandModal, setOpenConclusionDemandModal] =
        useState(false)

    const fetchDemandaInfo = useCallback((id) => {
        let url = `/demands/show/${id}`

        if (isDemandPicker) {
            url = `/demands/pending-acceptance/show/${id}`
        } else if (isDemandAuction) {
            url = `/demands/show-in-auction/${id}`
        }

        if (id !== undefined) {
            api.get(url)
                .then((response) => {
                    setDemand(response.data)
                })
                .catch((error) => {
                    //console.log({ errIn: url, message: error.message })
                })
        }
    }, [isDemandAuction, isDemandPicker])

    useEffect(() => {
        if (acaoSelectValue !== "") {
            setAcaoSelectValue(info.status)
        }

        fetchDemandaInfo(info?._id)
        setOpenJustificativa(false)
    }, [info, isDemandAuction, isDemandPicker, acaoSelectValue, fetchDemandaInfo])

    useEffect(() => {
        const fetchInictialComment = (array) => {
            if (array && array.length > 0) {
                const myArray = array
                setComantarioDemand(myArray[myArray.length - 1].comment)
            }
        }

        fetchInictialComment(demand?.demand_interactions)
    }, [demand])

    const handleChatServiceModal = (data) => {
        if (data !== undefined) {
            setChatServiceInfo(data)
            setOpenChatServiceModal(!openChatServiceModal)
        }
    }

    const handleChangeAcaoSelect = (value) => {
        setAcaoSelectValue(value)

        if (value === "stand_by") {
            setOpenJustificativa(true)
        } else {
            handleJustifcativaBaloon(false, true)
        }
    }

    const handleModalData = () => {
        setDemand({ message: "empty" })
        setComantarioDemand("")
    }

    const handleConclusionDemand = () => {
        setOpenConclusionDemandModal(!openConclusionDemandModal)
    }

    const handleStandByJustificativa = (value) => {
        setStandByJustificativa(value)
    }

    const handleJustifcativaBaloon = (comment, in_progress = false) => {
        if (comment) {
            api.post(`/demands/change-status/${info?._id}`, {
                status: acaoSelectValue,
                comment: standByJustificativa,
            }).then(() => {
                reloadDemands()
                fetchDemandaInfo(info?._id)
                setOpenJustificativa(false)
            })
        } else if (!comment && in_progress) {
            api.post(`/demands/change-status/${info?._id}`, {
                status: "in_progress",
            }).then(() => {
                reloadDemands()
                fetchDemandaInfo(info?._id)
                setOpenJustificativa(false)
            })
        }
    }

    const changeModal = () => {
        handleModalData()
        openLanceModal(demand?._id)
    }

    return (
        <>
            <ChatServiceModal
                openModal={openChatServiceModal}
                handleClose={handleChatServiceModal}
                chatInfo={chatServiceInfo}
                demandId={demand?._id}
            />
            <ConclusionDemand
                openModal={openConclusionDemandModal}
                handleClose={handleConclusionDemand}
                demandId={demand?._id}
            />
            <div className="info-demand-view">
                {demand.message === "empty" && comentarioDemand === "" ? (
                    <div className="loader">
                        <Loader
                            active
                            inline
                            size="massive"
                            style={{ margin: "0 auto" }}
                        />
                    </div>
                ) : (
                    <div className="info-container">
                        <div className="title">
                            <div>
                                <h2>
                                    {`#000${demand?.code}`} -{" "}
                                    {demand?.fullDescription}
                                </h2>
                            </div>
                            <div className="acao">
                                <div className="inform">
                                    <span>Ações2</span>
                                </div>
                                {(isMyDemand && demand?.status === "in_progress") 
                                || demand?.status === "stand_by" 
                                || (isMyDemand && demand?.status === "pending")
                                || (isMyDemand && demand?.status === "waiting_lawyer_acceptance") 
                                || (isMyDemand && demand?.status === "in_review") ? 
                                (
                                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                                        <Select
                                            value={acaoSelectValue}
                                            onChange={(e) =>
                                                handleChangeAcaoSelect(
                                                    e.target.value,
                                                )
                                            }
                                            className="select-table"
                                            displayEmpty
                                            inputProps={{
                                                "aria-label": "Without label",
                                            }}
                                            renderValue={(selected) => {
                                                if (selected.length === 0) {
                                                    return (
                                                        <StatusChips
                                                            status={
                                                                demand?.status
                                                            }
                                                        />
                                                    )
                                                } else {
                                                    return (
                                                        <StatusChips
                                                            status={
                                                                acaoSelectValue
                                                            }
                                                        />
                                                    )
                                                }
                                            }}
                                        >
                                            {["in_progress", "stand_by"].map(
                                                (menuItem) => {
                                                    if (
                                                        menuItem !==
                                                        demand?.status
                                                    ) {
                                                        return (
                                                            <MenuItem
                                                                value={menuItem}
                                                            >
                                                                <StatusChips
                                                                    status={
                                                                        menuItem
                                                                    }
                                                                />
                                                            </MenuItem>
                                                        )
                                                    }
                                                    return null;
                                                },
                                            )}
                                        </Select>
                                    </FormControl>
                                ) : (
                                    <StatusChips status={demand?.status} />
                                )}
                                <div
                                    className={
                                        openJustificativa
                                            ? "justificativa"
                                            : "justificativa d-none"
                                    }
                                >
                                    <p>
                                        Esta demanda está em stand-by, por favor
                                        informe uma justificativa.
                                    </p>
                                    <textarea
                                        placeholder="Mensagem"
                                        onChange={(e) =>
                                            handleStandByJustificativa(
                                                e.target.value,
                                            )
                                        }
                                    ></textarea>
                                    <button
                                        onClick={() =>
                                            handleJustifcativaBaloon(true)
                                        }
                                        disabled={!standByJustificativa}
                                    >
                                        OK
                                    </button>
                                </div>
                            </div>
                        </div>
                        {isDemandAuction || demand?.isAuction || isMyDemand ? (
                            <div
                                className="table-container"
                                style={{ display: "block" }}
                            >
                                <div className="scroll">
                                    <TableModal
                                        isClientTable
                                        handleChat={handleChatServiceModal}
                                        client={demand?.client_info}
                                        demandas={demand}
                                        isMyDemand={isMyDemand}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="data">
                                <div className="inform">
                                    <span>Data de entrega</span>
                                </div>
                                <strong>
                                    {formatedDate(demand?.finalDate)}
                                </strong>
                            </div>
                        )}
                        <div className="obs">
                            <div className="inform">
                                <span>Obervação/Comentário</span>
                            </div>
                            <p>
                                {comentarioDemand === ""
                                    ? "Não existe observações/comentários"
                                    : comentarioDemand}
                            </p>
                        </div>

                        <div className="data">
                            <div className="inform">
                                <span>Detalhes do serviço</span>
                            </div>
                            <span
                                className="service-detail"
                                onClick={() =>
                                    setShowServiceDetails(!showServiceDetails)
                                }
                            >
                                Detalhes do serviço{" "}
                                {showServiceDetails ? (
                                    <FaRegEye />
                                ) : (
                                    <FaRegEyeSlash />
                                )}
                            </span>
                        </div>
                        {showServiceDetails ? (
                            <div className="obs">
                                <div className="inform">
                                    <span>
                                        Descreva que tipo de trabalho gostaria
                                        de ver realizado.
                                    </span>
                                </div>
                                <div className="anwser">
                                    {demand?.demand_anwsers
                                        ? demand?.demand_anwsers.map(
                                              (anwser) => {
                                                  if (
                                                      anwser.question &&
                                                      anwser.anwser
                                                  ) {
                                                      return (
                                                          <div>
                                                              <p>
                                                                  <strong>
                                                                      {
                                                                          anwser.question
                                                                      }
                                                                  </strong>
                                                              </p>
                                                              <p>
                                                                  <small>
                                                                      Resposta:{" "}
                                                                  </small>
                                                                  {
                                                                      anwser.anwser
                                                                  }
                                                              </p>
                                                              {anwser.documents.map(
                                                                  (
                                                                      document,
                                                                      counter,
                                                                  ) => {
                                                                      return (
                                                                          <a
                                                                              className="anexo-file"
                                                                              target="_blank"
                                                                              href={`${api_url}/${document}?token=${getToken()}`}
                                                                              rel="noreferrer"
                                                                              download
                                                                          >
                                                                              Anexo{" "}
                                                                              {
                                                                                  ++counter
                                                                              }{" "}
                                                                              <FileAnexoIcon />{" "}
                                                                              &nbsp;
                                                                          </a>
                                                                      )
                                                                  },
                                                              )}
                                                              <br />
                                                          </div>
                                                      )
                                                  }
                                                  return null;
                                              },
                                          )
                                        : "Sem detalhes"}
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                        <div className="table-history">
                            {isMyDemand ? (
                                <div className="table-container">
                                    <div
                                        style={{
                                            width: "50px",
                                            paddingTop: "10px",
                                        }}
                                    >
                                        <span>Histórico</span>
                                    </div>
                                    <div className="scroll">
                                        <TableModal
                                            isHistoryDemand
                                            isAuctionDemand={demand?.isAuction}
                                            handleChat={handleChatServiceModal}
                                            demandSubService={
                                                demand?.subserivceName
                                            }
                                            demandas={demand}
                                            setComantarioDemand={
                                                setComantarioDemand
                                            }
                                        />
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}

                            {isDemandAuction ? (
                                <div className="service-chat">
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                        onClick={() =>
                                            handleChatServiceModal(demand)
                                        }
                                    >
                                        <MessageIcon />
                                        <div>
                                            <span>Chat do serviço</span>
                                        </div>
                                    </div>
                                    <div>
                                        <button
                                            className="btn-red"
                                            style={{
                                                width: "94px",
                                                height: "32px",
                                            }}
                                            onClick={() => changeModal()}
                                        >
                                            Dar lance
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className="valor">
                                    {isDemandAuction ? (
                                        <button
                                            className="btn-green"
                                            style={{
                                                width: "94px",
                                                height: "32px",
                                            }}
                                            onClick={() => changeModal()}
                                        >
                                            Auction
                                        </button>
                                    ) : (
                                        <div>
                                            <div className="inform">
                                                {/*<span>Valor</span>*/}
                                            </div>
                                            {/*{*/}
                                            {/*    demand?.status !== 'in_estimating'*/}
                                            {/*    ?*/}
                                            {/*        <strong>{`BNZ ${demand?.price},00`}</strong>*/}
                                            {/*    :*/}
                                            {/*        <h6>Valor em orçamento</h6>*/}
                                            {/*}*/}
                                        </div>
                                    )}
                                </div>
                            )}

                            {demand?.status === "in_progress" ? (
                                <Button
                                    style={{ marginTop: "20px" }}
                                    onClick={() => handleConclusionDemand()}
                                    color="green"
                                >
                                    Concluir
                                </Button>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                )}
                )
            </div>
        </>
    )
}

export default DemandInfoView
