import React, { useState } from "react"
import Sidebar from "../../components/Sidebar/Sidebar"
import './PlatinumPlan.css'
import ClientsPlans from "../../components/Plans/Clients/ClientsPlans";
import { useNotifications } from "../../helpers/getNotifications";

const PlatinumPlan = () => {
    const [sidebarIsOpen,setSidebarIsOpen] = useState(false);
    const handleSidebar = (sidebar)=>setSidebarIsOpen(sidebar);
    const {notifications, checkNotification} = useNotifications();
    
    return (
        <div className="platinum-plan">
            <Sidebar isOpen={handleSidebar} notifications={notifications} checkNotification={checkNotification}/>
            <div className="row">
                <div>
                    <div className={
                        !sidebarIsOpen
                            ? "container-width p-2"
                            : "container-width-sbopen"
                    }>
                        <main>
                            <div className= 'row '>
                                <div className="row_tableP">
                                        <ClientsPlans plan={'platinum'}/>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlatinumPlan