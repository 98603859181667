import React from "react";
import "./Hints.css"
import { ReactComponent as Info } from "../../assets/nav/Info.svg"

const Hints = (props) => {
    return (
      <div className="container-hints">
        <div className="inner-container-hints">
          <Info />
          <div className="spacer-hints"></div>
          <p><b>{props.hintText}</b><br/>
          {props.hintInfo}</p>
        </div>
      </div>
    )
}

export default Hints;