import React from 'react'
import './ClientsDetailsContainer.css'

const ClientsDetailsContainer = (props) => {

    function formatPlan(plan){
        if(plan !== undefined){
            return plan.replaceAll('-',' ').toUpperCase()
        }else{
            return 'PLANO NÃO IDENTIFICADO';
        }
    }

    return (
        <div id="clients-detail-container">
            <div id="clients-detail-container-header">
                <h2>Dados da empresa</h2>
            </div>
            <div id="clients-detail-container-body">
                <div className="row cards-dp-info">
                    <div className="col" style={{margin:'0', padding:'0 20px 0 0'}}>
                        <div className="row" style={{margin:'0', padding:'0 20px 0 0'}}>
                            <div className="col" style={{margin:'0', padding:'0 20px 0 0'}}>
                                <span className="client-detail-container-title">CNPJ</span>
                            </div>
                        </div>
                        <div className="row" style={{margin:'0', padding:'0 20px 0 0'}}>
                            <span className="client-detail-container-body">{props?.clientData?.cnpj}</span>
                        </div>
                    </div>
                    <div className="col" style={{margin:'0', padding:'0 20px 0 0'}}>
                        <div className="row" style={{margin:'0', padding:'0 20px 0 0'}}>
                            <span className="client-detail-container-title">Quantidade de funcionário</span>
                        </div>
                        <div className="row" style={{margin:'0', padding:'0 20px 0 0'}}>
                            <span className="client-detail-container-body">{props?.clientData?.businessEmployees}</span>
                        </div>
                    </div>
                </div>
                <div className="row cards-dp-info">
                    <div className="col" style={{margin:'0', padding:'0 20px 0 0'}}>
                        <div className="row" style={{margin:'0', padding:'0 20px 0 0'}}>
                            <span className="client-detail-container-title">E-mail corporativo</span>
                        </div>
                        <div className="row" style={{margin:'0', padding:'0 20px 0 0'}}>
                            <div className="col" style={{margin:'0', padding:'0 20px 0 0'}}>{props?.clientData?.businessEmail}</div>
                        </div>
                    </div>
                    <div className="col" style={{margin:'0', padding:'0 20px 0 0'}}>
                        <div className="row" style={{margin:'0', padding:'0 20px 0 0'}}>
                            <span className="client-detail-container-title">Telefone</span>
                        </div>
                        <div className="row" style={{margin:'0', padding:'0 20px 0 0'}}>
                            <div className="col" style={{margin:'0', padding:'0 20px 0 0'}}>{props?.clientData?.businessTelephoneNumber}</div>
                        </div>
                    </div>
                    <div className="col" style={{margin:'0', padding:'0 20px 0 0'}}>
                        <div className="row" style={{margin:'0', padding:'0 20px 0 0'}}>
                            <span className="client-detail-container-title">Plano</span>
                        </div>
                        <div className="row" style={{margin:'0', padding:'0 20px 0 0'}}>
                            <div className="col" style={{margin:'0', padding:'0 20px 0 0'}}>{formatPlan(props?.clientData?.subscription?.planIdentifier)}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ClientsDetailsContainer;