import {Switch, Route,} from "react-router-dom";
import Default from "../components/LoginForm/Partials/Default";
import DadosPessoais from "../components/LoginForm/Partials/Cadastro/DadosPessoais";
import DadosAdvogado from "../components/LoginForm/Partials/Cadastro/DadosAdvogado";
import ForgotPassWord from "../components/LoginForm/Partials/ForgotPassword";
import ResetPassword from "../components/LoginForm/Partials/ResetPassword";


const LoginRouter = () => {
    return (
        <Switch>
            <Route path="/login/forgotPassword" exact component={ForgotPassWord} />
            <Route path="/login/forgotPassword/:token" exact component={ResetPassword} />
            <Route path="/login/register/1"  exact component={DadosPessoais} />
            <Route path="/login/register/2/:userId" exact component={DadosAdvogado} />
            <Route path="/login" exact component={Default} />
        </Switch>
    );
};

export default LoginRouter;