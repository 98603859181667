import React, { useState } from "react";
import {
  ModalContent,
  ModalActions,
  Button,
  Header,
  Icon,
  Modal,
  Form,
} from "semantic-ui-react";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import "alertifyjs/build/css/themes/default.css";

const ModalTextArea = (props) => {
  const { openModal, handleClose, handleOk } = props;
  const [comment, setComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setComment("");
    setIsLoading(false);
    handleClose();
  };

  const handleFinish = (e) => {
    e.preventDefault();
    if (comment === '') {
        alertify.notify("O motivo é obrigatório.", "error", 5);
        return false;
    }
    try {
        handleOk(comment);
        handleClose();
    } catch {
    }
  }

  return (
    <Modal
      closeIcon={!isLoading}
      centered={true}
      open={openModal}
      onClose={close}
      closable={false}
      size="small"
      closeOnDimmerClick={false}
    >
      <Header icon="remove circle" content={props.title} />
      <ModalContent>
        <Form action="#">
          <div className="fields">
            <div className="sixteen wide field">
              <label for="comment">Motivo</label>
              <textarea
                name="comment"
                id="comment"
                onChange={(e) => setComment(e.target.value)}
                rows="5"
                required
                style={{ height: "80px" }}
              ></textarea>
            </div>
          </div>
        </Form>
      </ModalContent>
      <ModalActions>
        <Button color="green" className={isLoading ? 'loading disabled' : ''} onClick={(e) => handleFinish(e)}>
          <Icon name="checkmark" /> CONFIRMAR
        </Button>
        {!isLoading && (
          <Button color="red" onClick={close}>
            <Icon name="remove" /> FECHAR
          </Button>
        )}
      </ModalActions>
    </Modal>
  );
};

export default ModalTextArea;
