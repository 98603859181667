import React, {useState} from "react";
import { Input } from "semantic-ui-react"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import api from "../../../services/api/api";
import {FaRegEye, FaRegEyeSlash} from "react-icons/fa";

const ResetPassword = (props) => {

    const history = useHistory();
    const [eyePassword, setEyePassword] = useState(false);
    const [password, setPassword] = useState("");
    const token = props.match.params.token

    const handleSubmit = (e) =>{
        e.preventDefault()

        api.post('/account/forgot/password/'+token,{
            newPassword:password
        }).then(()=>{
            alert('Senha alterada com sucesso')
            window.location.pathname = '/'
        }).catch((error)=>{
            alert('Token inválido / já utilizado')
            // window.location.reload();
        })
    }

    return (
        <form>
            <div className="input-content" style={{marginTop: "20px"}}>
                <div className="input-content">
                    <label>Senha</label>
                    <Input labelPosition='left' onChange={e => setPassword(e.target.value)} icon='lock' iconPosition='left'  placeholder='no minimo 8 dígitos' name="password" id="password" type={!eyePassword ? "password" : "text" } className="validate input-senha"/>
                    {!eyePassword ? <FaRegEyeSlash onClick={() => setEyePassword(true)} className="icon-password"/> : <FaRegEye className="icon-password" onClick={() => setEyePassword(false)}/> }
                </div>
            </div>
            <div className="finalizar-cadastro">
                <button className="waves-effect waves-light btn-small" onClick={(e) => handleSubmit(e)}>
                    Enviar
                </button>
                <span onClick={() => history.goBack()}>Voltar</span>
            </div>
        </form>
    );
};

export default ResetPassword;