import React, { useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import api from "../../../services/api/api";
import uploadFiles from '../../../services/api/uploadS3';

const ConclusionDemand = (props) => {

    const { 
        openModal,
        handleClose
    } = props;

    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const fileInputRef = useRef(null);
    const [comment,setComment] = useState("");

    const finishDemand = async (e) => {
        e.preventDefault();
        if(comment === ""){
            alert("As observações são obrigatórias")
            return false
        }
        if(files.length <= 0){
            alert("O envio de arquivos é obrigatório")
            return false
        }
        setIsLoading(true);
        const uploadedFiles = await uploadFiles(files, `documents/${props.demandId}/{userId}`);

        api.post(`/demands/finish/${props.demandId}`, {
            comment,
            files: uploadedFiles
        })
            .then(()=>{
                fileInputRef.current.value = '';
                alert('Demanda enviada para revisão com sucesso');
                handleClose();
                window.location.reload();
            }).catch((error)=>{
                window.alert('Erro ao finalizar a demanda');
            }).finally(() =>  setIsLoading(false));
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: 'none !important',
        boxShadow: 24,
        p: 4,
      };

    return (
        <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus
        >
            <Box sx={style} className="box chat-modal">
                <form action="#">
                        <div className="row">
                            <h6>Conclusão</h6>
                            <div className="input-field col s12">
                                <textarea id="textarea2" onChange={(e)=>setComment(e.target.value)} required className="materialize-textarea" data-length="120">

                                </textarea>
                                <label htmlFor="textarea2">Observações</label>
                            </div>
                        </div>
                        <div className="file-field input-field">
                            <h6>Anexar arquivo</h6>
                            <div className="btn">
                                <span>Arquivo</span>
                                <input
                                    type="file"
                                    multiple
                                    ref={fileInputRef}
                                    onChange={(e)=>setFiles(e.target.files)}
                                />
                            </div>
                            <br/>
                            <br/>
                            <h4>Arquivos enviados:</h4>
                            {/*{files.map((file,counter) => {*/}
                            {/*    return (*/}
                            {/*        <li>{file.name}</li>*/}
                            {/*    )*/}
                            {/*})}*/}
                            <ul>
                                <li></li>
                            </ul>
                            <br/>
                            <br/>
                            <div className="file-path-wrapper">
                                <input className="file-path validate" id="file" type="text"/>
                            </div>
                        </div>
                        <h6>Ações</h6>
                        <div className="input-field col s12" style={{margin: "16px auto", display: "block"}} id="finish-demand-action-buttons">
                            <button
                                id="finish-demand"
                                onClick={(e)=>finishDemand(e)}
                                className="waves-effect waves-light btn"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Aguarde...' : 'Finalizar demanda'}
                            </button>
                            &nbsp;
                            <button className="modal-close waves-effect waves-light btn" onClick={() => handleClose()}>Fechar</button>
                        </div>
                    </form>
            </Box>
        </Modal>

    )
}

export default ConclusionDemand;