import React, {useEffect, useState} from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Searchbar from "../../components/Searchbar/Searchbar";
import {Input, Table, TextArea} from 'semantic-ui-react';
import Pagination from "../../components/Pagination/Pagination";
import api from "../../services/api/api";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Hints from '../../components/Hints/Hints';
import AssignModal from '../../components/Modals/AssignDemand/AssignModal'
import "./LawyerServices.css";
import { ReactComponent as DolarIcon } from "../../assets/dolar-icon.svg"
import { ReactComponent as CalendarIcon } from "../../assets/calendar-icon.svg"
import { useNotifications } from "../../helpers/getNotifications";

const LawyerServices = (props) => {

    const [sidebarIsOpen,setSidebarIsOpen] = useState(false);

    const handleSidebar = (sidebar)=>setSidebarIsOpen(sidebar);

    const jfDemand = props.match.params.jfDemand !== undefined;
    const clientId = props.match.params.clientId || undefined;
    const [selectedClient,setSelectedClient] = useState("");
    const [openAssignModal, setOpenAssignModal] = useState(false);
    const [demandData,setDemandData] = useState("");
    const [servicesProvided,setServicesProvided] = useState("");
    const [selectedProvidedService,setSelectedProvidedService] = useState("");
    const [serviceType,setServiceType] = useState("");
    const [selectedServiceType,setSelectedServiceType] = useState("");
    const [subservice,setSubservice] = useState("");
    const [selectedSubservice,setSelectedSubservice] = useState("");
    const [servicesToAssign,setServicesToAssign] = useState("");
    const [searchServices,setSearchServices] = useState(jfDemand?"Jurídico Familiar":"");
    const [deadlineDemandInEstimating,setDeadlineDemandInEstimating] = useState("");
    const [comment,setComment] = useState("");
    const [priceDemandInEstimating,setPriceDemandInEstimating] = useState("");
    const [fieldId,setFieldId] = useState("");
    const [clients,setClients] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPage,setMaxPage] = useState(1);
    const [search,setSearch] = useState("");
    const {notifications, checkNotification} = useNotifications();
    useEffect(()=>{
        api.get(`/clients/index?page=${currentPage}&search=${search}`).then((response) => {
            setClients(response.data.data);
            setMaxPage(response.data.last_page)
        }).catch((error)=>{
          alert('Erro ao carregar os clientes');
        });
    },[currentPage, search])

    useEffect(()=>{
      api.get(`/lawyer/service`).then((response) => {
        setServicesProvided(response.data.data)
      }).catch((error)=>{
        alert('Erro ao carregar os serviços');
      });
    },[])


    useEffect(()=>{
      api.get(`/lawyer/searchService?search=${searchServices}`).then((response) => {
        setServicesToAssign(response.data.data)
      }).catch((error)=>{
        alert('Erro ao carregar a pesquisa');
      });
    },[searchServices])


    useEffect(()=>{
      if (selectedClient && servicesToAssign && servicesToAssign.length > 0) {
        const client = clients.find(item => item._id === selectedClient);
        let packageId = null;
        if (client) {
          packageId = client.packageId;
        }
        const servicesPrices = servicesToAssign.map((item, index) => {
          return item.prices
        })
        const filteredPrices = servicesPrices.map((item, index) => {
          const packagesArray = item
          const filteredPackagesArray = packagesArray.map((newItem, newIndex)=>{
            return {packageId: newItem.packageId.$oid, amount: newItem.amount}
          })
          return filteredPackagesArray
        })
        const packagePriceToClient = filteredPrices.map((item, index) => {
          const packageItem = item
          const selectedPackageItem = packageItem.find(item => item.packageId === packageId);
          return selectedPackageItem
        })
        const filteredServicesToAssign = servicesToAssign.map((item, index) => {
          return {...item, priceToSelectedClient: packagePriceToClient[index]?.amount}
        })
        setServicesToAssign(filteredServicesToAssign)
      }
    },[selectedClient, clients, servicesToAssign])


    const handleServiceType = (event) => {
      if(event.target.value) {
        setSelectedProvidedService(event.target.value)

        api.get(`/lawyer/service-type/${event.target.value}`).then((response) => {
          setServiceType(response.data.data)
        }).catch((error)=>{
          alert('Erro na seleção dos tipos de serviços');
        });
      } else {
        setSelectedProvidedService("")
        setSelectedServiceType("")
        setServiceType("")
        setSelectedSubservice("")
        setSubservice("")
        setServicesToAssign("")        
      }
    }

    const handleSubservice = (event) => {
      if(event.target.value) {
        setSelectedServiceType(event.target.value)

        api.get(`/lawyer/subservice/${event.target.value}`).then((response) => {
          setSubservice(response.data.data)
        }).catch((error)=>{
          alert('Erro na seleção dos subserviços');
        });
      } else {
        setSelectedServiceType("")
        setSelectedSubservice("")
        setSubservice("")
        setServicesToAssign("")
      }
    }

    const handleShowSubservice = (event) => {
      if(event.target.value) {
        setSelectedSubservice(event.target.value)
        const subserviceToAssign = subservice.find(item => item._id === event.target.value);
        setServicesToAssign([subserviceToAssign])
      } else {
        setSelectedSubservice("")
        setServicesToAssign("")
      }
    }

    const changePage = (page = 1) => {
      setCurrentPage(page);
    }

    const handleSearchClients = (event) => {
        setSearch(event.target.value)
    }

    const handleSearchServices = (search) => {
      setSearchServices(search)
    }

    const handleSelectClient = (value) => {
      if(selectedClient !== value){
        setSelectedClient(value)
      } else {
        setSelectedClient(false)
      }
    }

    const handleDemandsInEstimating = (field, fieldId, value) => {
      setFieldId(fieldId)

      if(field === 'deadline'){
        setDeadlineDemandInEstimating(value)
      }
      if(field === 'price'){
        setPriceDemandInEstimating(value)
      }

      if(field === 'comment'){
          setComment(value)
      }
    }

    const handleAssignModal = (subserviceId, deadline, priceToSelectedClient, comment, needsEstimate) => {
      if(selectedClient || jfDemand){
        if(needsEstimate){
          if(fieldId === subserviceId){
            if(!deadline || !priceToSelectedClient) {
              alert("Os campos prazo ou orçamento não foram preenchidos!");
            } else {
              setDemandData({
                  clientId: jfDemand ? clientId : selectedClient,
                  subserviceId: subserviceId,
                  deadline: deadline,
                  price: priceToSelectedClient,
                  comment:comment
              })
              setOpenAssignModal(!openAssignModal)
            }
          } else {
            alert("É necessário preencher os mesmos campos do serviço a ser atribuído!");
          }
        } else {
          setDemandData({
              clientId: jfDemand ? clientId : selectedClient,
              subserviceId: subserviceId,
              deadline: deadline,
              price: priceToSelectedClient,
              comment:comment
          })
          setOpenAssignModal(!openAssignModal)
        }
      } else {
        alert("É necessário selecionar o cliente!");
      }
    }

    return (
        <div className="services-container">
            <AssignModal
            handleClose={handleAssignModal}
            openModal={openAssignModal}
            demandData={demandData}
            jfDemand={jfDemand}
            />
            <div className="row">
                <div className="col s12">
                    <Sidebar isOpen={handleSidebar} notifications={notifications} checkNotification={checkNotification}/>
                </div>
                <div className={!sidebarIsOpen ? "container-width" : "container-width-sbopen"}>
                <div className="col s12 services-inner-container">
                    <div className="hintsbar">
                      <Hints
                        hintText={'Use os filtros ou o campo de busca para encontrar o serviço que procura'}
                        hintInfo={'Você pode encontrar serviços específicos para sua necessidade escolhendo entre as opções do filtro acima.'}
                      />
                    </div>

                    {!jfDemand &&
                        <div className="searchbar">
                            <Searchbar search={handleSearchServices}/>
                        </div>
                    }

                    {!jfDemand &&
                        <div className="select-services-container">
                            <div className="row center">
                                <div className="col s12 l12 xl4">
                                    <div className="label-services">
                                        Serviços
                                    </div>
                                    <FormControl>
                                        <Select
                                            onChange={handleServiceType}
                                            className="select-table"
                                            displayEmpty
                                            inputProps={{'aria-label': 'Without label'}}
                                            value={selectedProvidedService}
                                        >
                                            <MenuItem value="" selected>
                                                <em>Selecione um serviço</em>
                                            </MenuItem>
                                            {servicesProvided && servicesProvided.length > 0 ?
                                                servicesProvided?.map((services) => {
                                                    return (
                                                        <MenuItem value={services._id}>{services.name}</MenuItem>
                                                    )
                                                }) : ""}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col s12 l12 xl4">
                                    <div className="label-services">
                                        Tipos
                                    </div>
                                    <FormControl >
                                        <Select
                                            onChange={handleSubservice}
                                            className="select-table"
                                            displayEmpty
                                            inputProps={{'aria-label': 'Without label'}}
                                            value={selectedServiceType}
                                        >
                                            <MenuItem value="" selected>
                                                <em>Selecione um tipo</em>
                                            </MenuItem>
                                            {serviceType && serviceType.length > 0 ?
                                                serviceType?.map((serviceType) => {
                                                    return (
                                                        <MenuItem value={serviceType._id}>{serviceType.name}</MenuItem>
                                                    )
                                                }) : ""}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col s12 l12 xl4">
                                    <div className="label-services">
                                        Subserviços
                                    </div>
                                    <FormControl>
                                        <Select
                                            onChange={handleShowSubservice}
                                            className="select-table"
                                            displayEmpty
                                            inputProps={{'aria-label': 'Without label'}}
                                            value={selectedSubservice}
                                        >
                                            <MenuItem value="" selected>
                                                <em>Selecione um subservico</em>
                                            </MenuItem>
                                            {subservice && subservice.length > 0 ?
                                                subservice?.map((subservice) => {
                                                    return (
                                                        <MenuItem value={subservice._id}>{subservice.name}</MenuItem>
                                                    )
                                                }) : ""}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>

                    }
                </div>
                <div className="col s12 table-container">
                  <div id="table-services" className="col s12 l6 xl6">
                    {servicesToAssign && servicesToAssign.length > 0 ? (servicesToAssign?.map((servicesToAssign) => {
                      return (
                        <Table>
                          <Table.Header className="tb-head">
                          <Table.Row className="tb-head">
                              <Table.HeaderCell>{servicesToAssign.name}</Table.HeaderCell>
                          </Table.Row>
                          </Table.Header>
                          <Table.Body className="tb-body">  
                            {servicesToAssign.needsEstimate ? (
                              <>
                                <Table.Row>
                                  <Table.Cell display="flex" flexDirection="row" alignItems="center" width={1}>
                                    <div className="label-services">
                                      Prazo
                                    </div>                                    
                                    <Input icon='calendar outline' iconPosition='left' onChange={(e)=> handleDemandsInEstimating('deadline', servicesToAssign._id, e.target.value)} placeholder='0 dias úteis' className="input-field-services"/>
                                  </Table.Cell>
                                </Table.Row>  
                                <Table.Row>
                                  <Table.Cell display="flex" flexDirection="row" alignItems="center" className="text-service-price-estimating" width={1}>
                                    <div className="label-services">
                                      Orçamento
                                    </div>
                                    <Input icon='dollar' iconPosition='left' placeholder='0 BNZ' onChange={(e)=> handleDemandsInEstimating('price', servicesToAssign._id, e.target.value)} className="input-field-services"/>
                                  </Table.Cell>
                                </Table.Row>
                                  <Table.Row>
                                      <Table.Cell display="flex" flexDirection="row" alignItems="center" className="text-service-price-estimating" width={1}>
                                          <div className="label-services">
                                              Escopo
                                          </div>
                                          <TextArea iconPosition='left' placeholder='Escopo' onChange={(e)=> setComment(e.target.value)} className="input-field-services"/>
                                      </Table.Cell>
                                  </Table.Row>
                                <Table.Row>
                                  <Table.Cell width={1}>
                                    <button onClick={() => 
                                      handleAssignModal(
                                        servicesToAssign._id, 
                                        deadlineDemandInEstimating, 
                                        priceDemandInEstimating,
                                        comment,
                                        true
                                      )}>Atribuir serviço
                                    </button>
                                  </Table.Cell>
                                </Table.Row>
                              </>  
                            ) : (
                              <>
                                <Table.Row>
                                  <Table.Cell display="flex" flexDirection="row" alignItems="center" width={1}>
                                    <CalendarIcon style={{margin: '0 0 -2px 0', padding: '0 17px 0 0'}} />{servicesToAssign.deadline} dias úteis
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell display="flex" flexDirection="row" alignItems="center" className="text-service-price" width={1}>
                                    <DolarIcon style={{margin: '0 0 -2px 0', padding: '0 17px 0 0'}} />
                                    {servicesToAssign.priceToSelectedClient ? `${servicesToAssign.priceToSelectedClient} BNZ` : 'Selecione o cliente'}
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell width={1}>
                                    <button onClick={() => 
                                      handleAssignModal(
                                        servicesToAssign._id, 
                                        servicesToAssign.deadline, 
                                        servicesToAssign.priceToSelectedClient,
                                        false
                                      )}>Atribuir serviço
                                    </button>
                                  </Table.Cell>
                                </Table.Row>
                              </>
                            )}
                          </Table.Body>
                        </Table>
                    )})) : (                     
                      <Table>
                        <Table.Header className="tb-head">
                        <Table.Row className="tb-head">
                            <Table.HeaderCell>Nenhum serviço encontrado</Table.HeaderCell>
                        </Table.Row>
                        </Table.Header>
                        <Table.Body className="tb-body">  
                          <Table.Row>
                            <Table.Cell display="flex" flexDirection="row" alignItems="center" width={1}>
                              Selecione ou busque pelo serviço que deseja atribuir e então selecione o cliente.<br/><br/>
                            </Table.Cell>
                          </Table.Row>  
                        </Table.Body>
                      </Table>
                    )}                    
                  </div>
                    {!jfDemand &&
                        <div id="table-clients-services" className="col s12 l6 xl6">
                            <div className="row">
                                <div className="col s12">
                                    <h2>Meus clientes</h2>
                                    <Input
                                        icon='search'
                                        iconPosition='left'
                                        onChange={handleSearchClients}
                                        placeholder='Buscar cliente'
                                        className="searchbar-clients"
                                    />
                                </div>
                            </div>
                               <div className="row">
                                   <div className="col s12">
                                       <table className="responsive-table">
                                           <thead>
                                           <tr>
                                               <th>
                                                   Nome
                                               </th>
                                               <th>
                                                   Email
                                               </th>
                                               <th>
                                                   Telefone
                                               </th>
                                               <th>
                                                   Ação
                                               </th>
                                           </tr>
                                           </thead>
                                           <tbody>
                                           {clients && clients.length > 0 ? (
                                               clients?.map((person) => {
                                                   return (
                                                       <tr>
                                                           <td>
                                                               {person.companyName??'Nome não informado'}
                                                           </td>
                                                           <td>
                                                               {person.businessEmail??'Email não informado'}
                                                           </td>
                                                           <td>
                                                               {person.businessTelephoneNumber??'Telefone não informado'}
                                                           </td>
                                                           <td>
                                                               <button
                                                                   style={selectedClient === person._id ?
                                                                       {background: '#FAFAFA', color: '#D3D2D7'}
                                                                       :
                                                                       {background: '#F14F5C', color: '#FFFFFF'}
                                                                   }
                                                                   onClick={() => handleSelectClient(person._id)}
                                                                   disabled={servicesToAssign && servicesToAssign.length > 0 ? false : true}
                                                               >
                                                                   {selectedClient === person._id ? 'Selecionado' : 'Selecionar'}
                                                               </button>
                                                           </td>
                                                       </tr>
                                                   );
                                               })
                                           ) : (
                                               <tr>
                                                   <td>
                                                       Clientes não encontrados
                                                   </td>
                                               </tr>
                                           )}
                                           </tbody>
                                           <tfoot>
                                           <Pagination numberOfPages={maxPage} changePage={changePage}/>
                                           </tfoot>
                                       </table>
                                   </div>
                               </div>
                        </div>
                    }
                </div>
              </div>
            </div>

        </div>
    )
}

export default LawyerServices