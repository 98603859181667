import React, {useEffect, useState} from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Breadcumb from "../../components/Breadcumb/Breadcumb";
import api from "../../services/api/api";
import { useNotifications } from "../../helpers/getNotifications";

const MyAccount = () => {
    let breadcumb = [
        {link:<MyAccount/>,name:'Minha conta'}
    ]

    const [sidebarIsOpen,setSidebarIsOpen] = useState(false);

    const handleSidebar = (sidebar)=>setSidebarIsOpen(sidebar);
    const {notifications, checkNotification} = useNotifications();

    const initialValues = {
        profileBackgroundImage:{},
        profileImage:{},
        displayAs:"",
        personalEmail:"",
        personalMobileNumber:"",
        personalTelephoneNumber:"",
        professionalEmail:"",
        professionalEmailConfirmation:"",
        professionalMobileNumber:"",
        professionalTelephoneExtension:"",
        professionalTelephoneNumber:"",
        linkedinProfile:"",
        password:""
    }
    const [values,setValues] = useState(initialValues);

    const [emailConfirmation,setEmailConfirmation] = useState("");
    const [passwordConfirmation,setPasswordConfirmation] = useState("");

    const handleUpdateProfile = (e) => {
        e.preventDefault();

        if(values.professionalEmail !== emailConfirmation){
            alert("Os emails não coincidem");
            return;
        }

        if (values.password !== ""){
            if(values.password !== passwordConfirmation){
                alert("As senhas não coincidem");
                return;
            }
        }

        let formData = new FormData();

        if(values.profileBackgroundImage !== undefined) formData.append('profileBackgroundImage',values.profileBackgroundImage)
        if(values.profileImage !== undefined) formData.append('profileImage',values.profileImage)
        formData.append('displayAs',values.displayAs)
        formData.append('personalEmail',values.personalEmail)
        formData.append('personalMobileNumber',values.personalMobileNumber)
        formData.append('personalTelephoneNumber',values.personalTelephoneNumber)
        formData.append('professionalEmail',values.professionalEmail)
        formData.append('professionalEmailConfirmation',values.professionalEmailConfirmation)
        formData.append('professionalMobileNumber',values.professionalMobileNumber)
        formData.append('professionalTelephoneExtension',values.professionalTelephoneExtension)
        formData.append('professionalTelephoneNumber',values.professionalTelephoneNumber)
        formData.append('linkedinProfile',values.linkedinProfile)
        formData.append('password',values.password)

        api.post(`/lawyer/update`,formData)
            .then(()=>{
                alert(`Conta atualizada`)
                window.location.reload();
            }).catch((error)=>{
                alert(`Erro ao atualizar a conta`)
        })

    }

    useEffect(()=>{

        api.get('/lawyer/my-account').then((response) => {

            const {data} = response

            const objToSet = {
                displayAs:data.displayAs,
                personalEmail:data.personalEmail,
                personalMobileNumber:data.personalMobileNumber,
                personalTelephoneNumber:data.personalTelephoneNumber,
                professionalEmail:data.professionalEmail,
                professionalEmailConfirmation:data.professionalEmail,
                professionalMobileNumber:data.professionalMobileNumber,
                professionalTelephoneExtension:data.professionalTelephoneExtension,
                professionalTelephoneNumber:data.professionalTelephoneNumber,
                linkedinProfile:data.linkedinProfile,
                password:""
            }

            setEmailConfirmation(data.professionalEmail)

            setValues(objToSet)

        }).catch((error)=>{
            alert("Erro ao buscar informações do usuário;")
        });
    },[])

    function changeValues(name,value){
        setValues({ ...values, [name]: value });
    }

    return (
        <div id="my-account">
            <div className="row">
                <div className="col s12">
                    <Sidebar isOpen={handleSidebar} notifications={notifications} checkNotification={checkNotification}/>
                </div>
                <div className={
                    !sidebarIsOpen
                        ? "container-width"
                        : "container-width-sbopen"
                }>
                <div className="col s12">
                    <Breadcumb breadcumbs={breadcumb}/>
                </div>
                <div className="container">
                    <div className="col s12">
                        <h5>Informações pessoais</h5>
                        <div className="row">
                            <form className="col s12">
                                <div className="row">
                                    <div className="col s6">
                                        <div className="file-field input-field">
                                            <div className="btn">
                                                <span>Foto de perfil</span>
                                                <input type="file" onChange={(e)=>changeValues('profileImage',e.target.files[0])}/>
                                            </div>
                                            <div className="file-path-wrapper">
                                                <input className="file-path validate" type="text"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col s6">
                                        <div className="file-field input-field">
                                            <div className="btn">
                                                <span>Foto de capa</span>
                                                <input type="file" onChange={(e)=>changeValues('profileBackgroundImage',e.target.files[0])}/>
                                            </div>
                                            <div className="file-path-wrapper">
                                                <input className="file-path validate" type="text"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-field col s6">
                                        <input id="personal-name" type="text" className="validate" value={values.displayAs} onChange={(e)=>changeValues('displayAs',e.target.value)}/>
                                            <label className="active" htmlFor="personal-name">Nome</label>
                                    </div>
                                    <div className="input-field col s6">
                                        <input id="personal-email" type="text" className="validate" value={values.personalEmail} onChange={(e)=>changeValues('personalEmail',e.target.value)}/>
                                        <label className="active" htmlFor="personal-email">E-mail</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-field col s6">
                                        <input id="personal-mobile-phone" type="text" className="validate" value={values.personalMobileNumber} onChange={(e)=>changeValues('personalMobileNumber',e.target.value)}/>
                                            <label className="active" htmlFor="personal-mobile-phone">Celular</label>
                                    </div>
                                    <div className="input-field col s6">
                                        <input id="personal-phone" type="text" className="validate" value={values.personalTelephoneNumber} onChange={(e)=>changeValues('personalTelephoneNumber',e.target.value)}/>
                                        <label className="active" htmlFor="personal-phone">Telefone</label>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col s12">
                        <h5>Informações profissionais</h5>
                        <form className="col s12">
                            <div className="row">
                                <div className="input-field col s6">
                                    <input id="professional-email" type="text" className="validate" value={values.professionalEmail} onChange={(e)=>changeValues('professionalEmail',e.target.value)}/>
                                    <label className="active" htmlFor="professional-email">E-mail</label>
                                </div>
                                <div className="input-field col s6">
                                    <input id="professional-email-confimr" type="text" className="validate" value={emailConfirmation} onChange={(e)=>setEmailConfirmation(e.target.value)}/>
                                    <label className="active" htmlFor="professional-email-confimr">Confirme o e-mail</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s4">
                                    <input id="professional-mobile-phone-prefix" type="text" className="validate" value={values.professionalMobileNumber} onChange={(e)=>changeValues('professionalMobileNumber',e.target.value)}/>
                                    <label className="active" htmlFor="professional-mobile-phone-prefix">Telefone celular profissional</label>
                                </div>
                                <div className="input-field col s4">
                                    <input id="professional-phone" type="text" className="validate" value={values.professionalTelephoneNumber} onChange={(e)=>changeValues('professionalTelephoneNumber',e.target.value)}/>
                                    <label className="active" htmlFor="professional-phone">Telefone fixo profissional</label>
                                </div>
                                <div className="input-field col s4">
                                    <input id="professional-phone-extension" type="text" className="validate" value={values.professionalTelephoneExtension} onChange={(e)=>changeValues('professionalTelephoneExtension',e.target.value)}/>
                                    <label className="active" htmlFor="professional-phone-extension">Ramal</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s6">
                                    <input id="linkedin-url" type="url" className="validate" value={values.linkedinProfile} onChange={(e)=>changeValues('linkedinProfile',e.target.value)}/>
                                    <label className="active" htmlFor="linkedin-url">Perfil do Linkedin</label>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col s12">
                        <h5>Redefinir senha</h5>
                        <div className="row">
                            <div className="input-field col s6">
                                <input id="password" type="password" className="validate" onChange={(e)=>changeValues('password',e.target.value)}/>
                                    <label className="active" htmlFor="password">Senha</label>
                            </div>
                            <div className="input-field col s6">
                                <input id="password-confirm" type="password" className="validate" onChange={(e)=>setPasswordConfirmation(e.target.value)}/>
                                    <label className="active" htmlFor="password-confirm">Confirmar senha</label>
                            </div>
                        </div>
                    </div>
                    <div className="col s12">
                        <button className="btn waves-effect waves-light" type="submit" name="action" onClick={(e)=>handleUpdateProfile(e)}>Atualizar
                            <i className="material-icons right">send</i>
                        </button>
                    </div>
                </div>
            </div>
                </div>
        </div>
    )
} 

export default MyAccount