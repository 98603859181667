// Consulta API IBGE
const URL_API_IBGE = 'https://servicodados.ibge.gov.br/api/v1';

export const fetchCountries = () => {
  const url = `${URL_API_IBGE}/localidades/paises`;
  return fetch(url).then(response => response.json());
}

export const fetchStates = () => {
  const url = `${URL_API_IBGE}/localidades/estados`;
  return fetch(url).then(response => response.json());
}

export const fetchCities = (selectedState) => {
  const url = `${URL_API_IBGE}/localidades/estados/${selectedState}/municipios`;
  return fetch(url).then(response => response.json());
}