import React from 'react'
import {
  DropdownMenu,
  DropdownItem,
  DropdownHeader,
  Dropdown,
} from 'semantic-ui-react'

import './style.css'

const SimpleButtonDropdown = (props) => (
  <Dropdown
    text={props.buttonText}
    icon={props.buttonIcon}
    labeled
    button
    className={props.class}
  >
    <DropdownMenu>
      <DropdownHeader content={props.comment} />
      {props.options.map((option) => (
        <DropdownItem key={option.value} {...option} onClick={option.onClick} />
      ))}
    </DropdownMenu>
  </Dropdown>
)

export default SimpleButtonDropdown