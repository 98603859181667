import React, { useState } from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Coruja from "../../../assets/CorujaAssignModal.svg";
import "./AssignModal.css";
import api from "../../../services/api/api";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import "alertifyjs/build/css/themes/default.css";
import moment from 'moment';
import 'moment/locale/pt-br';

alertify.set("notifier", "position", "top-right");


const AssignModal = (props) => {
  const [loading, setLoading] = useState(false);
    const {
        handleClose,
        openModal,
        demandData,
        jfDemand
    } = props;

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: 'none !important',
        boxShadow: 24,
        p: 4,
      };

      const selectOptionModal = (value) => {
        moment.locale('pt-br');
        if (value === true) {
          setLoading(true)
          let form = new FormData();
          form.append('clientId',demandData.clientId);
          form.append('subserviceId',demandData.subserviceId);
          form.append('deadline',demandData.deadline);
          form.append('price',demandData.price);
          form.append('comment',demandData.comment);
          if (demandData.requestDate) {
            const date = moment(demandData.requestDate, 'DD/MM/YYYY');
            form.append('requestDate', date.valueOf());
          }
          if (demandData.valueBNZ) {
            form.append('valueBNZ',demandData.valueBNZ);
          }
          if (demandData.channel) {
            form.append('channel',demandData.channel);
          }
          //console.log(demandData)
            let url
            if(jfDemand === false){
                url = `/demands/createDemand`
            }else{
                url = `/demands/createDemand?jfDemand=1`
            }

            //console.log(url)
            api.post(url,form)
              .then(()=>{
                alertify.notify("Demanda criada com sucesso", "success", 5);
                handleClose();
              }).catch((error)=>{
                  alertify.notify(error.response.data.message, "success", 5);
                handleClose();
            }).finally(() => setLoading(false))
        }
      }

    return (
        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus
        >
            <Box sx={style} className="box accept-modal">
              <h2>Essa demanda será atribuída ao cliente!</h2>
              <div><img src={Coruja} alt={"Bonuz owl"}/></div>
              <p>Deseja confirmar esta ação?</p>
              <div>
                  <button type="button" className='cancel' disabled={loading} onClick={() => handleClose(false)}>
                    {loading ? (
                      <div className="spinner-grey"/>
                    ) : (
                    'Cancelar'
                    )}
                  </button>
                  <button type="button" disabled={loading} onClick={() => selectOptionModal(true)}>
                    {loading ? (
                      <div className="spinner-white"/>
                    ) : (
                      'Confirmar'
                    )}
                  </button>
              </div>
            </Box>
        </Modal>
    );
};

export default AssignModal;