import React from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Coruja from "../../../../assets/CorujaOptionModal.png";
import "./ReturnDemandModal.css";
import api from "../../../../services/api/api";

const ReturnDemandModal = (props) =>{

    const {
        handleClose,
        openModal,
        setOptionReturnDemand,
        view,
        setView,
        demandId
    } = props;

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: 'none !important',
        boxShadow: 24,
        p: 4,
      };



      const selectOptionModal = (value) => {
        if (value === true) {
            setOptionReturnDemand(true)
            api.put(`/demands/give-back/${demandId}`).then(()=>{
                setView("finish-modal");
            }).catch(()=>{
                window.alert("Demanda passou do prazo para devolução");
            })
            setTimeout(() => handleClose(), 3 * 1000)
        } else {
            setOptionReturnDemand(false)
            handleClose()
        }
      }

    return (
        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus
        >
            <Box sx={style} className={view === "finish-modal" ? "box return-modal finish" : "box return-modal"}>
                {view === "default" ?
                <>
                    <h2>Você está prestes a devolver a demanda!!</h2>
                    <div><img src={Coruja} alt="bonuz owl"/></div>
                    <p>Certeza que deseja devolver a demanda?</p>
                    <div>
                        <button className='cancel' onClick={() => selectOptionModal(false)}>Cancelar</button>
                        <button onClick={() => selectOptionModal(true)}>Devolver</button>
                    </div>
                </>
                : ""
                }
                {view === "finish-modal" ?
                <>
                    <h2>Esta demanda foi devolvida para ‘Demanda geral’!</h2>
                </>
                : ""
                }
                
            </Box>
        </Modal>
    );
}

export default ReturnDemandModal;