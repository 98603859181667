import React, {useEffect, useState} from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import './styles.css'
import InfoCard from "../../components/PlansClients/InfoCard";
import {Link} from "react-router-dom";
import api from "../../services/api/api";
import { useNotifications } from "../../helpers/getNotifications";

const PlatinumPlanClient = (props) => {
    const [sidebarIsOpen,setSidebarIsOpen] = useState(false);
    const [client,setClient] = useState({})
    const [plan,setPlan] = useState('')
    const handleSidebar = (sidebar)=>setSidebarIsOpen(sidebar);
    const {notifications, checkNotification} = useNotifications();
    const clientId = props.match.params.clientId;
    useEffect(()=>{
        api.get(`/plans/index/${clientId}?plan=platinum`).then(
            (response) => {
                setClient(response.data.data.client)
                setPlan(response.data.data.planIdentifier)
            }
        )
    },[clientId])

    return (
        <div className="platinum-plan-client">
            <Sidebar isOpen={handleSidebar} notifications={notifications} checkNotification={checkNotification}/>
            <div className="row">
                <div>
                    <div className={
                        !sidebarIsOpen
                            ? "container-width p-2"
                            : "container-width-sbopen"
                    }>
                        <main>
                            <div className="first">
                                <InfoCard client={client} plan={plan}/>
                                <div id="dataprivacy-doc-send-button">
                                    <Link to={`/services/bonuz-plans/${client._id}`}>
                                        Adicionar Serviço
                                    </Link>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlatinumPlanClient