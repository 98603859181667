import React, {useEffect, useState} from 'react'
import './ActivitiesStatusBar.css'
import api from "../../../services/api/api";

const ActivitiesStatusBar = () => {

    let lenghtPending = 0
    let lenghtDone = 0
    let lenghtInProgress = 0

    let totalPercentage = 0

    const [ activities, setActivities ] = useState();


    useEffect(() => {
        api.get('data-privacy/all').then((response)=>{
            setActivities(response.data.data)
        }).catch(()=>alert('erro ao carregar atividades'))
    }, []);

    activities?.forEach((activite)=>{
        if(activite.status === 'pending'){
            lenghtPending++;
            totalPercentage++;
        }else if(activite.status === 'done'){
            lenghtDone++
            totalPercentage++;
        }else if(activite.status === 'in_progress'){
            lenghtInProgress++;
            totalPercentage++;
        }
    })

    return (
            <div className='card mb-3 p-3 data-privacy-metrics-wrapper'>
                <h4>Andamento das demandas gerais</h4>
                <div className="progress mb-2" style={{height: '8px', display: 'flex', background: '#e9ecef'}}>
                    <div className="progress-bar" role="progressbar" style={{width: `${(lenghtDone / totalPercentage * 100).toFixed(0)}%`, height: '8px', background: '#44E47A'}} aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                    <div className="progress-bar" role="progressbar" style={{width: `${(lenghtInProgress / totalPercentage * 100).toFixed(0)}%`, height: '8px', background: '#7683F6'}} aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                    <div className="progress-bar" role="progressbar" style={{width: `${(lenghtPending / totalPercentage * 100).toFixed(0)}%`, height: '8px', background: '#FBC229'}} aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div className='d-flex data-privacy-metrics-description'>
                    <div className='d-flex aling-items-center me-2 data-privacy-metrics-description-details'>
                        <div className='progress-circle' style={{background: '#44E47A'}}></div>
                        <small className='mb-0'>Concluído ({lenghtDone})</small>
                    </div>
                    <div className='d-flex aling-items-center me-2 data-privacy-metrics-description-details'>
                        <div className='progress-circle' style={{background: '#7683F6'}}></div>
                        <small className='mb-0'>Em progresso ({lenghtInProgress})</small>
                    </div>
                    <div className='d-flex aling-items-center me-2 data-privacy-metrics-description-details'>
                        <div className='progress-circle' style={{background: '#FBC229'}}></div>
                        <small className='mb-0'>Pendente ({lenghtPending})</small>
                    </div>
                </div>
            </div>
    )
}

export default ActivitiesStatusBar;