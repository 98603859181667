import React, {useEffect, useState} from "react";
import { FileUploader } from "react-drag-drop-files";
import { Input } from "semantic-ui-react";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { ReactComponent as LanguageIcon } from "../../../../assets/languageIcon.svg";
import { ReactComponent as LocaleIcon } from "../../../../assets/localeIcon.svg";
import FinishRegisterModal from "../../../Modals/FinishRegister/FinishRegisterModal";
import { fetchStates, fetchCities, fetchCountries } from "../../../../helpers/PlacesApi/placesApi";
import api from "../../../../services/api/api";
import './DadosAdvogado.css'

const DadosAdvogado = (props) => {

    const [openFinishRegisterModal, setOpenFinishRegisterModal] = useState(false);

    const [expertises, setExpertises] = useState([]);
    const [oab,setOab] = useState("")
    const [linkedin, setLinkedin] = useState("")
    const [file, setFile] = useState(null);
    const [availableExpertisesToChoose,setAvailableExpertisesToChoose] = useState([]);
    const [language, setLanguage] = useState("")
    const [birthplace, setBirthplace] = useState("");
    const [birthDate, setBirthDate] = useState("");

    const [countries, setCountries] = useState("")
    const [countryStates, setCountryStates] = useState("");
    const [stateCities, setStateCities] = useState("");

    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [selectedCity, setSelectedCity] = useState("");
    const [selectedAddress, setSelectedAddress] = useState("");

    const handleOpen = () => {
        setOpenFinishRegisterModal(true)
    }
    const handleClose = () => {
        window.location.pathname = '/login';
    };

    const handleChangeLanguage = (event) => {
      const { target: { value } } = event;
      setLanguage(value);
    };

    const handleChangeFile = (file) => {
        setFile(file);
    };

    // const onSelectedAddress = (place) => {
    //   setBirthplace(place)
    // };

    const handleChangeCountry = (place) => {
      if(selectedCountry !== place.target.value){
        setSelectedState("")
        setSelectedCity("")
        setSelectedAddress("")
      }
      setSelectedCountry(place.target.value)
    };

    const handleChangeState = (place) => {
      if(selectedState !== place.target.value){
        setSelectedCity("")
      }
      setBirthplace(place.target.value)
      setSelectedState(place.target.value)
    }

    const handleChangeCity = (place) => {
      setBirthplace(place.target.value + ' - ' + selectedState)
      setSelectedCity(place.target.value)
    }

    const handleAddress = (place) => {
      setSelectedAddress(place)
    }

    const setExpertisesValue = (value) => {
        if(expertises === []){
            setExpertises([value]);
        }

        let findExpertise = expertises.find(function (expertise){
           return expertise === value
        });

        let actualExpertises = expertises;

        if(findExpertise === undefined){
            setExpertises([...expertises, value])

        }else{

            let arrayIndex = expertises.indexOf(findExpertise);
            delete actualExpertises[arrayIndex];
            const filterExpertises = actualExpertises.filter(element => {
                return element !== undefined || element !== null;
            });
            filterExpertises.sort();
            setExpertises(filterExpertises);
        }
    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
    PaperProps: {
        style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        },
    },
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const lawyerAddress = selectedAddress ? (selectedAddress + ' - ' + selectedCountry) : (birthplace + ' - ' + selectedCountry)
        let form = new FormData();

        form.append('expertises',expertises.join(',')??[]);
        form.append('oab',oab);
        form.append('linkedinProfile',linkedin);
        form.append('curriculumFile',file);
        form.append('userId',props.match.params.userId);
        form.append('language',language);
        form.append('birthplace',lawyerAddress);
        form.append('birthDate',birthDate);
        form.append('cadastroExterno', true);

        api.post(
            '/account/register/complete',
            form
        ).then(()=> handleOpen()).catch((e)=>{
            //console.log(e)
            alert('Erro ao cadastrar');
            //window.location.reload();
        })
    }

    useEffect(()=>{
      api.get('/expertises/index').then((response)=>{
          setAvailableExpertisesToChoose(response.data);
      })
    },[])

    useEffect(()=>{
      fetchCountries().then((countries)=>{
        setCountries(countries)
      })
    },[])

    useEffect(()=>{
      fetchStates().then((states)=>{
        setCountryStates(states)
      })
    },[])

    useEffect(()=>{
      fetchCities(selectedState).then((cities)=>{
        setStateCities(cities)
      })
    },[selectedState])


    return (
        <form>
            <FinishRegisterModal
                handleOpen={handleOpen}
                handleClose={handleClose}
                openModal={openFinishRegisterModal}
            />
            <h3>Dados do Advogado</h3>
            <div className="input-content select">
                <label>Especialidades</label>
                <select multiple value={expertises} className="expertises-select input-content ui left icon input validate" onChange={(e)=>setExpertisesValue(e.target.value)}>
                    {availableExpertisesToChoose.map((expertise, counter) => (
                        <option value={expertise._id}>{expertise.name}</option>
                    ))}
                </select>
            </div>
            <div className="input-content">
                <label>OAB</label>
                <Input icon='address book' onChange={e => setOab(e.target.value)} iconPosition='left' placeholder='UF999999' name="especialidade" id="especialidade" type="text" className="validate"/>
            </div>
            <div className="input-content">
                <label>Linkedin</label>
                <Input icon='linkedin' onChange={e => setLinkedin(e.target.value)} iconPosition='left' placeholder='linkedin.com/in/' name="especialidade" id="especialidade" type="text" className="validate"/>
            </div>
            <div className="input-content">
                <label>Currículo</label>
                <FileUploader handleChange={handleChangeFile} name="file" label="upload Solte o arquivo aqui para enviar..."/>
            </div>
            <div className="input-content">
                <label>Data de nascimento</label>
                <Input icon='calendar' onChange={e => setBirthDate(e.target.value)} iconPosition='left' placeholder='99/99/9999' name="birthdate" id="birthdate" type="date" className="validate"/>
            </div>
            <div className="input-content select">
                <label>Idioma</label>
                <FormControl sx={{ height: 40, width: 360 }}>
                    <Select
                    id="demo-multiple-checkbox-language"
                    displayEmpty
                    value={language}
                    onChange={handleChangeLanguage}
                    MenuProps={MenuProps}
                    >
                      <MenuItem key={'default'} value={''}>
                        <div className="language-placeholder"><LanguageIcon style={{paddingRight: '10px'}} />Selecione um idioma de preferência</div>
                      </MenuItem>
                      <MenuItem key={'pt-br'} value={'pt-br'}>
                        <Checkbox checked={language === 'pt-br' && true} />
                          Português
                      </MenuItem>
                      <MenuItem key={'en-us'} value={'en-us'}>
                        <Checkbox checked={language === 'en-us' && true} />
                          Inglês
                      </MenuItem>
                      <MenuItem key={'es-es'} value={'es-es'}>
                        <Checkbox checked={language === 'es-es' && true} />
                          Espanhol
                      </MenuItem>
                    </Select>
                </FormControl>
            </div>
            {countries && (
              <div className="input-content select">
                <label>Naturalidade</label>
                <FormControl sx={{ height: 40, width: 360 }}>
                    <Select
                    id="demo-select-country"
                    displayEmpty
                    value={selectedCountry}
                    onChange={handleChangeCountry}
                    MenuProps={MenuProps}
                    >
                      <MenuItem key={'default'} value={''}>
                        <div className="locale-placeholder"><LocaleIcon style={{paddingRight: '10px'}} />Selecione o país</div>
                      </MenuItem>
                      {countries.map((country)=>{
                        const { nome } = country
                        return (
                          <MenuItem key={nome} value={nome}>
                            {nome}
                          </MenuItem>
                        )
                      })}
                    </Select>
                </FormControl>
              </div>
            )}
            {selectedCountry === 'Brasil' && (
              <div className="input-content select">
                <label>Estado</label>
                <FormControl sx={{ height: 40, width: 360 }}>
                    <Select
                    id="demo-select-state"
                    displayEmpty
                    value={selectedState}
                    onChange={handleChangeState}
                    MenuProps={MenuProps}
                    >
                      <MenuItem key={'default'} value={''}>
                        <div className="locale-placeholder"><LocaleIcon style={{paddingRight: '10px'}} />Selecione o estado</div>
                      </MenuItem>
                      {countryStates.map((state)=>{
                        const { sigla, nome } = state
                        return (
                          <MenuItem key={sigla} value={sigla}>
                            {nome}
                          </MenuItem>
                        )
                      })}
                    </Select>
                </FormControl>
              </div>
            )}
            {selectedCountry !== 'Brasil' && selectedCountry !== '' && (
              <div className="input-content">
                <label>Endereço</label>
                <Input icon='address book' onChange={e => handleAddress(e.target.value)} value={selectedAddress} iconPosition='left' placeholder='Digite o endereço' name="address" id="address" type="text" className="validate"/>
              </div>
            )}
            {selectedState && (
              <div className="input-content select">
                <label>Cidade</label>
                <FormControl sx={{ height: 40, width: 360 }}>
                    <Select
                    id="demo-select-city"
                    displayEmpty
                    value={selectedCity}
                    onChange={handleChangeCity}
                    MenuProps={MenuProps}
                    >
                      <MenuItem key={'default'} value={''}>
                        <div className="locale-placeholder"><LocaleIcon style={{paddingRight: '10px'}} />Selecione a cidade</div>
                      </MenuItem>
                      {stateCities.map((cities)=>{
                        const { nome } = cities
                        return (
                          <MenuItem key={nome} value={nome}>
                            {nome}
                          </MenuItem>
                        )
                      })}
                    </Select>
                </FormControl>
              </div>
            )}
            <div className="finalizar-cadastro">
                {!linkedin || !oab || !file || !language || !birthplace ?
                <button className="waves-effect waves-light btn-small" onClick={() => alert("Preencha todos os campos corretamente!")}>
                    Finalizar cadastro
                </button>
                :
                <button className="waves-effect waves-light btn-small" onClick={(e) => handleSubmit(e)}>
                    Finalizar cadastro
                </button>
                }

                {/*<span onClick={() => history.goBack()}>Voltar</span>*/}
            </div>
        </form>
    );
};

export default DadosAdvogado; 