import React, {useState} from "react";
import './SendDocButton.css'
import sendDocButton from '../../../assets/upload-img.svg'
import {Box, Modal} from "@mui/material";
import UploadFile from '../../../assets/upload-file.svg'
import api from "../../../services/api/api";

const SendDocButton = (props) => {

    const [openModal,setOpenModal] = useState(false);
    const [document, setDocument] = useState({})
    const {clientId} = props
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: 'none !important',
        boxShadow: 24,
        p: 4,
        width:'960px',
        height:'500px',
        overflowY:'scroll',
        borderRadius:'15px'
    };

    const sendDoc = (e) => {
        e.preventDefault();

        let form = new FormData();
        form.append('document',document);
        form.append('clientId',clientId);


        api.post(`/data-privacy/send/document`,form)
            .then(()=>{
                alert('Documento enviado');
                window.location.reload();
            }).catch((error)=>{
            window.alert('Erro ao finalizar a demanda');
        })
    }

    return (
        <>
            <div id="dataprivacy-doc-send-button">
                <button onClick={()=>setOpenModal(true)}>
                    <img src={sendDocButton} alt=""/>
                    Incluir Documento
                </button>
            </div>

            <Modal
                open={openModal}
                onClose={()=>setOpenModal(!openModal)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableAutoFocus
            >
                <Box sx={style}>
                    <div id="upload-box-header">
                        <h2>Inserir documento</h2>
                    </div>
                    <div id="upload-box-body">
                        <img src={UploadFile} alt="Upload file icon"/>
                        <input className="attatch-file" id="trigger-sendfile" type="file" onChange={(e)=>setDocument(e.target.files[0])}/>
                        {/*<textarea name="description" cols="30" rows="10" placeholder="Descrição"></textarea>*/}
                        <button
                            className="send-form-file"
                            onClick={(e)=>sendDoc(e)}
                        >Enviar</button>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default SendDocButton