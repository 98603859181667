import React from "react"
import logo from '../../assets/logo.png'
import './LoginForm.css'
import {useLocation } from "react-router-dom";
import LoginRouter from "../../routes/LoginRouter";

const LoginForm = () => {

    const location = useLocation();

    let pathName;
    pathName = location.pathname.substring(7);


    return (
        <div id="login-form">
            <div id="login-logo">
                <img src={logo} alt="Bonuz logo"/>
            </div>
            { pathName === "forgotPassword" ?
                <>
                    <div className="title-form forgot">
                        <h2>Esqueceu sua senha?</h2>
                        <p>Por favor, insira o endereço de e-mail associado à sua conta </p>  
                    </div>
                    <LoginRouter />
                </>
                : 
                <>
                    <div className="title-form">
                        <h2>Bem-vindo de volta a Bonuz!</h2>
                        <p>Preencha os campos abaixo e acesse sua conta</p>  
                    </div> 
                    <LoginRouter />
                </>

            }
        </div>
    )
}
export default LoginForm