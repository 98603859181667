import React, {useEffect, useState} from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Table from "../../components/Table/Table";
import api from "../../services/api/api";
import BaloonInfo from "../../components/BaloonInfo/BaloonInfo";
import { useNotifications } from "../../helpers/getNotifications";

const DemandsAuction = () => {
    const [sidebarIsOpen,setSidebarIsOpen] = useState(false);
    const handleSidebar = (sidebar)=>setSidebarIsOpen(sidebar);
    const [demands, setDemands] = useState({})
    const {notifications, checkNotification} = useNotifications();
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        api.get("/demands/in-auction")
            .then((response) => {
                setDemands(response.data)
                //console.log(response.data)
            })
            .catch((error) => {
                //console.log(error)
            })
            .finally(() => setLoading(false))
    }, [])

    return (
        <div id="demands-picker">
            <div className="row">
                <div className="col s12">
                    <Sidebar isOpen={handleSidebar} notifications={notifications} checkNotification={checkNotification}/>
                </div>
                <div
                    className={
                        !sidebarIsOpen
                            ? "container-width"
                            : "container-width-sbopen"
                    }
                >
                    <div className="col s12">
                        <BaloonInfo>
                            <p>
                                Siga os seguintes passos para leiloar um
                                serviço:
                            </p>
                            <p>
                                1. Após definir o serviço, clique no{" "}
                                <button
                                    className="btn-red"
                                    style={{ background: "#44E47A" }}
                                >
                                    Dar lance
                                </button>
                                ;
                            </p>
                            <p>
                                2. Abrirá uma janela para definir seu lance
                                inicial e um prazo para a entrega do
                                serviço.
                            </p>
                        </BaloonInfo>

                        <Table
                            tableTitle={"Demandas"}
                            isDemandAuction
                            dataDemandsAuction={demands}
                            type="auction"
                            loading={loading}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DemandsAuction