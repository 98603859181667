import React, { useState, useRef, useEffect } from "react";
import "./DemandChat.css";
import Moment from 'react-moment'
import api from "../../services/api/api";
import {api_url} from "../../config/environment/environmentSettings";
import {getToken} from "../../services/authentication/authentication";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import "alertifyjs/build/css/themes/default.css";
import uploadFiles from "../../services/api/uploadS3";


alertify.set("notifier", "position", "top-right");

const DemandChat = (props) => {
  const [message, setMessage] = useState("");
  const textareaRef = useRef(null);
  const [demandDetails, setDemandDetails] = useState({});
  const [messages, setMessages] = useState([]);
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);
  const [sending, setSending] = useState(false);
  const refConversasion = useRef(null);

  useEffect(() => {
    if (refConversasion.current) {
      refConversasion.current.scrollTop = refConversasion.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    adjustTextareaHeight();
  }, [message]);

  useEffect(() => {
    const detail = props.demandDetails;
    setDemandDetails(detail);
    setMessages(detail?.chat?.chat_messages);
  }, [props.demandDetails]);

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
  };

  const handleSend = async () => {
    if (sending) {
      return false;
    }
    if (message.length === 0) {
      alertify.notify("Digite uma mensagem.", "error", 5);
      return false;
    }
    props.loading(true);
    const uploadedFiles = await uploadFiles(files, `documents/${demandDetails._id}/{userId}/`);
    setSending(true);

    await api.post('/chat/send/message',{
      "demandId":demandDetails._id,
      "message":message,
      "files": uploadedFiles
    }).then((response) => {
        setMessage("");
        setMessages(response?.data);
        setFiles([]);
        fileInputRef.current.value = '';
        alertify.notify("Mensagem enviada clom sucesso.", "success", 5);
    }).finally(() => {
      props.loading(false);
      setSending(false);
    });
  };

  const handlerFileClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div id="demandChat">
      <div className="ui grid" id="chat">
        <div className="sixteen wide column" style={{paddingRight: '0px'}}>
          <div className="conversation">
            <div className="conversation-container" ref={refConversasion}>
              {messages?.map((message) => {
                return (
                  <div className={`message ${message.sender === 'lawyer' ? 'sent' : 'received'}`}>
                    {message.message}
                    <span className="metadata">
                      <span className="time"><Moment format='DD/MM/YYYY H:mm:ss'>{message.createdAt || message.created_at}</Moment></span>
                    </span>
                    {message?.documents && message?.documents?.length > 0 && (
                      <div className="file-list">
                        {message?.documents.map((file, index) => (
                          <div key={index} className="file-item" style={{cursor: 'pointer'}}>
                            <i className="icon paperclip"></i>
                            <span onClick={() => {window.open(`${api_url}/demands/${demandDetails._id}/file/${file._id}?token=${getToken()}`,'_blank')}}>{file.name}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="chat-container">
        <div className="input-area">
          <textarea
            id="message-input"
            placeholder="Digite sua mensagem..."
            ref={textareaRef}
            onChange={handleMessageChange}
            value={message}
            onKeyPress={handleKeyPress}
          ></textarea>
          <div className="icons">
            <button type="button" className="icon-btn" onClick={handlerFileClick}>
              <i className="icon paperclip"></i>
            </button>
            <button
              type="button"
              id="send-button"
              onClick={handleSend}
              className="icon-btn"
            >
              <i className="icon paper plane"></i>
            </button>
          </div>
        </div>
        <input
          type="file"
          multiple
          ref={fileInputRef}
          onChange={(e) => setFiles(Array.from(e.target.files))}
        />
        {files.length > 0 && (
          <div className="fields">
            <div className="file-list">
              {files.map((file, index) => (
                <div key={index} className="file-item">
                  <i className="icon paperclip"></i>
                  <span>{file.name}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DemandChat;
